import type { StoreJsonResponse, StoreStreamResponse } from "@/stores/common";
import type { ResponsePayload } from "@laborchart-modules/common/dist/api";
import type {
   BatchDeletePayload,
   DeleteResponsePayload,
   StreamedUpdate,
} from "@laborchart-modules/lc-core-api";
import { Trash } from "@procore/core-icons";
import { Button, Modal, Spinner, Typography, useI18nContext } from "@procore/core-react";
import { useRowSelectionState } from "@procore/data-table";
import type { TableApi } from "@procore/data-table";
import { useToastAlertContext } from "@procore/toast-alert";
import React from "react";

type onSingleDelete = (id: string) => StoreJsonResponse<ResponsePayload<DeleteResponsePayload>>;
type onBatchDelete = (updates: BatchDeletePayload) => StoreStreamResponse<StreamedUpdate>;

export type ConfirmDeleteModalPropTypes = {
   isBulk?: boolean;
   id?: string;
   tableApi?: TableApi;
   successText: string;
   deleteText: string;
   headerText: string;
   onDelete: onSingleDelete | onBatchDelete;
};

export const ConfirmDeleteModal = (props: ConfirmDeleteModalPropTypes) => {
   const { isBulk = false, id, tableApi } = props;
   const I18n = useI18nContext();
   const [open, setOpen] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const { showToast } = useToastAlertContext();
   const rows = useRowSelectionState().map((x: any) => x.data);
   const selectedRows = isBulk ? rows : [];
   const count = isBulk ? selectedRows.length : 1;

   const handleDelete = async () => {
      try {
         setLoading(true);
         if (isBulk) {
            const ids = selectedRows?.map((x: any) => x.id);
            await (props.onDelete as onBatchDelete)({ ids: ids }).stream;
            tableApi?.deselectAll();
         } else {
            await (props.onDelete as onSingleDelete)(id!).payload;
         }
         tableApi?.refreshServerSide({});
         showToast.success(I18n.t(props.successText, { count }));
      } catch {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
         setOpen(false);
      }
   };

   return (
      <>
         <Button
            icon={<Trash />}
            onClick={() => setOpen(true)}
            variant="tertiary"
            data-testid="trash-btn"
         />
         <Modal open={open} onClose={() => setOpen(false)} width="md">
            <Modal.Header>{I18n.t(props.headerText, { count })}</Modal.Header>
            <Spinner loading={loading}>
               <Modal.Body>
                  <Typography>{I18n.t(props.deleteText, { count })}</Typography>
               </Modal.Body>
               <Modal.Footer>
                  <Modal.FooterButtons>
                     <Button variant="secondary" onClick={() => setOpen(false)}>
                        {I18n.t("views.company.workforce_planning.cancel")}
                     </Button>
                     <Button variant="primary" onClick={handleDelete}>
                        {I18n.t("views.company.workforce_planning.delete")}
                     </Button>
                  </Modal.FooterButtons>
               </Modal.Footer>
            </Spinner>
         </Modal>
      </>
   );
};
