export type BaggageTimeKey = "daily_start_time" | "daily_end_time";
import type { CustomField, FormSelectOption } from "@/react/prop-types";
import type { WorkdayOptions } from "../labor-plans/prop-types";
import type { useI18nContext } from "@procore/core-react";
import type { GetAssignmentCreationSupportDataResponse } from "@laborchart-modules/lc-core-api/dist/api/legacy/get-assignment-creation-support-data";
import type { AssignmentFormValues, OverTimeRates } from "./assignment/types";
import type { ResponsePayload } from "@laborchart-modules/common/dist/api";
import type {
   UpdateSinglePersonResponse,
   UpdateSingleProjectResponse,
} from "@laborchart-modules/lc-core-api";
import type { SerializedPersonDetailsPerson } from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import type { SerializedProjectDetailProject } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project";
import type { SerializedProjectRole } from "@laborchart-modules/common/dist/rethink/serializers/project-roles-serializer";
import type { SerializedPerson } from "@laborchart-modules/common/dist/rethink/serializers/person-serializer";
import type { SerializedPosition } from "@laborchart-modules/common/dist/rethink/serializers/position-serializer";
import type { SerializedWageOverride } from "@laborchart-modules/common/dist/rethink/serializers/wage-override-serializer";
import type {
   ActivityStreamName,
   FindActivityActivity,
} from "@laborchart-modules/common/dist/reql-builder/procedures/find-activity";
import type { IntegratedEntity } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { RequestFormValues } from "./request/types";

export type ProjectOptions = {
   baggage: {
      additional_searchables: string[];
      start_date: number;
      est_end_date: number | null;
      daily_start_time: number;
      daily_end_time: number;
      status: string;
   };
};

export enum DropDownType {
   CATEGORY = "category",
   SUBCATEGORY = "subcategory",
   PROJECT = "project",
   RESOURCE = "resource",
   JOB_TITLE = "job_title",
   EQUIPMENT = "equipment",
}

export type Comment = {
   author_id: string;
   content: string;
};

export type Comments = {
   id: string;
   content: string;
   created_at: number | Date;
   author_id: string;
   author_name?: {
      first: string;
      last: string;
   } | null;
};

export type CommentsCardProps = {
   comments: Comments[];
};

export type CommonFormValuesForRequestAndAssignment = {
   resource: FormSelectOption<string> | null;
   project: FormSelectOption<string> | null;
   status: FormSelectOption<string> | null;
   category: FormSelectOption<string> | null;
   subcategory: FormSelectOption<string> | null;
   start_date: Date | null;
   end_date: Date | null;
   start_time: FormSelectOption<number>;
   end_time: FormSelectOption<number>;
   assignment_by_time: boolean;
   assignment_by_allocation: boolean;
   work_days: WorkdayOptions;
   percent_allocated: number | null;
   instruction_text: string;
   work_scope_text: string;
   comments: string;
   author_id: string;
};

export type CommonPayloadForRequestAndAssignment = {
   category_id: string | null;
   custom_fields: { [key: string]: any };
   end_day: number;
   end_time: number | null;
   percent_allocated: number | null;
   project_id: string;
   start_day: number;
   start_time: number | null;
   status_id: string | null;
   subcategory_id: string | null;
   work_days: WorkdayOptions;
   instruction_text: string | null;
   work_scope_text: string | null;
   comments: Comment[] | undefined;
};

export type OverTimeProps = {
   showOverTime: boolean;
   isOverTimeSelected: boolean;
   setIsOverTimeSelected: (value: boolean) => void;
   errors: any;
   setTotalHours: (value: number) => void;
   defaultOverTimeRates: OverTimeRates;
};

export type ScheduleCardProps = {
   I18n: ReturnType<typeof useI18nContext>;
   setFieldValue: (field: string, value: any) => void;
   assignmentCreationSupportData: GetAssignmentCreationSupportDataResponse | null;
   selectedWorkDays: WorkdayOptions;
   setSelectedWorkDays: (days: WorkdayOptions) => void;
   assignmentByTime: boolean;
   setAssignmentByTime: (value: boolean) => void;
   assignmentByAllocation: boolean;
   setAssignmentByAllocation: (value: boolean) => void;
   canViewAllStatuses: boolean;
   currentAssignments?: React.ReactNode;
   showTagSelector?: boolean;
   overTimeProps?: OverTimeProps;
   values: AssignmentFormValues | RequestFormValues;
};

export type CustomFieldsCardProps = {
   customFields: CustomField[] | null;
   I18n: ReturnType<typeof useI18nContext>;
   filterIntegrationOnly: boolean;
};

export enum PageType {
   PROJECT = "project",
   PERSON = "person",
}

// Create an extended type that includes procore_id
export type ExtendedDetail =
   | SerializedProjectDetailProject
   | (SerializedPersonDetailsPerson & {
        procore_id?: string; // Optional property
        roles?: Array<
           SerializedProjectRole & {
              assignee: SerializedPerson;
              position: SerializedPosition;
           }
        >;
        wage_overrides?: Array<
           SerializedWageOverride & {
              position: SerializedPosition;
           }
        >;
     });

export type ExtendedGetDetailResponse = ResponsePayload<ExtendedDetail>;

export type DetailsCardProps = {
   detailsData: ExtendedGetDetailResponse | null;
   refreshTable?: () => void;
   refetchData: () => void;
   updateData: (payload: any) => Promise<UpdateSingleProjectResponse | UpdateSinglePersonResponse>;
   loading: boolean;
   pageType: PageType;
};

export type ValueType = string | number | boolean | null;

export type ActivitiesResponseProps = FindActivityActivity & {
   meta?: {
      status_name?: string | null;
      start_day?: number;
      end_day?: number;
      property_name?: string;
      old_val?: ValueType;
      new_val?: ValueType;
      custom_field_name?: string | null;
      custom_field_type?: string | null;
      rate?: number;
      transfer?: any;
      type?: string;
   };
   subject_2_name?: ActivityStreamName | string | null;
   subject_3_name?: ActivityStreamName | string | null;
};

export type ActivitiesSpecificOptionType = {
   id: string;
   label: string;
   value: string;
   show: boolean;
};

export type ListActivitiesProps = {
   activitiesState: ActivitiesResponseProps[];
   setActivitiesState: React.Dispatch<React.SetStateAction<ActivitiesResponseProps[]>>;
   entityType: IntegratedEntity;
   includedCategories: string[];
   nextStartingAfter: string;
   setNextStartingAfter: React.Dispatch<React.SetStateAction<string>>;
   limitActivities: number;
   setLimitActivities: React.Dispatch<React.SetStateAction<number>>;
};

type DetailTypes = {
   owning_subject?: string;
   property_name?: string;
   value?: string;
};

export type ExtendedActivitiesResponseProps = ActivitiesResponseProps & {
   meta?: {
      percent_allocated?: number;
      start_time?: number;
      end_time?: number;
      old_val?: ValueType | ActivityStreamName;
      new_val?: ValueType | ActivityStreamName;
   };
   details?: DetailTypes[];
};

export type LinkToProcoreEntityArgs = {
   entityType: "person" | "project";
   procoreEntityId?: string | number | null;
   procoreCompanyId?: string;
};
