import { timeOptions } from "@/lib/utils/timezones";
import { type FormikHelpers, type FormikProps } from "formik";
import type { CreateAssignmentTearsheetProps } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   Page,
   H1,
   Box,
   Flex,
   Required,
   FlexList,
   Button,
   Form,
   Card,
   Title,
   useI18nContext,
   Spinner,
   H2,
} from "@procore/core-react";
import type { FC } from "react";
import React, { useEffect, useState } from "react";

import { assignmentSchema } from "./validation-schemas";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useGetAssignmentCreationSupportDataQuery } from "./queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { useGetCustomFields } from "../../common/queries/queries";
import type { WorkdayOptions } from "../../labor-plans/prop-types";
import {
   AuthAction,
   usePermissionContext,
   useViewPreferenceContext,
} from "@/react/providers/permission-context-provider";
import { formatOptions } from "@/react/shared/helper";
import {
   type AssignmentWithResource,
   type AssignmentFormValues,
   type AssignmentWithEquipment,
} from "./types";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
import { DEFAULT_END_TIME, DEFAULT_START_TIME, workDays } from "@/react/shared/constants";
import { processCustomFieldsFormValues } from "@/react/shared/custom-field-utils";
import { DEFAULT_OVERTIME_RATES, DEFAULT_PAY_SPLIT_RATES, formInitialValues } from "./constants";
import type { View } from "@procore/core-react/dist/Form/Form.types";
import type { GetAssignmentDetailReponse } from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { DateUtils } from "@/lib/utils/date";
import type { Comments, ProjectOptions } from "../types";
import { DropDownType } from "../types";
import { useDropDownSelectionHandler } from "@/react/shared/hooks/useDropDownSelectionHandler";
import {
   getCommonFieldsForRequestAndAssignment,
   getTimeOptionsFromProject,
   reverseTransformOvertimeRates,
   transformOvertimeRates,
   updateStartDateIfFuture,
} from "../helper";
import { ScheduleCard } from "../shared/schedule-card";
import CustomFieldsCard from "../shared/custom-fields-card";
import { EquipmentSelectField } from "@procore/equipment-select";
import { getProcoreCompanyIdFromURL } from "@/lib/utils/url";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import AdditionalInstructionsCard from "../shared/additional-instructions-card";
import CommentsCard from "../shared/comments-card";
import { authManager } from "@/lib/managers/auth-manager";
import { PersonStore } from "@/stores/person-store.core";
import { ProjectStore } from "@/stores/project-store.core";
import { createGanttCalendar } from "../../gantt/gantt-calendar";
import { EquipmentAssignmentStore } from "@/stores/equipment-assignments-store.core";
import type { AddUserToProjectDirectoryPayload } from "@/stores/project-directory-store.core";
import { ProjectDirectoryStore } from "@/stores/project-directory-store.core";
import { rawAssignmentToTask } from "../../gantt/gantt-utils";

export const makeCallToProjectDirectoriesAPIs = async (projectId: number, userId: number) => {
   try {
      const projectDirectoryUser = await ProjectDirectoryStore.getProjectDirectoryUser(
         projectId,
         userId,
      ).payload;
      // if the user is not present in the project directory for the selected project then we have to add the user to the project directory
      if (!projectDirectoryUser) {
         // as decided currently we are passing 0 as permission template id, in future we will get the permission template id from the user
         const addProjectDirectoryUserPayload: AddUserToProjectDirectoryPayload = {
            user: {
               permission_template_id: 0,
            },
         };
         await ProjectDirectoryStore.addProjectDirectoryUser(
            projectId,
            userId,
            addProjectDirectoryUserPayload,
         ).payload;
      }
   } catch (error) {
      // currently only logging the error, in future we will show the error message to the user
      console.log(
         `Error while calling project directory APIs for projectId: ${projectId}, userId: ${userId}`,
         error,
      );
   }
};

export const AssignmentTearsheetContent: FC<CreateAssignmentTearsheetProps> = ({
   onClose,
   showProjectDetail, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   projectId, // its an optional property so it can be undefined will be defined when create Assignment button is clicked from project detail page
   categoryId,
   subcategoryId,
   assignmentId, // its an optional property so it can be undefined will be defined in the edit Assignment flow
   callback,
}) => {
   const isCreateFlow = !assignmentId; // main purpose of this flag is to determine whether the form is in create flow or edit flow
   const authorId = authManager.authedUserId();
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { data: assignmentCreationSupportData, isLoading: supportDataLoading } =
      useGetAssignmentCreationSupportDataQuery(groupId);
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.ASSIGNMENT);
   const procoreCompanyId: string | undefined = getProcoreCompanyIdFromURL();
   const {
      categoryOptions,
      subCategoryOptions,
      categoryPlaceHolder,
      subCategoryPlaceHolder,
      currentAssignments,
      handleSelection,
      resetSubCategory,
      projectProcoreId,
      totalHours,
      setTotalHours,
      projectUserId,
      projectStartDate,
   } = useDropDownSelectionHandler(assignmentCreationSupportData);

   const [selectedWorkDays, setSelectedWorkDays] = useState<WorkdayOptions>(workDays);
   const [isEquipmentEnabled, setIsEquipmentEnabled] = useState<boolean>(false);

   const [assignmentByTime, setAssignmentByTime] = useState(true);
   const [assignmentByAllocation, setAssignmentByAllocation] = useState(false);
   const [canViewAllStatuses, setCanViewAllStatuses] = useState<boolean>(false);
   const [isOverTimeSelected, setIsOverTimeSelected] = useState<boolean>(false);
   const [loading, setLoading] = useState<boolean>(false);
   const [filterIntegrationOnly, setFilterIntegrationOnly] = useState<boolean>(true);
   const [formViewMode, setFormViewMode] = useState<ActionMode>(ActionMode.CREATE);
   const [initialValues, setInitialValues] = useState<AssignmentFormValues>(
      formInitialValues(projectId, categoryId, subcategoryId),
   );
   const [commentList, setCommentList] = React.useState<Comments[]>([]);
   const [makeTotalHoursAssignment, setMakeTotalHoursAssignment] = useState<boolean>(true);
   const { getViewPreference } = useViewPreferenceContext();

   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;

   const getResourceName = (personName: { first?: string; last?: string }): string => {
      const { first = "", last = "" } = personName;
      return isLastNameFirst ? `${last}, ${first}`.trim() : `${first} ${last}`.trim();
   };

   const getOverTimeRates = () => {
      return assignmentCreationSupportData?.data.overtime_day_rates ?? DEFAULT_OVERTIME_RATES;
   };

   // Helper function to map data to initial values for the assignment form, this will be triggered in edit mode
   const mapDataToInitialValues = (assignmentDetails: GetAssignmentDetailReponse) => {
      const { data } = assignmentDetails;
      // Extract initial values
      const baseFormValues = {
         resource: {
            id: data.person?.id,
            label: getResourceName(data.person.name),
         },
         project: {
            id: data.project?.id,
            label: data.project?.name,
         },
         status: data.status
            ? {
                 id: data.status.id,
                 label: data.status.name,
              }
            : null,
         category: data.category
            ? {
                 id: data.category.id,
                 label: data.category.name,
              }
            : null,
         subcategory: data.subcategory
            ? {
                 id: data.subcategory.id,
                 label: data.subcategory.name,
              }
            : null,
         start_date: DateUtils.getAttachedDate(data.start_day),
         end_date: DateUtils.getAttachedDate(data.end_day),
         start_time: {
            id: data.start_time ?? DEFAULT_START_TIME,
            label: data.start_time
               ? timeOptions.find(({ id }) => id === data.start_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
         },
         end_time: {
            id: data.end_time ?? DEFAULT_END_TIME,
            label: data.end_time
               ? timeOptions.find(({ id }) => id === data.end_time)!.label
               : timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
         },
         assignment_by_time: data.start_time != null && data.end_time != null,
         assignment_by_allocation: Boolean(data.percent_allocated),
         work_days: data.work_days,
         percent_allocated: data.percent_allocated ?? 100,
         overtime: data.overtime,
         pay_split: data.pay_split ?? DEFAULT_PAY_SPLIT_RATES,
         overtime_rates: data.overtime_rates
            ? reverseTransformOvertimeRates(data.overtime_rates)
            : getOverTimeRates(),
         work_scope_text: data.work_scope_text ?? "",
         instruction_text: data.instruction_text ?? "",
         comments: "",
      };
      // Initialize custom fields in form values
      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      // setting local states
      setAssignmentByAllocation(baseFormValues.assignment_by_allocation);
      setAssignmentByTime(baseFormValues.assignment_by_time);
      setSelectedWorkDays(baseFormValues.work_days);
      setIsOverTimeSelected(baseFormValues.overtime);
      setTotalHours(
         baseFormValues.pay_split.straight +
            baseFormValues.pay_split.overtime +
            baseFormValues.pay_split.unpaid,
      );
      setFormViewMode(!isCreateFlow ? ActionMode.UPDATE : ActionMode.READ);
      setFilterIntegrationOnly(false);
      setCommentList(data.comments ?? []);
      setMakeTotalHoursAssignment(false);
      return { ...baseFormValues, ...customFieldValues };
   };

   useEffect(() => {
      const isEquipmentEnabled = LaunchDarklyClient.getFlagValue("wfp-equipment-pilot-enabled");
      setIsEquipmentEnabled(isEquipmentEnabled);
   }, []);

   useEffect(() => {
      updateStartDateIfFuture(projectStartDate, isCreateFlow, setInitialValues);
   }, [projectStartDate, isCreateFlow]);

   useEffect(() => {
      const fetchAssignmentDetails = async () => {
         if (assignmentId) {
            try {
               const assignmentDetails: GetAssignmentDetailReponse =
                  await Assignment2Store.getAssignmentDetails(assignmentId).payload;
               setInitialValues(mapDataToInitialValues(assignmentDetails));
            } catch (error) {
               showToast.error(I18n.t("views.company.workforce_planning.error"));
            }
         }
      };
      fetchAssignmentDetails();
   }, [assignmentId]);

   // this is needed to toggle the category and subcategory options/placeholders based on the project/category selection
   useEffect(() => {
      if (initialValues.project) {
         handleSelection(initialValues.project, DropDownType.PROJECT, makeTotalHoursAssignment);
      }

      if (initialValues.category) {
         handleSelection(initialValues.category, DropDownType.CATEGORY);
      }
   }, [initialValues.project, assignmentCreationSupportData?.data]);

   // written for population of overtime rates when the form is opened in create mode
   useEffect(() => {
      setInitialValues((prevValues) => {
         const isDefaultRates =
            JSON.stringify(prevValues.overtime_rates) === JSON.stringify(DEFAULT_OVERTIME_RATES);
         if (isDefaultRates) {
            return {
               ...prevValues,
               overtime_rates: getOverTimeRates(),
            };
         }

         return prevValues;
      });
   }, [assignmentCreationSupportData?.data]);

   // written for population of pay split rates when the form is opened in create mode
   useEffect(() => {
      setInitialValues((prevValues) => {
         const isDefaultPaySplit =
            JSON.stringify(prevValues.pay_split) === JSON.stringify(DEFAULT_PAY_SPLIT_RATES);
         if (isDefaultPaySplit) {
            return {
               ...prevValues,
               pay_split: {
                  overtime: 0,
                  straight: totalHours,
                  unpaid: 0,
               },
            };
         }

         return prevValues;
      });
   }, [totalHours]);

   const handleClose = () => {
      // this will navigate to project details page on cancel click
      if (projectId && showProjectDetail) {
         showProjectDetail(projectId);
      }
      // this will simply close the tearsheet
      else {
         onClose();
      }
   };

   useEffect(() => {
      const canViewAllStatuses = checkAuthAction(AuthAction.CAN_VIEW_ALL_STATUSES);
      setCanViewAllStatuses(canViewAllStatuses);
   }, [checkAuthAction]);

   const validationSchema = assignmentSchema(
      I18n,
      !canViewAllStatuses,
      selectedWorkDays,
      assignmentByAllocation,
      assignmentByTime,
      projectProcoreId !== null,
      isEquipmentEnabled,
      isOverTimeSelected,
   );

   const getAssignmentActionText = (formValues: AssignmentFormValues): string => {
      const { resource, equipment } = formValues;

      // Determine the count of selected resources (1 for resource, 1 for equipment)
      const resourceSelectedCount = [resource, equipment].filter(Boolean).length;

      // Build the resource count text only if more than 1 resource is selected
      const resourceCountText = resourceSelectedCount > 1 ? ` (${resourceSelectedCount})` : "";

      // Determine the base action text based on the flow type
      const actionText = !isCreateFlow
         ? I18n.t("views.company.workforce_planning.update")
         : I18n.t("views.company.workforce_planning.create");

      // Return the final action text with optional count
      return `${actionText}${resourceCountText}`;
   };

   /* istanbul ignore next */
   async function handleSubmit(
      values: AssignmentFormValues,
      { resetForm }: FormikHelpers<AssignmentFormValues>,
   ) {
      values.author_id = authorId ?? "";
      const commonFields = getCommonFieldsForRequestAndAssignment(values, customFields);

      const commonFieldsForResourceAndEquipment = {
         overtime: values.assignment_by_time ? values.overtime : false,
         pay_split:
            values.overtime && values.assignment_by_time
               ? {
                    overtime: values.pay_split?.overtime ?? 0,
                    straight: values.pay_split?.straight ?? 0,
                    unpaid: values.pay_split?.unpaid ?? 0,
                 }
               : null,
         overtime_rates:
            values.overtime && values.assignment_by_time
               ? transformOvertimeRates(values.overtime_rates)
               : null,
      };

      let resourceAssignment: AssignmentWithResource | null = null;
      let equipmentAssignment: AssignmentWithEquipment | null = null;

      if (values.resource?.id) {
         resourceAssignment = {
            resource_id: values.resource.id,
            ...commonFields,
            ...commonFieldsForResourceAndEquipment,
         };
      }

      if (values.equipment?.id) {
         equipmentAssignment = {
            equipment_id: values.equipment.id,
            ...commonFields,
            ...commonFieldsForResourceAndEquipment,
         };
      }

      try {
         setLoading(true);

         // Collect promises for resource and equipment API calls
         const apiCalls: Array<Promise<any>> = [];

         // Resource assignment API call
         if (resourceAssignment) {
            const resourceCall = !isCreateFlow
               ? Assignment2Store.updateSingleAssignment({
                    assignmentId,
                    update: resourceAssignment,
                 }).payload
               : Assignment2Store.createAssignment(resourceAssignment).payload;

            apiCalls.push(resourceCall);
         }

         // Equipment assignment API call (when API is ready)
         if (equipmentAssignment) {
            const equipmentCall = !isCreateFlow
               ? Promise.resolve(
                    console.log("Edit equipment API call will go here", equipmentAssignment),
                 )
               : EquipmentAssignmentStore.createEquipmentAssignment({
                    ...equipmentAssignment,
                    resource_id: equipmentAssignment.equipment_id,
                 }).payload;
            apiCalls.push(equipmentCall);
         }

         // Execute all API calls in parallel
         const results = await Promise.all(apiCalls);

         /*after the execution of all the API related to labor and equipment assignment we need to call APIs related to Project Directory
         if procore id for selected project and selected labor exists then we have to call these APIs*/
         if (projectProcoreId && projectUserId) {
            await makeCallToProjectDirectoriesAPIs(projectProcoreId, projectUserId);
         }

         // Handle callback method to update the Bryntum Gantt
         if (resourceAssignment && callback) {
            // Let's fetch person name as well so we can return it in the callback
            const [personPayload, projectPayload] = await Promise.all([
               PersonStore.getPerson(resourceAssignment.resource_id).payload,
               ProjectStore.getProject(resourceAssignment.project_id).payload,
            ]);

            try {
               const startDay = DateUtils.getAttachedDate(resourceAssignment.start_day);
               const endDay = DateUtils.getAttachedDate(resourceAssignment.end_day);
               let startTime: string | number | null = resourceAssignment.start_time;
               let endTime: string | number | null = resourceAssignment.end_time;

               // if startTime is a float (eg. 14.5 === 2:30pm), we're transforming it to a string in the format of "HH:MM:00"
               if (typeof startTime === "number") {
                  const hours = Math.floor(startTime).toString().padStart(2, "0");
                  const minutes = Math.floor((startTime % 1) * 60)
                     .toString()
                     .padStart(2, "0");
                  startTime = `${hours}:${minutes}:00`;
               }
               // same check & transformation for endTime
               if (typeof endTime === "number") {
                  const hours = Math.floor(endTime).toString().padStart(2, "0");
                  const minutes = Math.floor((endTime % 1) * 60)
                     .toString()
                     .padStart(2, "0");
                  endTime = `${hours}:${minutes}:00`;
               }

               const newTask = rawAssignmentToTask({
                  assignment: {
                     id: results[0].data.id,
                     ...resourceAssignment,
                     start_day: startDay,
                     end_day: endDay,
                     start_time: startTime,
                     end_time: endTime,
                  },
                  person: personPayload.data,
                  project: projectPayload.data,
               });

               const hasPermissionToViewPeopleTimeoff = !!authManager
                  .authedUser()
                  ?.permissionLevel()
                  ?.viewPeopleTimeoff();

               const calendarForNewTask = createGanttCalendar(
                  newTask,
                  personPayload.data,
                  hasPermissionToViewPeopleTimeoff,
               );

               callback({ newTask, calendarForNewTask });
            } catch (error) {
               console.error("ERROR IN CALLBACK:", error);
            }
         }

         // If editing, switch to READ mode
         if (!isCreateFlow) {
            setFormViewMode(ActionMode.READ);
         } else {
            handleClose(); // Close the form only in create flow
         }
      } catch (error) {
         if (!isCreateFlow) {
            showToast.error(I18n.t("views.company.workforce_planning.assignment.edit_error"));
            resetForm();
         } else {
            showToast.error(I18n.t("views.company.workforce_planning.assignment.create_error"));
         }
      } finally {
         setLoading(false);
      }
   }

   return (
      <Spinner loading={(supportDataLoading || loading) && assignmentCreationSupportData === null}>
         <Page>
            <Page.Main
               style={{
                  width: "950px",
                  display: "flex",
                  flexDirection: "column",
               }}
            >
               <Page.Header>
                  <Page.Title>
                     <H1>
                        {!isCreateFlow
                           ? I18n.t("views.company.workforce_planning.assignment.edit_title")
                           : I18n.t("views.company.workforce_planning.assignment.create_title")}
                     </H1>
                  </Page.Title>
               </Page.Header>
               <Form
                  view={formViewMode as View}
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize
               >
                  {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                  {({
                     submitForm,
                     isSubmitting,
                     setFieldValue,
                     errors,
                     values,
                  }: FormikProps<AssignmentFormValues>) => (
                     <>
                        <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                           <Card style={{ marginBottom: "10px" }}>
                              <Box padding="md">
                                 <FlexList justifyContent="space-between" marginBottom="sm">
                                    <Title>
                                       <Title.Text>
                                          <H2>
                                             {I18n.t("views.company.workforce_planning.details")}
                                          </H2>
                                       </Title.Text>
                                    </Title>
                                    {formViewMode === ActionMode.READ && (
                                       <Button
                                          variant="secondary"
                                          onClick={() => setFormViewMode(ActionMode.UPDATE)}
                                          data-testid="edit-assignment"
                                       >
                                          {I18n.t("views.company.workforce_planning.edit")}
                                       </Button>
                                    )}
                                 </FlexList>
                                 <Form.Form>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={12}
                                          name="project"
                                          label={I18n.t("views.company.workforce_planning.project")}
                                          required
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.project_options ??
                                                [],
                                          )}
                                          onSelect={({ item }) => {
                                             const selectedItem =
                                                assignmentCreationSupportData?.data.project_options.find(
                                                   (project) => project.id === item.id,
                                                ) as ProjectOptions;
                                             handleSelection(item, DropDownType.PROJECT);
                                             setFieldValue("category", null);
                                             setFieldValue("subcategory", null);
                                             setFieldValue(
                                                "start_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_START_TIME,
                                                   "daily_start_time",
                                                ),
                                             );
                                             setFieldValue(
                                                "end_time",
                                                getTimeOptionsFromProject(
                                                   selectedItem,
                                                   DEFAULT_END_TIME,
                                                   "daily_end_time",
                                                ),
                                             );
                                          }}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.select_option",
                                          )}
                                          onClear={false}
                                       />
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={6}
                                          name="category"
                                          label={I18n.t(
                                             "views.company.workforce_planning.category",
                                          )}
                                          options={categoryOptions}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.CATEGORY);
                                             setFieldValue("subcategory", null);
                                          }}
                                          placeholder={I18n.t(categoryPlaceHolder)}
                                          disabled={categoryOptions.length === 0}
                                          onClear={() => {
                                             resetSubCategory();
                                             setFieldValue("subcategory", null);
                                          }}
                                       />
                                       <Form.Select
                                          colStart={7}
                                          colWidth={6}
                                          name="subcategory"
                                          label={I18n.t(
                                             "views.company.workforce_planning.subcategory",
                                          )}
                                          options={subCategoryOptions}
                                          placeholder={I18n.t(subCategoryPlaceHolder)}
                                          disabled={subCategoryOptions.length === 0}
                                       />
                                    </Form.Row>
                                    <Form.Row>
                                       <Form.Select
                                          colStart={1}
                                          colWidth={6}
                                          name="resource"
                                          label={I18n.t("views.company.workforce_planning.labor")}
                                          options={formatOptions(
                                             assignmentCreationSupportData?.data.resource_options ??
                                                [],
                                          )}
                                          placeholder={I18n.t(
                                             "views.company.workforce_planning.assignment.labor_placeholder",
                                          )}
                                          onClear={() => {
                                             handleSelection(null, DropDownType.RESOURCE);
                                          }}
                                          onSelect={({ item }) => {
                                             handleSelection(item, DropDownType.RESOURCE);
                                          }}
                                          required={!isEquipmentEnabled}
                                       />

                                       {isEquipmentEnabled && (
                                          <EquipmentSelectField
                                             label={I18n.t(
                                                "views.company.workforce_planning.equipment",
                                             )}
                                             companyId={
                                                procoreCompanyId != null
                                                   ? Number(procoreCompanyId)
                                                   : 1
                                             }
                                             name="equipment"
                                             showBeta={true}
                                             colStart={7}
                                             colWidth={6}
                                             i18nScope="views.company.workforce_planning.equipment_placeholder"
                                             disabled={!projectProcoreId}
                                          />
                                       )}
                                    </Form.Row>
                                 </Form.Form>
                              </Box>
                           </Card>
                           {/** form for the schedule card */}
                           <ScheduleCard
                              I18n={I18n}
                              setFieldValue={setFieldValue}
                              assignmentCreationSupportData={assignmentCreationSupportData}
                              selectedWorkDays={selectedWorkDays}
                              setSelectedWorkDays={setSelectedWorkDays}
                              assignmentByTime={assignmentByTime}
                              setAssignmentByTime={setAssignmentByTime}
                              assignmentByAllocation={assignmentByAllocation}
                              setAssignmentByAllocation={setAssignmentByAllocation}
                              canViewAllStatuses={canViewAllStatuses}
                              currentAssignments={currentAssignments}
                              values={values} // this will contain the form values
                              overTimeProps={{
                                 showOverTime: true,
                                 isOverTimeSelected,
                                 setIsOverTimeSelected,
                                 defaultOverTimeRates: getOverTimeRates(),
                                 errors,
                                 setTotalHours,
                              }}
                           />
                           {/** form for the additional information/custom field card */}
                           <CustomFieldsCard
                              customFields={customFields}
                              I18n={I18n}
                              filterIntegrationOnly={filterIntegrationOnly}
                           />
                           <AdditionalInstructionsCard />
                           <CommentsCard comments={commentList} />
                        </Page.Body>
                        {formViewMode !== ActionMode.READ && (
                           <Page.Footer>
                              <Box padding="md">
                                 <Flex justifyContent="space-between" alignItems="center">
                                    <Required showLabel />
                                    <FlexList space="sm">
                                       <Button
                                          variant="tertiary"
                                          disabled={isSubmitting}
                                          onClick={handleClose}
                                       >
                                          {I18n.t("views.company.workforce_planning.cancel")}
                                       </Button>
                                       <Button
                                          type="submit"
                                          disabled={isSubmitting}
                                          onClick={submitForm}
                                       >
                                          {getAssignmentActionText(values)}
                                       </Button>
                                    </FlexList>
                                 </Flex>
                              </Box>
                           </Page.Footer>
                        )}
                     </>
                  )}
               </Form>
            </Page.Main>
         </Page>
      </Spinner>
   );
};
