import React, { useState } from "react";
import {
   Button,
   useI18nContext,
   Form,
   Spinner,
   Tearsheet,
   Page,
   H1,
   Card,
   Box,
   Flex,
   FlexList,
} from "@procore/core-react";
import { timeOffReasons } from "../../tearsheets/time-off/constants";
import { getOrderedTimeOptions } from "../../tearsheets/time-off/helpers";
import type { FormikProps } from "formik";
import { isAfter } from "date-fns";
import { ErrorBanner } from "../../error-banner";
import { useToastAlertContext } from "@procore/toast-alert";
import { Pencil } from "@procore/core-icons";
import type {
   EditTimeOffFormValues,
   TimeOffDataTable,
} from "../../time-off-list/time-off-list-prop-types";
import { useRowSelectionState } from "@procore/data-table";
import type { TableApi } from "@procore/data-table";
import { getTimeOffTypeOptions } from "../../time-off-list/time-off-list-utils";

export type BulkEditTimeOffTearsheetPropTypes = {
   bulkUpdateTimeOff: (ids: string[], values: EditTimeOffFormValues) => Promise<any>;
   tableApi?: TableApi;
};

export const BulkEditTimeOffTearsheet = (props: BulkEditTimeOffTearsheetPropTypes) => {
   const { tableApi, bulkUpdateTimeOff } = props;
   const I18n = useI18nContext();
   const [isOpen, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);
   const { showToast } = useToastAlertContext();
   const orderedTimeOptions = getOrderedTimeOptions(7.5);
   const selectedRows = useRowSelectionState().map((x: any) => x.data);

   const mutliOccurrenceSelected =
      selectedRows.filter((x: TimeOffDataTable) => x.occurences && x.occurences > 1).length > 0;

   const handleSubmit = async (values: EditTimeOffFormValues) => {
      try {
         setLoading(true);
         const ids = selectedRows.map((x: TimeOffDataTable) => x.id);
         await bulkUpdateTimeOff(ids, values);
         showToast.success(
            I18n.t("views.company.workforce_planning.time_off.modals.bulk_edit_success_toast", {
               count: ids.length,
            }),
         );
         setOpen(false);
         tableApi?.refreshServerSide({});
         setLoading(false);
      } catch (err) {
         setLoading(false);
         setError(true);
      }
   };

   return (
      <>
         <Button onClick={() => setOpen(true)} icon={<Pencil />} variant="tertiary" />
         <Tearsheet open={isOpen} onClose={() => setOpen(false)}>
            <Spinner loading={loading}>
               <Page>
                  <Page.Main
                     style={{
                        width: "400",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                     }}
                  >
                     <Page.Header>
                        {error && (
                           <Page.Banner style={{ width: "400px" }}>
                              <ErrorBanner
                                 title={I18n.t("views.company.workforce_planning.error")}
                                 content={I18n.t(
                                    "views.company.workforce_planning.time_off.modals.edit_error",
                                 )}
                              />
                           </Page.Banner>
                        )}
                        <Page.Title>
                           <H1>
                              {I18n.t(
                                 "views.company.workforce_planning.time_off.modals.bulk_edit_header",
                              )}
                           </H1>
                        </Page.Title>
                     </Page.Header>

                     <Form view="update" onSubmit={handleSubmit}>
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {(formikProps: FormikProps<any>) => {
                           return (
                              <Form.Form>
                                 <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                    <Card>
                                       <Box padding="md">
                                          <Form.Row>
                                             <Form.DateSelect
                                                name="start_date"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.start_date",
                                                )}
                                                colStart={1}
                                                colWidth={6}
                                                onChange={(date) => {
                                                   // Manually set end date when the user selects a start date if end date is not set.
                                                   // This mimics legacy behavior so new modal does not add clicks.
                                                   formikProps.setFieldValue("start_date", date);
                                                   if (!formikProps.values.end_date && date) {
                                                      formikProps.setFieldValue("end_date", date);
                                                   }
                                                }}
                                                disabled={mutliOccurrenceSelected}
                                             />
                                             <Form.DateSelect
                                                name="end_date"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.end_date",
                                                )}
                                                colStart={7}
                                                colWidth={6}
                                                disabledDate={(date) => {
                                                   const { start_date } = formikProps.values;

                                                   return isAfter(start_date, date);
                                                }}
                                                disabled={mutliOccurrenceSelected}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="daily_start_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.daily_start_time",
                                                )}
                                                options={orderedTimeOptions}
                                                colStart={1}
                                                colWidth={5}
                                                required
                                                onSearch={false}
                                             />
                                             <Form.Select
                                                name="daily_end_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.daily_end_time",
                                                )}
                                                options={orderedTimeOptions}
                                                colStart={7}
                                                colWidth={5}
                                                onSearch={false}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="reason"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.reason",
                                                )}
                                                options={timeOffReasons}
                                                onSearch={false}
                                                colStart={1}
                                                colWidth={11}
                                                block
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.RadioButtons
                                                name="type"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.type",
                                                )}
                                                options={getTimeOffTypeOptions(I18n)}
                                             />
                                          </Form.Row>
                                       </Box>
                                    </Card>
                                 </Page.Body>
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="flex-end" alignItems="center">
                                          <FlexList space="md">
                                             <Button
                                                variant="tertiary"
                                                onClick={() => setOpen(false)}
                                             >
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button variant="primary" type="submit">
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              </Form.Form>
                           );
                        }}
                     </Form>
                  </Page.Main>
               </Page>
            </Spinner>
         </Tearsheet>
      </>
   );
};
