import { Checkbox, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition } from "@procore/data-table";
import React, { useEffect } from "react";

type CustomFilterComponentPropTypes = {
   columnDefinition: ColumnDefinition;
   onChange: (newValues: any[]) => void;
   value: any;
};

enum StatusField {
   PROJECT_STATUS = "status",
   PERSON_STATUS = "person_status",
}

export const StatusFilter = (props: CustomFilterComponentPropTypes) => {
   const I18n = useI18nContext();
   const [selected, setSelected] = React.useState<string[]>([]);
   const [field, setField] = React.useState<string>("");

   useEffect(() => {
      const preSelected = props.value ? props.value.map((x: { value: string }) => x.value) : [];
      setSelected(preSelected);
   }, [props.value]);

   useEffect(() => {
      const columnDefinition = props.columnDefinition;
      setField(columnDefinition.field);
   });

   function handleChange(key: "active" | "inactive" | "pending") {
      let newSelected;
      if (selected.includes(key)) {
         newSelected = selected.filter((x: string) => x != key);
      } else {
         newSelected = selected.concat([key]);
      }
      const onChangeParam = newSelected.map((x: string) => ({
         value: x,
         label: I18n.t(`views.company.workforce_planning.${x}`),
      }));
      props.onChange(onChangeParam);
      setSelected(newSelected);
   }

   return (
      <>
         <Checkbox checked={selected.includes("active")} onChange={() => handleChange("active")}>
            {I18n.t("views.company.workforce_planning.active")}
         </Checkbox>
         <Checkbox
            checked={selected.includes("inactive")}
            onChange={() => handleChange("inactive")}
         >
            {I18n.t("views.company.workforce_planning.inactive")}
         </Checkbox>
         {/** added this conditional render over here to use this component generically, since in project view there is pending status as well */}
         {field === StatusField.PROJECT_STATUS && (
            <Checkbox
               checked={selected.includes("pending")}
               onChange={() => handleChange("pending")}
            >
               {I18n.t("views.company.workforce_planning.pending")}
            </Checkbox>
         )}
      </>
   );
};
