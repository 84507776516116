import { useGetPeopleStream } from "@/react/components/common/queries/queries";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { CannedMessageType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { SerializedCannedMessage } from "@laborchart-modules/common/dist/rethink/serializers/canned-message-serializer";
import { Pencil, Plus, Trash } from "@procore/core-icons";
import {
   Box,
   Button,
   Card,
   Grid,
   H2,
   MultiSelect,
   Pill,
   Spinner,
   Title,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { customAlertTypes } from "../constants";
import type { CustomAssignmentAlertsProps, DefaultRecipient } from "../types";
import { useProjectTearsheet, onCustomizeProjectAlertsClick } from "../project-tearsheet";
import type { CannedMessage } from "@laborchart-modules/common";
import styles from "./project-details.scss";
import classnames from "classnames";
import { useModal } from "@/react/shared/hooks/useModal";
import { FileDeleteModal } from "@/react/shared/modals/confirm-file-delete-modal";
import { MessageStore } from "@/stores/message-store.core";
import { useToastAlertContext } from "@procore/toast-alert";
const cx = classnames.bind(styles);

export const ProjectCustomAssignmentAlerts = ({
   projectData,
   editMode,
   loading,
   setEditMode,
   handleChange,
   refetchData,
}: CustomAssignmentAlertsProps) => {
   const I18n = useI18nContext();
   const { showToast } = useToastAlertContext();
   const { checkAuthAction } = usePermissionContext();
   const { groupId } = useGroupContext();
   const { openModal, closeModal, isOpen } = useModal();
   const { data: peopleData, isLoading: peopleDataLoading } = useGetPeopleStream(
      groupId != "my-groups" ? groupId : undefined,
   );
   const { dispatch: projectTearsheetDispatch } = useProjectTearsheet();
   const [canEditCustomAlerts, setCanEditCustomAlerts] = useState(false);
   const [canViewRecipients, setCanViewRecipients] = useState(false);
   const [canEditRecipients, setCanEditRecipients] = useState(false);
   const [defaultRecipients, setDefaultRecipients] = useState<DefaultRecipient[]>([]);
   const [selectedAlertId, setSelectedAlertId] = useState<string>("");

   const [assignmentAlerts, setAssignmentAlerts] = useState<{
      [key in CannedMessageType]: SerializedCannedMessage | null;
   }>({
      [CannedMessageType.ASSIGNMENT_NEW]: null,
      [CannedMessageType.ASSIGNMENT_EDIT]: null,
      [CannedMessageType.ASSIGNMENT_TRANSFER]: null,
      [CannedMessageType.ASSIGNMENT_DELETE]: null,
      [CannedMessageType.ASSIGNMENT_REMINDER]: null,
   });

   useEffect(() => {
      const canEditCustomAlerts = checkAuthAction(AuthAction.EDIT_PROJECT_CUSTOM_ALERTS);
      const canViewDefaultRecipients = checkAuthAction(AuthAction.VIEW_PROJECT_DEFAULT_RECIPIENTS);
      const canEditRecipients = checkAuthAction(AuthAction.EDIT_PROJECT_DEFAULT_RECIPIENTS);
      setCanEditCustomAlerts(canEditCustomAlerts);
      setCanViewRecipients(canViewDefaultRecipients);
      setCanEditRecipients(canEditRecipients);
   }, [checkAuthAction]);

   const toggleSelectedAlertId = (alertId: string) => {
      setSelectedAlertId(alertId);
      openModal();
   };

   const handleCustomAlertDelete = async (messageId: string) => {
      try {
         await MessageStore.deleteCannedMessage(messageId).payload;
         refetchData();
      } catch (error) {
         showToast.error(
            I18n.t(
               "views.company.workforce_planning.project_details_custom_assignment_alerts.delete_error",
            ),
         );
      } finally {
         closeModal();
      }
   };

   useEffect(() => {
      const { canned_messages: cannedMessages = [], default_recipients: recipients = [] } =
         projectData?.data ?? {};

      setDefaultRecipients(
         recipients
            .map((recipient) => ({ ...recipient, label: recipient.name }))
            .sort((a, b) => a.label.localeCompare(b.label)),
      );

      // Search for custom alerts inside the canned messages
      customAlertTypes.forEach((customAlertType) => {
         return cannedMessages.some((message) => {
            if (message.type === customAlertType.type) {
               setAssignmentAlerts((alerts) => ({
                  ...alerts,
                  [customAlertType.type]: message,
               }));
               return true;
            }
         });
      });
   }, [projectData?.data]);

   const renderCustomAlert = (
      alertType: {
         title: string;
         defaultTitle: string;
         type: CannedMessageType;
      },
      projectId: string,
      projectRoles: any[],
   ) => {
      const alertTitle = I18n.t(alertType.title, { defaultValue: alertType.defaultTitle });
      const hasCustomAlert = Boolean(assignmentAlerts[alertType.type]);
      const existingAlertContent = projectData?.data.canned_messages?.find(
         (x) => x.type === alertType.type,
      );

      return (
         <Grid.Row key={alertType.type} data-testid="custom-alert">
            <Grid.Col colWidth={6} style={{ display: "flex", alignItems: "center" }}>
               <Box gap="md" alignItems="center" display="flex">
                  <Typography intent="label" weight="semibold">
                     {alertTitle}
                  </Typography>
                  <Typography>
                     {hasCustomAlert && (
                        <Pill color="green">
                           {" "}
                           {I18n.t("views.company.workforce_planning.active")}{" "}
                        </Pill>
                     )}
                  </Typography>
               </Box>
            </Grid.Col>

            <Grid.Col
               colWidth={6}
               style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}
            >
               {/** if alert content is there and user having the permission of editing custom alerts then render edit and delete buttons */}
               {hasCustomAlert && canEditCustomAlerts ? (
                  <>
                     <Button
                        variant="tertiary"
                        onClick={() =>
                           onCustomizeProjectAlertsClick(
                              projectTearsheetDispatch,
                              projectId,
                              alertType.type,
                              projectRoles,
                              existingAlertContent as CannedMessage,
                           )
                        }
                        data-testid="edit-custom-alert-button"
                        size="sm"
                        className={cx("no-hover-bg no-focus-outline")}
                     >
                        <Pencil size="sm" />
                     </Button>

                     <Button
                        variant="tertiary"
                        onClick={() => toggleSelectedAlertId(existingAlertContent?.id ?? "")}
                        data-testid="delete-custom-alert-button"
                        size="sm"
                        style={{ marginLeft: "-10px" }}
                        className={cx("no-hover-bg no-focus-outline")}
                     >
                        <Trash size="sm" />
                     </Button>
                  </>
               ) : (
                  <Button
                     variant="tertiary"
                     onClick={() =>
                        onCustomizeProjectAlertsClick(
                           projectTearsheetDispatch,
                           projectId,
                           alertType.type,
                           projectRoles,
                           existingAlertContent as CannedMessage,
                        )
                     }
                     data-testid="add-custom-alert-button"
                     size="sm"
                     icon={<Plus size="sm" />}
                  >
                     {I18n.t(
                        "views.company.workforce_planning.project_details_custom_assignment_alerts.add_alert",
                     )}
                  </Button>
               )}
            </Grid.Col>
         </Grid.Row>
      );
   };

   const handleOnChangeRecipients = (recipients: DefaultRecipient[]) => {
      setDefaultRecipients(recipients);
      handleChange({
         target: {
            name: "default_recipient_ids",
            value: recipients.map((recipient) => recipient.id),
         },
      });
   };

   return (
      <Card style={{ marginBottom: 20 }} data-testid="custom-assingment-alerts">
         <Spinner loading={loading} centered>
            <Box padding="lg">
               <Title>
                  <Title.Text>
                     <H2>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_custom_assignment_alerts.title",
                        )}
                     </H2>
                  </Title.Text>
                  <Title.Subtext>
                     {I18n.t(
                        "views.company.workforce_planning.project_details_custom_assignment_alerts.subtitle",
                     )}
                  </Title.Subtext>
               </Title>
            </Box>
            <Box padding="lg" paddingTop="none">
               {/* RENDER CUSTOM ALERTS */}
               <Grid gutterY="md">
                  {customAlertTypes.map((customAlert) => {
                     return renderCustomAlert(
                        customAlert,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        projectData?.data.id!,
                        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                        projectData?.data.roles!,
                     );
                  })}
               </Grid>

               {/* DEFAULT RECIPIENTS */}
               {(canViewRecipients && defaultRecipients && defaultRecipients.length > 0) ||
               canEditRecipients ? (
                  <>
                     <Box paddingTop="md" paddingBottom="md">
                        <hr />
                     </Box>

                     <Box gap="md" display="flex" flexDirection="column">
                        <div className={cx("custom-alert-recipients")}>
                           <Typography intent="label" weight="semibold">
                              {I18n.t(
                                 "views.company.workforce_planning.project_details_custom_assignment_alerts.send_alerts_to",
                              )}
                           </Typography>
                           {/** If the user has permission to edit recipients and not editMode, render the edit button */}
                           {!editMode && canEditRecipients && (
                              <Button
                                 variant="tertiary"
                                 onClick={() => setEditMode()}
                                 data-testid="edit-recipients-button"
                                 size="sm"
                                 className={cx("no-hover-bg no-focus-outline")}
                              >
                                 <Pencil size="sm" />
                              </Button>
                           )}
                        </div>
                        {editMode ? (
                           // If in edit mode, render the MultiSelect component to select recipients
                           <MultiSelect
                              onChange={(option) => handleOnChangeRecipients(option)}
                              disabled={peopleDataLoading}
                              options={(peopleData ?? []) as DefaultRecipient[]}
                              value={defaultRecipients}
                              block
                              data-testid="multiselect-recipients"
                              placeholder="Select Resources"
                           />
                        ) : (
                           // else just display the names read only view
                           <Typography data-testid="recipients">
                              {defaultRecipients.map((recipient) => recipient.name).join(", ")}
                           </Typography>
                        )}
                     </Box>
                  </>
               ) : null}
               {selectedAlertId && (
                  <FileDeleteModal
                     open={isOpen}
                     onClose={closeModal}
                     handleDelete={() => handleCustomAlertDelete(selectedAlertId)}
                     removeText="views.company.workforce_planning.remove"
                  />
               )}
            </Box>
         </Spinner>
      </Card>
   );
};
