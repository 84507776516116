import {
   Flex,
   Search,
   Button,
   useI18nContext,
   FlexList,
   SegmentedController,
} from "@procore/core-react";
import type { Dispatch, SetStateAction } from "react";
import React, { useState } from "react";
import {
   Filter,
   Sliders,
   ZoomMinus,
   ZoomPlus,
   Fullscreen,
   RotateCounterClockwise,
   RotateClockwise,
} from "@procore/core-icons";
import type { ganttFilterType } from "./prop-types";
import { GanttControlPanelFilterPills } from "./gantt-control-panel-filter-pills";
import type { BryntumGantt } from "@bryntum/gantt-react";
import "./gantt-container.css";
import {
   GanttModalType,
   SaveViewModal as DefaultSaveViewModal,
   showGanttModal,
} from "./gantt-modals";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import type { StateTrackingManager } from "@bryntum/gantt";

type VoidFn = () => void;
export interface GanttControlPanelProps {
   search: string;
   ganttFilter: ganttFilterType;
   ganttViewMode: GanttViewMode;
   setGanttViewMode: (value: GanttViewMode) => void;
   setSearch: (value: string) => void;
   toggleConfigPanel: VoidFn;
   toggleFilterPanel: VoidFn;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
   onZoomOut: VoidFn;
   onZoomIn: VoidFn;
   onZoomToFit: VoidFn;
   fileExportName: string;
   ganttRef?: React.RefObject<BryntumGantt>;
   customFilterFields?: any;
   SaveViewModal?: React.ComponentType<any>;
   stm: StateTrackingManager;
   stmPosition: number;
}

export enum GanttViewMode {
   PROJECTS = "projects",
   RESOURCES = "resources",
}

export const GanttControlPanel = ({
   SaveViewModal = DefaultSaveViewModal,
   ...props
}: GanttControlPanelProps) => {
   const I18n = useI18nContext();
   const {
      search,
      setSearch,
      ganttViewMode,
      setGanttViewMode,
      toggleConfigPanel,
      toggleFilterPanel,
      ganttFilter,
      setGanttFilter,
      fileExportName,
      ganttRef,
      customFilterFields,
      stm,
   } = props;

   // Instead of passing the isConfigPanelOpen state in as a prop, just initialize it here and let it always default to closed
   const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);

   return (
      <Flex direction="row" gap="sm" className="ganttControlPanelWrapper">
         <div style={{ display: "grid", gridTemplateColumns: "auto auto auto 1fr" }}>
            {LaunchDarklyClient.getFlagValue("gantt-show-view-mode-controller") && (
               <SegmentedController aria-label={"gantt-view-segmented-controller"} block>
                  <SegmentedController.Segment
                     onClick={() => setGanttViewMode(GanttViewMode.PROJECTS)}
                     active={ganttViewMode === GanttViewMode.PROJECTS}
                  >
                     Projects
                  </SegmentedController.Segment>
                  <SegmentedController.Segment
                     onClick={() => setGanttViewMode(GanttViewMode.RESOURCES)}
                     active={ganttViewMode === GanttViewMode.RESOURCES}
                  >
                     Resources
                  </SegmentedController.Segment>
               </SegmentedController>
            )}
            <div style={{ marginLeft: "14px" }}>
               <Search
                  value={search}
                  onSubmit={(value) => setSearch(value)}
                  onClear={() => setSearch("")}
                  placeholder={I18n.t("views.company.workforce_planning.gantt.search_placeholder")}
                  style={{ width: "300px" }}
                  data-testid="search-input"
               />
            </div>
            <Button
               variant="tertiary"
               icon={<Filter size="sm" />}
               onClick={() => {
                  toggleFilterPanel();
               }}
               style={{ margin: "0 4px 0 8px", width: "max-content" }}
               className={"gantt-filter-toggle-btn"}
               data-testid="filter-panel-toggle-btn"
            />
            <GanttControlPanelFilterPills
               ganttFilter={ganttFilter}
               setGanttFilter={setGanttFilter}
               customFilterFields={customFilterFields}
            />
         </div>
         <FlexList space="xs">
            <Button
               icon={<ZoomMinus size="sm" />}
               onClick={props.onZoomOut}
               variant="tertiary"
               data-testid="zoom-out-btn"
            />
            <Button
               icon={<ZoomPlus size="sm" />}
               onClick={props.onZoomIn}
               variant="tertiary"
               data-testid="zoom-in-btn"
            />
            <Button
               icon={<Fullscreen size="sm" />}
               onClick={props.onZoomToFit}
               variant="tertiary"
               data-testid="zoom-to-fit-btn"
            />
            <Button
               icon={<RotateCounterClockwise size="sm" />}
               onClick={() => stm.undo()}
               disabled={!stm?.canUndo}
               variant="tertiary"
               data-testid="undo-btn"
            />
            <Button
               icon={<RotateClockwise size="sm" />}
               onClick={() => stm.redo()}
               disabled={!stm?.canRedo}
               variant="tertiary"
               data-testid="redo-btn"
            />
            <Button
               variant="tertiary"
               onClick={() => {
                  showGanttModal({
                     type: GanttModalType.ExportView,
                     modalId: "gantt-export-modal-container",
                     title: "Export View",
                     onSave: (modalState: any) => {
                        if (!ganttRef?.current) return;

                        if (modalState.exportType === "pdf") {
                           ganttRef?.current.instance.features.print.print({
                              orientation: "landscape",
                              rowsRange: "visible",
                              scheduleRange: "currentview",
                              exporterType: "multipage",
                              paperFormat: modalState.exportPageSize,
                           });
                        } else if (modalState.exportType === "csv") {
                           ganttRef.current?.instance.features.excelExporter.export({
                              filename: fileExportName,
                              csv: true,
                           });
                        }
                     },
                  });
               }}
               style={{ backgroundColor: "#E3E6E8" }}
            >
               Export
            </Button>
            {/* This modal works a bit differently than the others because it's shared between pages and is a bit more heavily abstracted */}
            <SaveViewModal
               search={search}
               viewConfig={{ search, ganttViewMode, ganttFilter, customFilterFields }}
            />
            <Button
               variant="tertiary"
               icon={<Sliders size="sm" />}
               onClick={() => {
                  toggleConfigPanel();
                  setIsConfigPanelOpen(!isConfigPanelOpen);
               }}
               data-testid="config-panel-toggle-btn"
               className={isConfigPanelOpen ? "controlPanelButtonActive" : ""}
            />
         </FlexList>
      </Flex>
   );
};
