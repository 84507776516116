import React from "react";
import {
   DetailPage,
   H1,
   P,
   Form,
   Required,
   Button,
   useI18nContext,
   Flex,
   Typography,
} from "@procore/core-react";
import { Warning } from "@procore/core-icons";
import { DateTimeFormatter, NumberFormatter } from "@procore/globalization-toolkit";
import type { CustomCurvePropTypes, DataTableRequest } from "../prop-types";
import {
   calculateProjectDurationInWeeks,
   calculateTotalRequestHours,
   findRequestSeriesStartDay,
   formatRequestsForGraph,
} from "../helpers";
import { LaborPlansBreadCrumbs } from "../labor-plans-breadcrumbs";
import { ErrorBanner } from "../../error-banner";
import { CustomGraph } from "./custom-graph";
import {
   findAverageWorkersPerWeek,
   generateBellCurve,
   generateCustomCurve,
   generateLinearPlan,
} from "../curve-generator";
import styles from "../labor-plans.scss";
import classNames from "classnames";

const cx = classNames.bind(styles);

export const CustomCurve = (props: CustomCurvePropTypes) => {
   const I18n = useI18nContext();
   const {
      projectDetailsUrl,
      project,
      totalHours,
      tbdWeeks,
      loading,
      selectedWorkDays,
      onCancel,
      onBreadCrumbClick,
      curveError,
      tearsheet,
      formValues,
      onBack,
      onNext,
      imagesUrl,
   } = props;
   const [requests, setRequests] = React.useState<[]>(
      generateBellCurve(
         calculateProjectDurationInWeeks(project, tbdWeeks),
         project.daily_end_time - project.daily_start_time,
         Number(formValues.total_estimated_hours),
         Number(formValues.max_peak_workers),
         formValues.work_days,
         project,
      ),
   );
   const dateTimeOptions = {
      locale: I18n.currentLocale,
      timeZone: "UTC",
      dateStyle: "short",
   };
   const dateFormatter = new DateTimeFormatter(dateTimeOptions as any);

   const numberOptions = { locale: I18n.currentLocale(), minimumFractionDigits: 0 };
   const numberFormatter = new NumberFormatter(numberOptions);

   const plannedHours = React.useMemo(() => {
      const hours = calculateTotalRequestHours(requests, project, selectedWorkDays);
      return hours;
   }, [requests]);

   const totalRequests = React.useMemo(() => {
      return numberFormatter.formatNumber(
         requests.reduce((sum: number, r: DataTableRequest) => sum + r.weekly_workers, 0),
      );
   }, [requests]);
   const getProjectFormValues = () => {
      if (!project) return {};
      const formattedStartDate = dateFormatter.formatDateTime(project.start_date!);
      const formattedEndDate = project.est_end_date
         ? dateFormatter.formatDateTime(project.est_end_date)
         : I18n.t("views.company.workforce_planning.tbd");
      const total_duration = `${calculateProjectDurationInWeeks(project, tbdWeeks)} ${I18n.t(
         "views.company.workforce_planning.weeks",
      )}`;
      return {
         project: project.name,
         project_hours_to_date: `${numberFormatter.formatNumber(totalHours)} ${I18n.t(
            "views.company.workforce_planning.hours",
         )}`,
         daily_work_hours: `${project.daily_end_time - project.daily_start_time} ${I18n.t(
            "views.company.workforce_planning.hours",
         )}`,
         dates: `${formattedStartDate} - ${formattedEndDate}`,
         total_duration: total_duration,
         work_days: selectedWorkDays,
         labor_curve: "bell_curve",
      };
   };

   function onDraggablePointDrop(mean: number, stdDev: number = 1): void {
      let skewedRequests;
      if (stdDev >= 2) {
         skewedRequests = generateLinearPlan(
            calculateProjectDurationInWeeks(project, tbdWeeks),
            project.daily_end_time - project.daily_start_time,
            Number(formValues.total_estimated_hours),
            Number(formValues.max_peak_workers),
            formValues.work_days,
            project,
         );
      } else {
         skewedRequests = generateCustomCurve(
            calculateProjectDurationInWeeks(project, tbdWeeks),
            project.daily_end_time - project.daily_start_time,
            Number(formValues.total_estimated_hours),
            Number(formValues.max_peak_workers),
            formValues.work_days,
            project,
            mean,
            stdDev,
         );
      }
      setRequests(skewedRequests);
   }

   return (
      <Form view="create" initialValues={getProjectFormValues()} enableReinitialize>
         <>
            <DetailPage.Header transparent>
               {!tearsheet && (
                  <LaborPlansBreadCrumbs
                     onClick={onBreadCrumbClick}
                     project={project}
                     projectDetailsUrl={projectDetailsUrl!}
                  />
               )}
            </DetailPage.Header>
            <DetailPage.Body>
               {curveError && (
                  <ErrorBanner
                     title={I18n.t("views.company.workforce_planning.error")}
                     content={I18n.t("views.company.workforce_planning.labor_plan.curve_error")}
                  />
               )}
               <DetailPage.Title>
                  <H1>{I18n.t("views.company.workforce_planning.labor_plan.create_title")}</H1>
                  <P>{I18n.t("views.company.workforce_planning.labor_plan.details_text")}</P>
               </DetailPage.Title>
               <DetailPage.Card>
                  <DetailPage.Section
                     heading={I18n.t("views.company.workforce_planning.project_details")}
                  >
                     <Form.Form>
                        <Form.Row>
                           <Form.Text
                              view="read"
                              name="project"
                              label={I18n.t("views.company.workforce_planning.project")}
                              colWidth={3}
                           />
                           <Form.Text
                              view="read"
                              name="dates"
                              label={I18n.t("views.company.workforce_planning.dates")}
                              colStart={5}
                              colWidth={3}
                           />
                           <Form.Text
                              view="read"
                              name="total_duration"
                              label={I18n.t(
                                 "views.company.workforce_planning.labor_plan.labels.total_duration",
                              )}
                              colStart={9}
                              colWidth={3}
                              tooltip={I18n.t(
                                 "views.company.workforce_planning.labor_plan.tooltips.duration",
                              )}
                           />
                        </Form.Row>
                        <Form.Row>
                           <Form.Text
                              view="read"
                              name="daily_work_hours"
                              label={I18n.t(
                                 "views.company.workforce_planning.labor_plan.labels.daily_work_hours",
                              )}
                              tooltip={I18n.t(
                                 "views.company.workforce_planning.labor_plan.tooltips.daily_work_hours",
                              )}
                           />
                           <Form.Text
                              view="read"
                              name="project_hours_to_date"
                              label={I18n.t(
                                 "views.company.workforce_planning.labor_plan.labels.project_hours_to_date",
                              )}
                              colStart={5}
                              tooltip={I18n.t(
                                 "views.company.workforce_planning.labor_plan.tooltips.hours_to_date",
                              )}
                           />
                        </Form.Row>
                        <DetailPage.Section
                           heading={I18n.t(
                              "views.company.workforce_planning.labor_plan.labels.customize_curve",
                           )}
                           className={cx("custom-graph-section")}
                        >
                           <P style={{ paddingTop: "0" }}>
                              {I18n.t(
                                 "views.company.workforce_planning.labor_plan.labels.customize_curve_text",
                              )}
                           </P>
                           <div style={{ paddingTop: "20px" }}>
                              <CustomGraph
                                 data={formatRequestsForGraph(requests, "#d3d3d3", false)}
                                 graphStart={findRequestSeriesStartDay(requests).getTime()}
                                 yAxisTitle={I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.projected_workers",
                                 )}
                                 height={"500px"}
                                 imagesUrl={imagesUrl}
                                 onDraggablePointDrop={onDraggablePointDrop}
                                 dragMinY={findAverageWorkersPerWeek(
                                    Number(formValues.total_estimated_hours),
                                    calculateProjectDurationInWeeks(project, tbdWeeks),
                                    project.daily_end_time - project.daily_start_time,
                                    selectedWorkDays,
                                 )}
                              />
                           </div>
                           <Flex paddingTop="sm">
                              <Typography weight="semibold">
                                 {I18n.t(
                                    "views.company.workforce_planning.labor_plan.estimated_total_requests",
                                    {
                                       requests: totalRequests,
                                    },
                                 )}
                              </Typography>
                              <Typography>{` ${I18n.t(
                                 "views.company.workforce_planning.labor_plan.hours_count",
                                 { hours: numberFormatter.formatNumber(plannedHours) },
                              )}`}</Typography>
                              {plannedHours > Number(formValues.total_estimated_hours) && (
                                 <Warning
                                    size="sm"
                                    style={{ color: "#D5A411", paddingTop: "2px" }}
                                 />
                              )}
                           </Flex>
                        </DetailPage.Section>
                     </Form.Form>
                  </DetailPage.Section>
               </DetailPage.Card>
            </DetailPage.Body>
            {!loading && (
               <DetailPage.Footer
                  style={{ position: "fixed", bottom: "0", width: tearsheet ? "952px" : "100%" }}
               >
                  <DetailPage.FooterNotation className={cx("footerContent")}>
                     <Required showLabel />
                  </DetailPage.FooterNotation>
                  <DetailPage.FooterActions className={cx("footerContent")}>
                     <Button variant="secondary" onClick={() => onBack()}>
                        {I18n.t("views.company.workforce_planning.back")}
                     </Button>
                     <Button variant="secondary" onClick={() => onCancel()}>
                        {I18n.t("views.company.workforce_planning.cancel")}
                     </Button>
                     <Button type="submit" onClick={() => onNext(requests)}>
                        {I18n.t("views.company.workforce_planning.next")}
                     </Button>
                  </DetailPage.FooterActions>
               </DetailPage.Footer>
            )}
         </>
      </Form>
   );
};
