import {
   DetailPage,
   H1,
   Form,
   Flex,
   Typography,
   Button,
   Switch,
   useI18nContext,
   Spinner,
} from "@procore/core-react";
import React, { useMemo, useState, useRef } from "react";
import type { JobTitle } from "@laborchart-modules/common/dist/postgres/schemas/types/job_titles";
import type { CostCode as Category } from "@laborchart-modules/common";
import {
   ClientSideDataTable,
   NumberCellRenderer,
   NumberCellEditor,
   SelectCellRenderer,
   SelectCellEditor,
   DateCellRenderer,
   DateCellEditor,
   type CellValueChangeParams,
   type ColumnDefinition,
   type DateCellColumnDefinition,
   type NumberCellColumnDefinition,
   type SelectCellColumnDefinition,
   type TableApi,
} from "@procore/data-table";
import { NumberFormatter } from "@procore/globalization-toolkit";
import type { DataTableRequest, LaborPlansRequestFormProps } from "./prop-types";
import { LaborPlansBreadCrumbs } from "./labor-plans-breadcrumbs";
import {
   calculateTotalRequestHours,
   getEndDayfromEndWeek,
   getNewWeekEndFromDate,
   getNewWeekStartFromDate,
   getStartDayFromStartWeek,
   addDatesToRequestsCurve,
   formatRequestsForGraph,
   findRequestSeriesStartDay,
} from "./helpers";
import { LaborGraph } from "../labor-graph/labor-graph";
import { Warning } from "@procore/core-icons";
import { CustomActionCellRenderer } from "./custom-action-cell/custom-action-cell";
import { v4 } from "uuid";
import styles from "./labor-plans.scss";
import classnames from "classnames";

const cx = classnames.bind(styles);

export const LaborPlansRequestsForm = (props: LaborPlansRequestFormProps) => {
   const {
      projectDetailsUrl,
      project,
      selectedWorkDays,
      formValues,
      jobTitles,
      requests,
      onBack,
      onAddRequests,
      onCancel,
      onBreadCrumbClick,
      tearsheet,
      loading,
   } = props;
   const I18n = useI18nContext();
   const [tableApi, setTableApi] = useState<TableApi>();
   const [useWeeks, setUseWeeks] = useState<boolean>(true);
   const [dtRequests, setDTRequests] = useState<DataTableRequest[]>(props.requests);
   const stateRef = useRef<any[]>([]);
   stateRef.current = dtRequests;
   const shortWorkdaysMap = [
      I18n.t("views.company.workforce_planning.sunday_abbrev"),
      I18n.t("views.company.workforce_planning.monday_abbrev"),
      I18n.t("views.company.workforce_planning.tuesday_abbrev"),
      I18n.t("views.company.workforce_planning.wednesday_abbrev"),
      I18n.t("views.company.workforce_planning.thursday_abbrev"),
      I18n.t("views.company.workforce_planning.friday_abbrev"),
      I18n.t("views.company.workforce_planning.saturday_abbrev"),
   ];

   const numberOptions = { locale: I18n.currentLocale(), minimumFractionDigits: 0 };
   const numberFormatter = new NumberFormatter(numberOptions);

   const getProjectFormValues = () => {
      let workdays_string = "";
      Object.keys(selectedWorkDays).forEach((k: any) => {
         if (selectedWorkDays[k]) {
            if (workdays_string == "") {
               workdays_string += shortWorkdaysMap[Number(k)];
            } else {
               workdays_string += `, ${shortWorkdaysMap[Number(k)]}`;
            }
         }
      });
      return {
         ...formValues,
         total_estimated_hours: numberFormatter.formatNumber(formValues.total_estimated_hours),
         daily_work_hours: `${project.daily_end_time - project.daily_start_time} ${I18n.t(
            "views.company.workforce_planning.hours",
         )} - ${workdays_string}`,
         labor_curve: I18n.t(
            `views.company.workforce_planning.labor_plan.labels.${formValues.labor_curve}`,
         ),
         max_peak_workers: formValues.max_peak_workers
            ? numberFormatter.formatNumber(formValues.max_peak_workers)
            : null,
      };
   };
   const plannedHours = useMemo(() => {
      const hours = calculateTotalRequestHours(dtRequests, project, selectedWorkDays);
      return numberFormatter.formatNumber(hours);
   }, [dtRequests]);

   const totalRequests = useMemo(() => {
      return numberFormatter.formatNumber(
         dtRequests.reduce((sum: number, r: DataTableRequest) => (sum += r.weekly_workers), 0),
      );
   }, [dtRequests, numberFormatter]);

   const getTotalRequests = () => totalRequests;

   const actionCellDefinition: ColumnDefinition = {
      field: "action",
      headerName: I18n.t("views.company.workforce_planning.actions"),
      cellRenderer: CustomActionCellRenderer,
      editable: false,
      sortable: false,
      cellRendererParams: {
         onDeleteRow: (params: DataTableRequest) => {
            /* istanbul ignore next */
            if (!tableApi) return;
            tableApi.applyTransaction({ remove: [params] });
            const dataTableRequests = tableApi.getRowData();
            setDTRequests(dataTableRequests);
         },
         onCopyRow: (params: DataTableRequest) => {
            /* istanbul ignore next */
            if (!tableApi) return;
            const rowIndex = tableApi
               .getRowData()
               .findIndex((r: DataTableRequest) => r.id === params.id);
            tableApi.applyTransaction({
               add: [{ ...params, id: v4() }],
               addIndex: rowIndex,
            });
            const dataTableRequests = tableApi.getRowData();
            setDTRequests(dataTableRequests);
         },
         onAddRowAbove: (params: DataTableRequest) => {
            /* istanbul ignore next */
            if (!tableApi) return;
            const baseRequest = [
               {
                  id: v4(),
                  weekly_workers: 1,
                  week_start: 1,
                  week_end: 1,
                  request_duration: 1,
                  job_title: null,
                  category: null,
               },
            ];
            const newRequest = addDatesToRequestsCurve(baseRequest, project, selectedWorkDays);
            const rowIndex = tableApi
               .getRowData()
               .findIndex((r: DataTableRequest) => r.id === params.id);
            tableApi.applyTransaction({
               add: [...newRequest],
               addIndex: rowIndex,
            });
            setDTRequests(tableApi.getRowData());
         },
         onAddRowBelow: (params: DataTableRequest) => {
            /* istanbul ignore next */
            if (!tableApi) return;
            const baseRequest = [
               {
                  id: v4(),
                  weekly_workers: 1,
                  week_start: 1,
                  week_end: 1,
                  request_duration: 1,
                  job_title: null,
                  category: null,
               },
            ];
            const newRequest = addDatesToRequestsCurve(baseRequest, project, selectedWorkDays);
            const rowIndex = tableApi
               .getRowData()
               .findIndex((r: DataTableRequest) => r.id === params.id);
            tableApi.applyTransaction({
               add: [...newRequest],
               addIndex: rowIndex + 1,
            });
            setDTRequests(tableApi.getRowData());
         },
      },
   };

   const weeksColumnDefinitions: ColumnDefinition[] = [
      {
         field: "weekly_workers",
         editable: true,
         headerName: I18n.t("views.company.workforce_planning.quanity"),
         cellRenderer: NumberCellRenderer,
         cellEditor: NumberCellEditor,
         flex: 1,
      } as NumberCellColumnDefinition,
      {
         editable: true,
         field: "job_title",
         cellRenderer: SelectCellRenderer,
         headerName: I18n.t("views.company.workforce_planning.job_title"),
         cellEditor: SelectCellEditor,
         label: "--",
         flex: 2,
         cellEditorParams: {
            selectOptions: jobTitles,
            getId: (item) => item?.id,
         },
         getStringFormattedValue: (value: JobTitle) => {
            if (value === undefined) {
               return "--";
            }
            return value?.name;
         },
      } as SelectCellColumnDefinition,
      {
         field: "week_start",
         editable: true,
         headerName: I18n.t("views.company.workforce_planning.start_week"),
         cellRenderer: NumberCellRenderer,
         cellEditor: NumberCellEditor,
         flex: 3,
      } as NumberCellColumnDefinition,
      {
         field: "request_duration",
         editable: true,
         headerName: I18n.t("views.company.workforce_planning.duration_in_weeks"),
         cellRenderer: NumberCellRenderer,
         cellEditor: NumberCellEditor,
      } as NumberCellColumnDefinition,
      {
         editable: true,
         field: "category",
         cellRenderer: SelectCellRenderer,
         headerName: I18n.t("views.company.workforce_planning.category"),
         cellEditor: SelectCellEditor,
         label: "Uncategorized",
         flex: 4,
         cellEditorParams: {
            selectOptions: project.categories,
            getId: (item) => item?.id,
         },
         getStringFormattedValue: (value: Category) => {
            if (value === undefined) {
               return I18n.t("views.company.workforce_planning.uncategorized");
            }
            return value?.name;
         },
      } as SelectCellColumnDefinition,
      actionCellDefinition,
   ];

   const datesColumnDefinitions: ColumnDefinition[] = [
      {
         field: "weekly_workers",
         editable: true,
         headerName: I18n.t("views.company.workforce_planning.quanity"),
         cellRenderer: NumberCellRenderer,
         cellEditor: NumberCellEditor,
         flex: 1,
      } as NumberCellColumnDefinition,
      {
         editable: true,
         field: "job_title",
         cellRenderer: SelectCellRenderer,
         headerName: I18n.t("views.company.workforce_planning.job_title"),
         cellEditor: SelectCellEditor,
         label: "--",
         flex: 2,
         cellEditorParams: {
            selectOptions: jobTitles,
            getId: (item) => item?.id,
         },
         getStringFormattedValue: (value: JobTitle) => {
            if (value === undefined) {
               return "--";
            }
            return value?.name;
         },
      } as SelectCellColumnDefinition,
      {
         editable: true,
         field: "start_day",
         cellRenderer: DateCellRenderer,
         headerName: I18n.t("views.company.workforce_planning.start_date"),
         cellEditor: DateCellEditor,
         cellEditorParams: {
            disabledDate: (date: Date) => date < new Date(project.start_date!),
         },
      } as DateCellColumnDefinition,
      {
         editable: true,
         field: "end_day",
         cellRenderer: DateCellRenderer,
         headerName: I18n.t("views.company.workforce_planning.end_date"),
         cellEditor: DateCellEditor,
         cellEditorParams: {
            disabledDate: (date: Date) => date < new Date(project.start_date!),
         },
      } as DateCellColumnDefinition,
      {
         editable: true,
         field: "category",
         cellRenderer: SelectCellRenderer,
         headerName: I18n.t("views.company.workforce_planning.category"),
         cellEditor: SelectCellEditor,
         label: "Uncategorized",
         flex: 4,
         cellEditorParams: {
            selectOptions: project.categories,
            getId: (item) => item?.id,
         },
         getStringFormattedValue: (value: Category) => {
            if (value === undefined) {
               return I18n.t("views.company.workforce_planning.uncategorized");
            }
            return value?.name;
         },
      } as SelectCellColumnDefinition,
      actionCellDefinition,
   ];

   const getColumnDefinitions = () => {
      if (useWeeks) return weeksColumnDefinitions;
      else return datesColumnDefinitions;
   };

   /* istanbul ignore next */
   const handleCellValuesChanged = (params: CellValueChangeParams<any>) => {
      const requestRef = [...stateRef.current];
      const r = requestRef.find((x) => x.id == params.rowData.id);
      if (params.field == "weekly_workers") {
         r.weekly_workers = Number(params.newValue);
      }
      if (params.field == "job_title") {
         r.job_title = params.newValue;
      }
      if (params.field == "week_start") {
         const newValue = Number(params.newValue);
         const newEndWeek = newValue + r.request_duration - 1;
         r.week_start = newValue;
         r.week_end = newEndWeek;
         r.start_day = getStartDayFromStartWeek(r, project, selectedWorkDays);
         r.end_day = getEndDayfromEndWeek(r, project, selectedWorkDays);
      }
      if (params.field == "request_duration") {
         const newValue = Number(params.newValue);
         const newEndWeek = r.week_start + newValue - 1;
         r.request_duration = newValue;
         r.week_end = newEndWeek;
         r.end_day = getEndDayfromEndWeek(r, project, selectedWorkDays);
      }
      if (params.field == "category") {
         r.category = params.newValue;
      }
      if (params.field == "start_day") {
         r.start_day = params.newValue;
         r.week_start = getNewWeekStartFromDate(params.newValue, project, selectedWorkDays);
         r.request_duration = r.week_end - r.week_start + 1;
      }
      if (params.field == "end_day") {
         r.end_day = params.newValue;
         r.week_end = getNewWeekEndFromDate(params.newValue, project, selectedWorkDays);
         r.request_duration = r.week_end - r.week_start + 1;
      }
      // Need to use the [...] syntax or React will not recognize state change needed to recalculate total hours
      setDTRequests([...requestRef]);
   };

   return (
      <Form view="read" initialValues={getProjectFormValues()}>
         <Form.Form>
            <Spinner loading={loading} style={{ height: "100%" }}>
               <div className={cx("labor-plans-request-form-wrapper")}>
                  <DetailPage.Header transparent>
                     {!tearsheet && (
                        <LaborPlansBreadCrumbs
                           onClick={onBreadCrumbClick}
                           project={project}
                           projectDetailsUrl={projectDetailsUrl!}
                        />
                     )}
                  </DetailPage.Header>
                  <DetailPage.Body className={cx("labor-plans-requests-form-body")}>
                     <DetailPage.Title>
                        <H1>
                           {I18n.t("views.company.workforce_planning.labor_plan.create_title")}
                        </H1>
                     </DetailPage.Title>
                     <DetailPage.Card
                        style={{
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <DetailPage.Section
                           expandId={1}
                           heading={I18n.t("views.company.workforce_planning.project_details")}
                           style={{
                              flexGrow: "2",
                              minWidth: "40%",
                           }}
                        >
                           <div
                              style={{
                                 display: "flex",
                                 flexDirection: "row",
                                 justifyContent: "space-between",
                              }}
                           >
                              <div className={cx("form-rows")}>
                                 <Form.Row>
                                    <Form.Text
                                       name="project"
                                       label={I18n.t("views.company.workforce_planning.project")}
                                       colWidth={4}
                                       style={{ alignSelf: "auto" }}
                                    />
                                    <Form.Text
                                       name="dates"
                                       label={I18n.t("views.company.workforce_planning.dates")}
                                       colStart={5}
                                       colWidth={4}
                                       style={{ alignSelf: "auto" }}
                                    />
                                    <Form.Text
                                       name="total_duration"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.total_duration",
                                       )}
                                       colStart={9}
                                       colWidth={4}
                                       tooltip={I18n.t(
                                          "views.company.workforce_planning.labor_plan.tooltips.duration",
                                       )}
                                       style={{ alignSelf: "auto" }}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Text
                                       colWidth={4}
                                       name="daily_work_hours"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.daily_work_hours",
                                       )}
                                       tooltip={I18n.t(
                                          "views.company.workforce_planning.labor_plan.tooltips.daily_work_hours",
                                       )}
                                       style={{ alignSelf: "auto" }}
                                    />
                                    <Form.Text
                                       name="project_hours_to_date"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.project_hours_to_date",
                                       )}
                                       colStart={5}
                                       colWidth={4}
                                       tooltip={I18n.t(
                                          "views.company.workforce_planning.labor_plan.tooltips.hours_to_date",
                                       )}
                                       style={{ alignSelf: "auto" }}
                                    />
                                    <Form.Text
                                       name="total_estimated_hours"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.total_estimated_hours",
                                       )}
                                       colStart={9}
                                       colWidth={4}
                                       style={{ alignSelf: "auto" }}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Text
                                       name="max_peak_workers"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.max_peak_workers",
                                       )}
                                       colWidth={4}
                                       style={{ alignSelf: "auto" }}
                                    />
                                    <Form.Text
                                       colStart={5}
                                       colWidth={4}
                                       name="labor_curve"
                                       label={I18n.t(
                                          "views.company.workforce_planning.labor_plan.labels.labor_curve",
                                       )}
                                       style={{ alignSelf: "auto" }}
                                    />
                                 </Form.Row>
                              </div>
                              <LaborGraph
                                 data={formatRequestsForGraph(dtRequests)}
                                 graphStart={findRequestSeriesStartDay(dtRequests).getTime()}
                                 yAxisTitle={I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.projected_workers",
                                 )}
                                 xTickInterval={1}
                                 yTickInterval={5}
                                 enableZoom={true}
                                 height={"300px"}
                                 width={"50%"}
                              />
                           </div>
                        </DetailPage.Section>
                     </DetailPage.Card>
                     <DetailPage.Card>
                        <DetailPage.Section
                           heading={I18n.t("views.company.workforce_planning.requests")}
                           className={cx("requests-section")}
                        >
                           <Flex paddingBottom="sm" gap="sm">
                              <Switch checked={useWeeks} onChange={() => setUseWeeks(!useWeeks)} />
                              <Typography weight="semibold">
                                 {I18n.t(
                                    "views.company.workforce_planning.labor_plan.labels.duration_toggle_duration",
                                 )}
                              </Typography>
                           </Flex>
                           <div className={cx("laborPlansDataTable")}>
                              <ClientSideDataTable
                                 columnDefinitions={getColumnDefinitions()}
                                 getRowId={(params) => {
                                    return params.data?.id;
                                 }}
                              >
                                 <ClientSideDataTable.Table
                                    rows={requests}
                                    onCellValueChanged={handleCellValuesChanged}
                                    onTableReady={(tableApi: TableApi) => setTableApi(tableApi)}
                                 />
                              </ClientSideDataTable>
                           </div>
                           <Flex paddingTop="sm">
                              <Typography weight="semibold">
                                 {I18n.t(
                                    "views.company.workforce_planning.labor_plan.total_requests",
                                    {
                                       requests: getTotalRequests(),
                                    },
                                 )}
                              </Typography>
                              <Typography>{` ${I18n.t(
                                 "views.company.workforce_planning.labor_plan.hours_count",
                                 { hours: plannedHours },
                              )}`}</Typography>
                              {plannedHours > formValues.total_estimated_hours && (
                                 <Warning
                                    size="sm"
                                    style={{ color: "#D5A411", paddingTop: "2px" }}
                                 />
                              )}
                           </Flex>
                        </DetailPage.Section>
                     </DetailPage.Card>
                  </DetailPage.Body>
                  <DetailPage.Footer
                     style={{ position: "fixed", bottom: "0", width: tearsheet ? "952px" : "100%" }}
                  >
                     <DetailPage.FooterActions>
                        <Button variant="tertiary" onClick={() => onCancel()}>
                           {I18n.t("views.company.workforce_planning.cancel")}
                        </Button>
                        <Button variant="secondary" onClick={() => onBack()}>
                           {I18n.t("views.company.workforce_planning.back")}
                        </Button>
                        <Button
                           type="submit"
                           onClick={() => {
                              onAddRequests(dtRequests);
                           }}
                        >
                           {I18n.t("views.company.workforce_planning.labor_plan.add_requests")}
                        </Button>
                     </DetailPage.FooterActions>
                  </DetailPage.Footer>
               </div>
            </Spinner>
         </Form.Form>
      </Form>
   );
};
