import React, { useEffect, useState } from "react";
import {
   Avatar,
   Box,
   Button,
   Card,
   EmptyState,
   Flex,
   Grid,
   H3,
   P,
   Spinner,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import classnames from "classnames";
import styles from "../project/project-detail-cards/project-details.scss";
import {
   NotesType,
   type DetailsNotesCardProps,
   type EmptyNotesProps,
   type ListNotesCardProps,
} from "./types";
import { useModal } from "@/react/shared/hooks/useModal";
import { ActionMode } from "@/react/prop-types";
import { Lock, Pencil, Trash, Unlock } from "@procore/core-icons";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { formatFullDateTime, getDateString, getInitials } from "../project/helpers";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { AddNotes } from "./details-add-notes";
import { useToastAlertContext } from "@procore/toast-alert";
import type {
   CreateNotePayload,
   CreateNoteResponse,
} from "@laborchart-modules/lc-core-api/dist/api/notes/create-note";
import { NoteStore } from "@/stores/note-store.core";
import type { UpdateNoteResponse } from "@laborchart-modules/lc-core-api/dist/api/notes/update-note";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { dateSortOptions } from "@/react/shared/constants";
import { FileDownloadModal } from "@/react/shared/modals/file-download-modal";
import { NotesToolbar } from "@/react/shared/components/notes-toolbar";
import type { DeleteNoteResponse } from "@laborchart-modules/lc-core-api/dist/api/notes/delete-note";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import { commonButtonStyle } from "@/react/shared/helper";

const cx = classnames.bind(styles);

const EmptyNotes: React.FC<EmptyNotesProps> = ({
   openAddNotesModal,
   isAddNotesOpen,
   canEditNotes,
}) => {
   const I18n = useI18nContext();
   if (isAddNotesOpen) {
      return null;
   }
   // title is same for project and people
   const emptyNotesTitle =
      "views.company.workforce_planning.project_details_notes.notes_empty_state_title";

   // description is same for project and people
   const emptyNotesDescription =
      "views.company.workforce_planning.project_details_notes.notes_empty_state_description";

   // button text is same for project and people
   const buttonText = "views.company.workforce_planning.project_details_notes.add_note";
   return (
      <Box padding="sm">
         <EmptyState compact>
            <EmptyState.NoItems />
            <EmptyState.Title>{I18n.t(emptyNotesTitle)}</EmptyState.Title>
            <EmptyState.Description className={cx("empty-state-description")}>
               {I18n.t(emptyNotesDescription)}
            </EmptyState.Description>
            {canEditNotes && (
               <EmptyState.Actions className={cx("empty-state-actions")}>
                  <Button variant="secondary" onClick={openAddNotesModal}>
                     {I18n.t(buttonText)}
                  </Button>
               </EmptyState.Actions>
            )}
         </EmptyState>
      </Box>
   );
};

const ListNotesCard: React.FC<ListNotesCardProps> = ({
   notes,
   onUpdateNote,
   selectedNoteId,
   setSelectedNoteId,
   openAddNotesModal,
   isAddNotesOpen,
   canEditNotes,
   onDeleteNote,
   showLoader,
   noteType,
}) => {
   const I18n = useI18nContext();
   const [sortOption, setSortOption] = useState(dateSortOptions[1]);
   const [sortedNotes, setSortedNotes] = useState(notes);
   const [mode, setMode] = useState<ActionMode>(ActionMode.EDIT);
   const { openModal, closeModal, isOpen } = useModal();
   const [selectedAttachment, setSelectedAttachment] = useState<any>(null);

   const toggleSelectedNote = (noteId: string, mode: ActionMode) => {
      setSelectedNoteId(selectedNoteId === noteId ? "" : noteId);
      setMode(mode);
      openModal();
   };

   const resetView = () => {
      closeModal();
      setSelectedNoteId("");
   };

   const handleDownloadLinkClick = (attachment: SerializedAttachment) => {
      setSelectedAttachment(attachment);
      openModal();
   };

   const handleModalClose = () => {
      setSelectedAttachment(null);
      closeModal();
   };

   useEffect(() => {
      const sorted = [...notes].sort((a, b) => {
         const dateA = new Date(a.last_edited).getTime();
         const dateB = new Date(b.last_edited).getTime();
         if (sortOption.value === Order.ASCENDING) {
            return dateA - dateB;
         }
         return dateB - dateA;
      });
      setSortedNotes(sorted);
   }, [sortOption, notes]);

   return (
      <>
         <Box padding="sm">
            <NotesToolbar
               sortOptions={dateSortOptions}
               selectedSortOption={sortOption}
               onSortOptionSelect={setSortOption}
               onAddNoteClick={openAddNotesModal}
               isAddNoteDisabled={isAddNotesOpen || selectedNoteId !== ""}
               canEditNotes={canEditNotes}
            />
         </Box>

         {!isAddNotesOpen &&
            sortedNotes.map((note) => (
               <Box key={note.id} padding="sm" marginBottom="sm" className={cx("custom-card")}>
                  {selectedNoteId !== note.id && (
                     <Grid>
                        <Grid.Row>
                           <Grid.Col style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <Avatar>
                                 <Avatar.Label>
                                    {getInitials(`${note?.author?.first} ${note?.author.last}`)}
                                 </Avatar.Label>
                              </Avatar>
                              <Typography intent="h3">
                                 {`${note?.author?.first} ${note?.author.last}`}
                              </Typography>
                              <Typography color="gray45" intent="small">
                                 {`${formatFullDateTime(note?.last_edited)}`}
                              </Typography>
                              <Box display="flex" alignItems="center">
                                 <Typography color="gray45" intent="small">
                                    {note?.is_private ? <Lock size="sm" /> : <Unlock size="sm" />}
                                 </Typography>
                                 <Typography color="gray45" intent="small">
                                    {note?.is_private ? "Private" : "Public"}
                                 </Typography>
                              </Box>
                           </Grid.Col>
                           {canEditNotes && (
                              <Grid.Col>
                                 <Flex justifyContent="flex-end">
                                    <Pencil
                                       size="sm"
                                       data-testid="edit-note"
                                       style={{
                                          ...commonButtonStyle({
                                             selectedId: selectedNoteId,
                                             currentId: note.id,
                                          }),
                                          marginRight: "10px",
                                       }}
                                       onClick={() => toggleSelectedNote(note.id, ActionMode.EDIT)}
                                    />
                                    <Trash
                                       size="sm"
                                       data-testid="delete-note"
                                       style={commonButtonStyle({
                                          selectedId: selectedNoteId,
                                          currentId: note.id,
                                       })}
                                       onClick={() =>
                                          toggleSelectedNote(note.id, ActionMode.DELETE)
                                       }
                                    />
                                 </Flex>
                              </Grid.Col>
                           )}
                        </Grid.Row>
                        <Grid.Row>
                           <Grid.Col colWidth={6}>
                              <Box marginTop="sm" paddingRight="lg">
                                 <H3>Note</H3>
                                 <P>{note?.content}</P>
                              </Box>
                           </Grid.Col>

                           <Grid.Col colWidth={6} style={{ display: "flex" }}>
                              <Box marginTop="sm">
                                 <H3>
                                    {I18n.t(
                                       "views.company.workforce_planning.project_detail_attachments.title",
                                    )}
                                 </H3>
                                 {note.attachments && note.attachments.length > 0 && (
                                    <AttachmentLink
                                       attachments={note.attachments}
                                       onLinkClick={handleDownloadLinkClick}
                                    />
                                 )}
                              </Box>
                           </Grid.Col>
                        </Grid.Row>
                     </Grid>
                  )}
                  {selectedNoteId === note.id && (
                     <AddNotes
                        onClose={resetView}
                        handleCreateUpdate={onUpdateNote}
                        mode={mode}
                        initialValues={note}
                        showToolBar={false}
                        canEditNotes={canEditNotes}
                        handleDelete={onDeleteNote}
                        showLoader={showLoader}
                        noteType={noteType}
                     />
                  )}
               </Box>
            ))}
         {selectedAttachment && (
            <FileDownloadModal
               open={isOpen}
               onClose={handleModalClose}
               fileName={selectedAttachment.name}
               uploadedOn={getDateString(new Date(selectedAttachment.created_at))}
               attachmentId={selectedAttachment.id}
               mimeType={selectedAttachment.mimetype}
            />
         )}
         <Box padding="xxs" />
      </>
   );
};

export const DetailsNotesCard: React.FC<DetailsNotesCardProps> = ({
   refetchData,
   loading: DataLoading,
   noteType,
   notesData,
   contextId,
}) => {
   const I18n = useI18nContext();
   const { openModal, isOpen, closeModal } = useModal();
   const [loading, setLoading] = useState<boolean>(false);
   const { showToast } = useToastAlertContext();
   const [selectedNoteId, setSelectedNoteId] = useState<string>("");
   const { checkAuthAction } = usePermissionContext();
   const [canEditNotes, setCanEditNotes] = React.useState<boolean>(false);
   const [showToolBar, setShowToolBar] = useState<boolean>(true);

   useEffect(() => {
      const valueAction =
         noteType === NotesType.PROJECT
            ? AuthAction.EDIT_PROJECT_NOTES
            : AuthAction.EDIT_PEOPLE_NOTES;
      const canEditNotes = checkAuthAction(valueAction);
      setCanEditNotes(canEditNotes);
   }, [checkAuthAction, noteType]);

   const handleNoteCreateUpdate = async (payload: CreateNotePayload, noteId?: string) => {
      setLoading(true);
      let noteCreateUpdate: CreateNoteResponse | UpdateNoteResponse;
      try {
         const idObj =
            noteType === NotesType.PROJECT ? { project_id: contextId } : { person_id: contextId };
         if (!noteId) {
            noteCreateUpdate = await NoteStore.createNote(payload, idObj).payload;
         } else {
            noteCreateUpdate = await NoteStore.updateNote(noteId, idObj, payload).payload;
         }
         if (noteCreateUpdate.data) {
            refetchData();
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
         closeModal();
      }
   };

   const handleNoteDelete = async (noteId: string) => {
      setLoading(true);
      let noteDeletion: DeleteNoteResponse;
      const idObj =
         noteType === NotesType.PROJECT ? { project_id: contextId } : { person_id: contextId };
      try {
         noteDeletion = await NoteStore.deleteNote(noteId, idObj).payload;
         if (noteDeletion.data) {
            refetchData();
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
         closeModal();
      }
   };

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={DataLoading}>
            {notesData && notesData.data?.notes.length === 0 && (
               <EmptyNotes
                  openAddNotesModal={() => {
                     setShowToolBar(true);
                     openModal();
                  }}
                  isAddNotesOpen={isOpen}
                  canEditNotes={canEditNotes}
               />
            )}
            {notesData && notesData.data?.notes.length > 0 && (
               <ListNotesCard
                  notes={notesData?.data?.notes}
                  onUpdateNote={handleNoteCreateUpdate}
                  selectedNoteId={selectedNoteId}
                  setSelectedNoteId={setSelectedNoteId}
                  openAddNotesModal={() => {
                     setShowToolBar(false);
                     openModal();
                  }}
                  isAddNotesOpen={isOpen}
                  canEditNotes={canEditNotes}
                  onDeleteNote={handleNoteDelete}
                  showLoader={loading}
                  noteType={noteType}
               />
            )}
            {isOpen && (
               <AddNotes
                  onClose={closeModal}
                  handleCreateUpdate={handleNoteCreateUpdate}
                  showLoader={loading}
                  showToolBar={showToolBar}
                  canEditNotes={canEditNotes}
                  noteType={noteType}
               />
            )}
         </Spinner>
      </Card>
   );
};
