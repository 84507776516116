import React, { useEffect, useState } from "react";
import {
   Card,
   Box,
   EmptyState,
   FlexList,
   H2,
   Button,
   Grid,
   Typography,
   useI18nContext,
   Spinner,
} from "@procore/core-react";
import { ColorSwab } from "../../../data-table/ColorSelectComponent/ColorSelectColumn";
import type {
   EmptyWageOverridesProps,
   ListWageOverridesCardProps,
   ProjectWageOverrideData,
   WageOverrideData,
} from "../types";
import styles from "./project-details.scss";
import classnames from "classnames";
import { Pencil, Trash } from "@procore/core-icons";
import { ActionMode } from "@/react/prop-types";
const cx = classnames.bind(styles);
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { AddWageOverrides } from "./project-details-add-wage-overrides";
import { useModal } from "@/react/shared/hooks/useModal";
import { useGetJobTitles } from "@/react/components/common/queries/queries";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useToastAlertContext } from "@procore/toast-alert";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { commonButtonStyle } from "@/react/shared/helper";
import type { DetailsCardProps } from "../../types";

const EmptyWageOverrides: React.FC<EmptyWageOverridesProps> = ({
   openAddWageOverridesModal,
   isAddWageOverridesOpen,
}) => {
   const I18n = useI18nContext();
   if (isAddWageOverridesOpen) {
      return null;
   }
   return (
      <>
         <Box padding="lg">
            <FlexList justifyContent="space-between">
               <H2>
                  {" "}
                  {I18n.t("views.company.workforce_planning.project_details_wage_override.title")}
               </H2>
            </FlexList>
         </Box>
         <Box padding="sm">
            <EmptyState compact>
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_wage_override.empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_wage_override.empty_state_description",
                  )}
               </EmptyState.Description>
               <EmptyState.Actions className={cx("empty-state-actions")}>
                  <Button variant="secondary" onClick={openAddWageOverridesModal}>
                     {I18n.t(
                        "views.company.workforce_planning.project_details_wage_override.add_wage",
                     )}
                  </Button>
               </EmptyState.Actions>
            </EmptyState>
         </Box>
      </>
   );
};

const ListWageOverridesCard: React.FC<ListWageOverridesCardProps> = ({
   wageOverrides,
   editOverrides,
   openAddWageOverridesModal,
   isAddWageOverridesOpen,
   selectedOverrideId,
   setSelectedOverrideId,
   jobTitles,
   onUpdateProject,
}) => {
   const { openModal, closeModal } = useModal();
   const [mode, setMode] = useState<ActionMode>(ActionMode.EDIT);

   const toggleSelectedOverride = (overrideId: string, mode: ActionMode) => {
      setSelectedOverrideId(selectedOverrideId === overrideId ? "" : overrideId);
      setMode(mode);
      openModal();
   };

   const resetView = () => {
      closeModal();
      setSelectedOverrideId("");
   };

   const getInitialValues = (wageOverrides: WageOverrideData) => {
      return {
         job_title: {
            id: wageOverrides.job_title_id,
            label: wageOverrides.position.name,
         },
         wage: wageOverrides.rate,
      };
   };

   if (isAddWageOverridesOpen) {
      return null;
   }

   return (
      <>
         <DetailsCardHeader
            titleKey="views.company.workforce_planning.project_details_wage_override.title"
            helpTextKey="views.company.workforce_planning.project_details_wage_override.help_text"
            buttonTextKey="views.company.workforce_planning.project_details_wage_override.add_wage"
            btnDisabled={selectedOverrideId !== ""}
            openAddItemModal={openAddWageOverridesModal}
            permission={editOverrides}
         />
         <Box className={cx("custom-box")}>
            <>
               {wageOverrides.map((item, index: number) => (
                  <React.Fragment key={item.id}>
                     <Box
                        padding="md"
                        style={{
                           borderBottom:
                              index !== wageOverrides.length - 1 ? "1px solid #D6DADC" : "none",
                        }}
                     >
                        {selectedOverrideId !== item.id && (
                           <Grid>
                              <Grid.Row>
                                 <Grid.Col
                                    colWidth={4}
                                    style={{ display: "flex", alignItems: "center" }}
                                 >
                                    <ColorSwab
                                       color={item.position.color}
                                       shape="circle"
                                       label={item.position.name}
                                       height="20px"
                                       width="20px"
                                    />{" "}
                                    <Typography
                                       intent="h3"
                                       className={cx("custom-line-height-font-size-margin")}
                                    >
                                       {item.position.name}
                                    </Typography>
                                 </Grid.Col>
                                 <Grid.Col colWidth={6}>
                                    <Typography
                                       intent="small"
                                       className={cx("custom-line-height-font-size-margin")}
                                       style={{ color: "#6A767C" }}
                                    >
                                       ${item.rate}/hr
                                    </Typography>
                                 </Grid.Col>
                                 {editOverrides && (
                                    <Grid.Col colWidth={2} className={cx("icons-at-end")}>
                                       <span>
                                          <Pencil
                                             size="sm"
                                             data-testid="edit-override"
                                             style={{
                                                ...commonButtonStyle({
                                                   selectedId: selectedOverrideId,
                                                   currentId: item.id,
                                                }),
                                                marginRight: "10px",
                                             }}
                                             onClick={() =>
                                                toggleSelectedOverride(item.id, ActionMode.EDIT)
                                             }
                                          />

                                          <Trash
                                             size="sm"
                                             data-testid="delete-override"
                                             style={commonButtonStyle({
                                                selectedId: selectedOverrideId,
                                                currentId: item.id,
                                             })}
                                             onClick={() =>
                                                toggleSelectedOverride(item.id, ActionMode.DELETE)
                                             }
                                          />
                                       </span>
                                    </Grid.Col>
                                 )}
                              </Grid.Row>
                           </Grid>
                        )}
                        {selectedOverrideId === item.id && (
                           <AddWageOverrides
                              open={!isAddWageOverridesOpen}
                              onClose={resetView}
                              handleCreate={onUpdateProject}
                              mode={mode}
                              initialValues={getInitialValues(item)}
                              availableJobTitles={jobTitles}
                              wageOverrides={wageOverrides}
                           />
                        )}
                     </Box>
                  </React.Fragment>
               ))}
            </>
         </Box>
         <Box padding="xxs" />
      </>
   );
};

export const ProjectDetailsWageOverridesCard: React.FC<DetailsCardProps> = ({
   detailsData,
   refreshTable,
   updateData,
   refetchData,
   loading: projectDataLoading,
}) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const { openModal, closeModal, isOpen } = useModal();
   const { data: jobTitles } = useGetJobTitles(groupId);
   const [canEditWageOverrides, setCanEditWageOverrides] = React.useState<boolean>(false);
   const [selectedOverrideId, setSelectedOverrideId] = useState<string>("");
   const handleWageOverridesCreation = async (payload: ProjectWageOverrideData) => {
      try {
         const updatedProject = await updateData(payload);
         if (updatedProject.data) {
            setSelectedOverrideId("");
            refetchData();
            if (refreshTable) {
               refreshTable();
            }
            closeModal();
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      }
   };
   useEffect(() => {
      const canEditWageOverrides = checkAuthAction(AuthAction.EDIT_PROJECT_WAGE_OVERRIDES);
      setCanEditWageOverrides(canEditWageOverrides);
   }, [checkAuthAction]);

   return (
      <>
         {(canEditWageOverrides ||
            (detailsData?.data?.wage_overrides && detailsData.data?.wage_overrides.length > 0)) && (
            <Card style={{ marginBottom: "5px" }}>
               <Spinner loading={projectDataLoading}>
                  {canEditWageOverrides &&
                     detailsData?.data?.wage_overrides &&
                     detailsData.data?.wage_overrides.length === 0 && (
                        <EmptyWageOverrides
                           openAddWageOverridesModal={openModal}
                           isAddWageOverridesOpen={isOpen}
                        />
                     )}
                  {detailsData?.data?.wage_overrides &&
                     detailsData.data?.wage_overrides.length > 0 && (
                        <ListWageOverridesCard
                           wageOverrides={detailsData?.data?.wage_overrides}
                           editOverrides={canEditWageOverrides}
                           openAddWageOverridesModal={openModal}
                           isAddWageOverridesOpen={isOpen}
                           selectedOverrideId={selectedOverrideId}
                           setSelectedOverrideId={setSelectedOverrideId}
                           jobTitles={jobTitles}
                           onUpdateProject={handleWageOverridesCreation}
                        />
                     )}
                  {canEditWageOverrides && detailsData && (
                     <AddWageOverrides
                        open={isOpen}
                        onClose={closeModal}
                        handleCreate={handleWageOverridesCreation}
                        availableJobTitles={jobTitles}
                        wageOverrides={detailsData?.data?.wage_overrides ?? []}
                     />
                  )}
               </Spinner>
            </Card>
         )}
      </>
   );
};
