import React, { useCallback, useImperativeHandle } from "react";
import { useProjectTearsheet } from "../tearsheets/project/project-tearsheet";
import { useAssignmentTearsheet } from "../tearsheets/assignment/assignment-tearsheet";
import { useRequestTearsheet } from "../tearsheets/request/request-tearsheet";
import { usePersonTearsheet } from "../tearsheets/people/people-tearsheet";

interface TearsheetInput {
   projectId: string;
   personId?: string;
   assignmentId?: string;
   requestId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
}

/**
 * Represents a reference to a Gantt project tearsheet.
 */
export type GanttTearsheetRef = {
   /**
    * Opens the tearsheet for the specified project.
    *
    * @param projectId - The ID of the project to open the tearsheet for.
    * @param personId - The ID of the person to open the tearsheet for.
    * @param assignmentId - The ID of the assignment to open the tearsheet for.
    * @param requestId - The ID of the request to open the tearsheet for.
    * @param categoryId - The ID of the category to open the tearsheet for.
    * @param subcategoryId - The ID of the subcategory to open the tearsheet for.
    * @param callback - The callback to call when the tearsheet is closed.
    */
   handleOpenTearsheet: ({
      assignmentId,
      requestId,
      projectId,
      categoryId,
      subcategoryId,
      callback,
   }: TearsheetInput) => void;
};

export enum GanttTearsheetType {
   ProjectDetail = "project-detail",
   PersonDetail = "person-detail",
   CreateAssignment = "create-assignment",
   CreateRequest = "create-request",
   EditAssignment = "edit-assignment",
   EditRequest = "edit-request",
   SendAssignmentAlerts = "send-assignment-alerts",
}

/**
 * This component is a wrapper around the ProjectTearsheetProvider which allows us
 * render the tearsheet without re-rendering the entire GanttContainer. We can use ProjectTearsheetProvider
 * to render the tearsheet for both the project details tearsheet and the create assignment tearsheet.
 */
const Tearsheet = (tearsheetType: GanttTearsheetType) => {
   return React.forwardRef<GanttTearsheetRef>(function GanttTearsheet(_, ref) {
      // Call all hooks at the top level
      const projectTearsheet = useProjectTearsheet();
      const assignmentTearsheet = useAssignmentTearsheet();
      const requestTearsheet = useRequestTearsheet();
      const personTearsheet = usePersonTearsheet();

      const getTearsheetDispatch = () => {
         switch (tearsheetType) {
            case GanttTearsheetType.ProjectDetail:
               return projectTearsheet.dispatch;
            case GanttTearsheetType.CreateAssignment:
            case GanttTearsheetType.EditAssignment:
            case GanttTearsheetType.SendAssignmentAlerts:
               return assignmentTearsheet.dispatch;
            case GanttTearsheetType.CreateRequest:
            case GanttTearsheetType.EditRequest:
               return requestTearsheet.dispatch;
            case GanttTearsheetType.PersonDetail:
               return personTearsheet.dispatch;
            default:
               console.error(`Tearsheet type ${tearsheetType} not found`);
               return null;
         }
      };

      const tearsheetDispatch = getTearsheetDispatch();

      const handleOpenTearsheet = useCallback(
         ({
            assignmentId,
            requestId,
            projectId,
            categoryId,
            subcategoryId,
            personId,
            callback,
         }: TearsheetInput) => {
            if (!tearsheetDispatch) return;

            tearsheetDispatch({
               type: `open-${tearsheetType}`,
               assignmentId,
               requestId,
               projectId,
               categoryId,
               subcategoryId,
               personId,
               callback,
            } as any);
         },
         [tearsheetDispatch],
      );

      useImperativeHandle(ref, () => ({
         handleOpenTearsheet,
      }));
      return null;
   });
};

export const GanttProjectDetailsTearsheet = Tearsheet(GanttTearsheetType.ProjectDetail);

export const GanttPersonDetailsTearsheet = Tearsheet(GanttTearsheetType.PersonDetail);

export const GanttCreateAssignmentTearsheet = Tearsheet(GanttTearsheetType.CreateAssignment);

export const GanttSendAssignmentAlertsTearsheet = Tearsheet(
   GanttTearsheetType.SendAssignmentAlerts,
);

export const GanttCreateRequestTearsheet = Tearsheet(GanttTearsheetType.CreateRequest);

export const GanttEditAssignmentTearsheet = Tearsheet(GanttTearsheetType.EditAssignment);

export const GanttEditRequestTearsheet = Tearsheet(GanttTearsheetType.EditRequest);
