import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { Plus } from "@procore/core-icons";
import { useI18nContext, FlexList, Button } from "@procore/core-react";
import React from "react";
import type { ExportFormValues } from "../data-table/DataExport/export-modal";
import { ExportModal } from "../data-table/DataExport/export-modal";
import { useExportToastContext } from "../data-table/DataExport/FileDownload";
import { usePersonTearsheet, onCreatePersonClick } from "../tearsheets/people/people-tearsheet";
import { createPeopleListReport } from "./queries";
import type { CustomField } from "@/react/prop-types";

type PeopleListQuickControlsProps = {
   groupId: string;
   customFields?: CustomField[] | null;
   isLastNameFirst: boolean;
   configKey: string;
   search: string | null;
};

export const PeopleListQuickControls: React.FC<PeopleListQuickControlsProps> = ({
   configKey,
   groupId,
   customFields,
   isLastNameFirst,
   search,
}) => {
   const { checkAuthAction } = usePermissionContext();
   const I18n = useI18nContext();
   const [canCreatePerson, setCanCreatePerson] = React.useState(false);
   const { dispatch: personTearsheetDispatch } = usePersonTearsheet();
   const { setVisible: showFileDownloadToast, addItems: addDownloadableReport } =
      useExportToastContext();

   React.useEffect(() => {
      setCanCreatePerson(checkAuthAction(AuthAction.CREATE_PEOPLE));
   }, [checkAuthAction]);

   const onPeopleListExport = React.useCallback(
      async (params: ExportFormValues) => {
         const report = await createPeopleListReport({
            formValues: params,
            groupId,
            configKey,
            I18n,
            customFields,
            isLastNameFirst,
            search,
         });
         showFileDownloadToast(true);
         addDownloadableReport([report.data]);
      },
      [search, customFields],
   );

   return (
      <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
         {canCreatePerson && (
            <Button onClick={() => onCreatePersonClick(personTearsheetDispatch)} icon={<Plus />}>
               {I18n.t("views.company.workforce_planning.create")}
            </Button>
         )}
         <ExportModal onExport={onPeopleListExport} />
      </FlexList>
   );
};
