import type { JobTitle } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   useI18nContext,
   Box,
   Button,
   Required,
   Flex,
   FlexList,
   Form,
   Title,
   H2,
} from "@procore/core-react";
import type { ChangeEvent } from "react";
import React, { useEffect, useMemo, useState } from "react";
import type {
   AddWageOverridesModalProps,
   ProjectWageOverrideData,
   WageOverrideFormValues,
} from "../types";
import styles from "./project-details.scss";

import classnames from "classnames";
import { prepareOverridePayload } from "../helpers";
import { wageOverridesSchema } from "../validation-schemas";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";

const cx = classnames.bind(styles);

export const AddWageOverrides = ({
   open,
   onClose,
   handleCreate,
   mode = ActionMode.CREATE,
   initialValues,
   availableJobTitles,
   wageOverrides,
}: AddWageOverridesModalProps) => {
   const I18n = useI18nContext();
   const [filteredJobTitles, setFilteredJobTitles] = useState<JobTitle[]>([]);
   const validationSchema = wageOverridesSchema(I18n);

   const filteredJobTitlesMemo = useMemo(() => {
      if (availableJobTitles && wageOverrides) {
         return availableJobTitles.filter(
            (jobTitle) => !wageOverrides.some((override) => override.job_title_id === jobTitle.id),
         );
      }
      return [];
   }, [availableJobTitles, wageOverrides]);

   useEffect(() => {
      setFilteredJobTitles(filteredJobTitlesMemo);
   }, [filteredJobTitlesMemo]);

   const handleClose = () => {
      onClose();
      setFilteredJobTitles(availableJobTitles || []);
   };
   /* istanbul ignore next */
   const FilterJobTitles = (searchTerm: string) => {
      if (!availableJobTitles.length) return;
      if (!searchTerm) {
         setFilteredJobTitles(filteredJobTitlesMemo);
         return;
      }
      const filteredTitles = filteredJobTitles.filter((job_title) =>
         job_title.label.toLowerCase().startsWith(searchTerm.toLowerCase()),
      );
      setFilteredJobTitles(filteredTitles);
   };

   const handleWageOverrideCreation = async (jobTitleId: string, wage: number | null) => {
      const payload: ProjectWageOverrideData = prepareOverridePayload(jobTitleId, wage);
      await handleCreate(payload);
   };

   const handleSubmit = (values: WageOverrideFormValues) => {
      const {
         job_title: { id },
         wage,
      } = values;
      const wageValue = mode === ActionMode.DELETE ? null : wage;
      handleWageOverrideCreation(id, wageValue);
   };

   if (!open) {
      return null;
   }

   return (
      <>
         {mode === ActionMode.CREATE && (
            <DetailsCardHeader
               titleKey="views.company.workforce_planning.project_details_wage_override.title"
               helpTextKey="views.company.workforce_planning.project_details_wage_override.help_text"
               buttonTextKey="views.company.workforce_planning.project_details_wage_override.add_wage"
               btnDisabled={true}
            />
         )}
         <Box padding="sm" hidden={mode !== ActionMode.DELETE}>
            <FlexList justifyContent="space-between">
               <Title>
                  <Title.Text>
                     <H2>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_wage_override.delete_wage_title",
                        )}
                     </H2>
                  </Title.Text>
                  <Title.Subtext>
                     {I18n.t(
                        "views.company.workforce_planning.project_details_wage_override.delete_wage_description",
                     )}
                  </Title.Subtext>
               </Title>
            </FlexList>
         </Box>
         <Box padding="sm" className={mode === ActionMode.CREATE ? cx("custom-box") : ""}>
            <Form
               view="create"
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
               initialValues={initialValues}
            >
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({ submitForm, isSubmitting }: FormikProps<WageOverrideFormValues>) => (
                  <>
                     <Box padding={mode === ActionMode.CREATE ? "md" : ""}>
                        <Form.Form>
                           <Form.Row>
                              <Form.Select
                                 colStart={1}
                                 colWidth={6}
                                 name="job_title"
                                 label={I18n.t(
                                    "views.company.workforce_planning.project_details_wage_override.job_title_label",
                                 )}
                                 options={filteredJobTitles}
                                 onSearch={({
                                    target: { value },
                                 }: ChangeEvent<HTMLInputElement>) => {
                                    FilterJobTitles(value);
                                 }}
                                 placeholder={I18n.t(
                                    "views.company.workforce_planning.project_details_wage_override.job_title_placeholder",
                                 )}
                                 required
                                 disabled={mode === ActionMode.EDIT || mode === ActionMode.DELETE}
                              />

                              <Form.Currency
                                 colStart={7}
                                 colWidth={6}
                                 name="wage"
                                 label={I18n.t(
                                    "views.company.workforce_planning.project_details_wage_override.wage_label",
                                 )}
                                 placeholder={I18n.t(
                                    "views.company.workforce_planning.project_details_wage_override.wage_placeholder",
                                 )}
                                 required
                                 tooltip={false}
                                 disabled={mode === ActionMode.DELETE}
                              />
                           </Form.Row>
                        </Form.Form>
                     </Box>
                     <Box padding={mode === ActionMode.CREATE ? "md" : ""}>
                        <Flex justifyContent="space-between" alignItems="center">
                           <Required showLabel />
                           <FlexList space="sm">
                              <Button
                                 variant="secondary"
                                 disabled={isSubmitting}
                                 onClick={handleClose}
                              >
                                 {I18n.t("views.company.workforce_planning.cancel")}
                              </Button>
                              <Button type="submit" disabled={isSubmitting} onClick={submitForm}>
                                 {I18n.t(
                                    mode === ActionMode.DELETE
                                       ? "views.company.workforce_planning.delete"
                                       : "views.company.workforce_planning.save",
                                 )}
                              </Button>
                           </FlexList>
                        </Flex>
                     </Box>
                  </>
               )}
            </Form>
         </Box>
         {mode === ActionMode.CREATE && <Box padding="xxs" />}
      </>
   );
};
