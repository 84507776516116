import type { SerializedFindTimeOff } from "@laborchart-modules/lc-core-api/dist/api/time-off/find-time-off";
import type { ExtendedGetDetailResponse, PageType } from "../types";

export type ListTimeOffProps = {
   timeOffData: SerializedFindTimeOff[] | null;
   showLoader: boolean;
   pageType?: PageType;
   refetchTimeOffData: () => void;
};

export type DetailsTimeOffCardProps = {
   timeOffData?: ExtendedGetDetailResponse | null;
   refetchData?: () => void;
   loading: boolean;
   contextId: string;
   pageType: PageType;
};

export type TimeOffCardProps = Array<{
   id: string;
   start_date: string | Date;
   end_date: string | Date;
   reason: { id: number; label: string; value: string };
   daily_start_time: string;
   daily_end_time: string;
   repeat: string;
   occurences: number;
   person_name: { first: string; last: string };
   cadence: number;
   repeat_end_day: number | Date | null;
   type: { id: number; label: string; value: string; color: string };
   apply_to_saturday: boolean;
   apply_to_sunday: boolean;
}>;

export enum RepeatType {
   WEEKLY = "weekly",
   MONTHLY = "monthly",
   YEARLY = "yearly",
}
