import { UNSAFE_FilterToken as FilterToken, useI18nContext } from "@procore/core-react";
import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";
import type { ganttFilterOptionTypes, ganttFilterType } from "./prop-types";
import { ganttFilterPillType } from "./prop-types";
import { INITIAL_GANTT_FILTER } from "./gantt-config";
import "./gantt-container.css";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { formatDateTime } from "@/react/shared/helper";
import { getQualifiersLabel } from "./custom_filters/gantt-custom-filter-helper";

const ganttFilterPills: Array<{
   name: ganttFilterOptionTypes;
   filterType: ganttFilterPillType;
   label: string;
}> = [
   { name: "jobTitles", filterType: ganttFilterPillType.MULTISELECT, label: "Job Titles" },
   { name: "projectStatuses", filterType: ganttFilterPillType.MULTISELECT, label: "Statuses" },
   { name: "assignmentsOrRequests", filterType: ganttFilterPillType.CHECKBOX, label: "Only show" },
   { name: "peopleOrEquipment", filterType: ganttFilterPillType.CHECKBOX, label: "Type" },
   {
      name: "hideEmptyProject",
      filterType: ganttFilterPillType.BOOLEAN,
      label: "Hide Empty Projects",
   },
   {
      name: "hideEmptyCategories",
      filterType: ganttFilterPillType.BOOLEAN,
      label: "Hide Empty Categories",
   },

   //  { name: "startDate", filterType: ganttFilterPillType.DATE, label: "Start Date" },
   //  { name: "endDate", filterType: ganttFilterPillType.DATE, label: "End Date" },
];

export const GanttControlPanelFilterPills = (props: {
   ganttFilter: ganttFilterType;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
   customFilterFields?: any;
}) => {
   const { ganttFilter, setGanttFilter, customFilterFields } = props;
   const I18n = useI18nContext();

   const [hasCustomFields, setHasCustomFields] = useState(false);

   useEffect(() => {
      if (customFilterFields?.projects_custom_field_filters) {
         setHasCustomFields(true);
      } else {
         setHasCustomFields(false);
      }
   }, [customFilterFields]);

   const onTokenRemoveClick = (name: ganttFilterOptionTypes) => {
      if (INITIAL_GANTT_FILTER[name] === undefined) {
         setGanttFilter((prevFilter: ganttFilterType) => {
            const newCustomFieldsFilters = { ...prevFilter.customFieldsFilters };
            delete newCustomFieldsFilters[name]; // Remove the entire key
            return {
               ...prevFilter,
               customFieldsFilters: newCustomFieldsFilters,
            };
         });
      } else {
         setGanttFilter((prevFilter: ganttFilterType) => ({
            ...prevFilter,
            [name]: INITIAL_GANTT_FILTER[name],
         }));
      }
   };

   return (
      <div className="filterPillsWrapper">
         {ganttFilterPills.map((pill) => {
            const currentFilter: any = ganttFilter[pill.name];
            const isActive = currentFilter.length || currentFilter === true || currentFilter.date;
            let label;

            switch (pill.filterType) {
               case ganttFilterPillType.MULTISELECT:
                  if (currentFilter.length) label = `${pill.label} (${currentFilter.length})`;
                  break;
               case ganttFilterPillType.BOOLEAN:
                  if (currentFilter) label = pill.label;
                  break;
               case ganttFilterPillType.CHECKBOX:
                  if (currentFilter.length) label = `${pill.label}: ${currentFilter.join(", ")}`;
                  break;
               // case ganttFilterPillType.DATE:
               //    if (currentFilter.qualifier && currentFilter.date)
               //       label = `${pill.label} ${getDateQualifiersName(
               //          currentFilter.qualifier,
               //       )} ${dateFormat(new Date(currentFilter.date))}`;
               //    break;
            }

            return (
               isActive && (
                  <FilterToken key={pill.name} style={{ padding: "0 12px" }}>
                     <FilterToken.Label style={{ fontWeight: "600" }}>{label}</FilterToken.Label>
                     <FilterToken.Remove
                        onClick={() => onTokenRemoveClick(pill.name)}
                        style={{ marginLeft: "6px", padding: "0 4px" }}
                     />
                  </FilterToken>
               )
            );
         })}

         {hasCustomFields &&
            ganttFilter.customFieldsFilters &&
            customFilterFields.projects_custom_field_filters.map((customField: any) => {
               const filterField = ganttFilter.customFieldsFilters[customField.id];
               if (!filterField) return null;
               let label = customField.name;
               switch (customField.type) {
                  case CustomFieldType.TEXT:
                     label = `${customField.name}: ${filterField[0].value}`;
                     break;
                  case CustomFieldType.BOOL:
                     {
                        label = `${customField.name}: `;
                        if (filterField.length === 2) {
                           label += `${I18n.t(
                              "views.company.workforce_planning.boolean_values.yes_value",
                           )} & ${I18n.t(
                              "views.company.workforce_planning.boolean_values.no_value",
                           )}`;
                        } else if (filterField.length === 1) {
                           label += filterField[0].value
                              ? I18n.t("views.company.workforce_planning.boolean_values.yes_value")
                              : I18n.t("views.company.workforce_planning.boolean_values.no_value");
                        }
                     }
                     break;
                  case CustomFieldType.NUMBER:
                  case CustomFieldType.CURRENCY:
                     label = `${customField.name} ${filterField[0].classifier} ${filterField[0].value}`;
                     break;
                  case CustomFieldType.DATE:
                     label = `${customField.name} ${I18n.t(
                        getQualifiersLabel(filterField[0].classifier, customField.type) ?? "",
                     )} ${formatDateTime(getAttachedDate(filterField[0].value), I18n.locale)}`;
                     break;
                  case CustomFieldType.MULTI_SELECT:
                  case CustomFieldType.SELECT:
                     label = `${customField.name}: ${filterField
                        .map((field: any) => field.value)
                        .join(" or ")}`;
                     break;
               }

               return (
                  <FilterToken key={customField.name} style={{ padding: "0 12px" }}>
                     <FilterToken.Label style={{ fontWeight: "600" }}>{label}</FilterToken.Label>
                     <FilterToken.Remove
                        onClick={() => onTokenRemoveClick(customField.id)}
                        style={{ marginLeft: "6px", padding: "0 4px" }}
                     />
                  </FilterToken>
               );
            })}
      </div>
   );
};
