import type { TagOption } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import {
   useI18nContext,
   Box,
   Grid,
   H3,
   Spinner,
   Button,
   Menu,
   Card,
   Required,
   Typography,
   DateSelect,
} from "@procore/core-react";
import React, { useEffect, useState } from "react";
import type { AddTagsModalProps, TagData } from "./types";
import { FileUpload } from "@/react/shared/components/file-upload";
import styles from "../project/project-detail-cards/project-details.scss";
import classnames from "classnames";
import { groupByCategories, prepareTagPayload } from "../helper";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { PageType } from "../types";

const cx = classnames.bind(styles);

export const AddTags = ({
   open,
   onClose,
   handleCreate,
   availableTags,
   showLoader,
   pageType,
   cardHeaderKeys,
}: AddTagsModalProps) => {
   const I18n = useI18nContext();
   const [attachments, setAttachments] = useState<any[]>([]);
   const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<string[]>([]);
   const [groupedItems, setGroupedItems] = useState<{ [key: string]: any }>({});
   const [filteredTagOptions, setFilteredTagOptions] = useState<TagOption[]>(availableTags || []);
   const [selectedTagId, setSelectedTagId] = useState("");
   const [loading, setLoading] = useState(false);
   const [tagsLoading, setTagsLoading] = useState(true);
   const [expirationDate, setExpirationDate] = useState<Date | null>(null);
   const [expireDateDisplay, setExpireDateDisplay] = useState(false);

   const handleClose = () => {
      onClose();
      setAttachments([]);
      setDeletedAttachmentIds([]);
      setSelectedTagId("");
      setFilteredTagOptions(availableTags || []);
      setExpireDateDisplay(false);
   };

   useEffect(() => {
      if (availableTags.length) {
         setTagsLoading(false);
         setFilteredTagOptions(availableTags);
      }
   }, [availableTags]);

   const FilterTags = (searchTerm: string) => {
      const filteredTags = availableTags.filter((tag) =>
         tag.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
      );
      setFilteredTagOptions(filteredTags);
   };

   const handleTagCreation = async () => {
      const payload: TagData = prepareTagPayload(
         attachments,
         deletedAttachmentIds,
         selectedTagId,
         expirationDate,
      );
      await handleCreate(payload, ActionMode.SAVE);
      handleClose();
   };

   useEffect(() => {
      setGroupedItems(groupByCategories(filteredTagOptions));
   }, [filteredTagOptions]);

   const sortByCategory = ([a]: [string, any], [b]: [string, any]) => {
      if (a === "All Tags") return 1;
      if (b === "All Tags") return -1;
      return 0;
   };

   const sortedEntries = Object.entries(groupedItems).sort(sortByCategory);

   const handleExpireDateDisplay = (item: any) => {
      setSelectedTagId(item);
      setExpireDateDisplay(false);
      if (pageType === PageType.PERSON) {
         const selectedTag = availableTags.find((tag) => tag.id === item);

         if (selectedTag?.require_expr_date) {
            setExpireDateDisplay(true);
         }
      }
   };

   if (!open) {
      return null;
   }

   return (
      <>
         <DetailsCardHeader
            titleKey={cardHeaderKeys.titleKey}
            helpTextKey={cardHeaderKeys.helpTextKey}
            buttonTextKey={cardHeaderKeys.buttonTextKey}
            btnDisabled={true}
         />
         <Box padding="sm" className={cx("custom-box")}>
            <Grid>
               <Grid.Row>
                  <Grid.Col colWidth={6} style={{ display: "flex" }}>
                     <H3>
                        {I18n.t("views.company.workforce_planning.details_tags.tag_title")}{" "}
                        <Required style={{ marginLeft: "5px" }} />
                     </H3>
                  </Grid.Col>
                  <Grid.Col colWidth={6}>
                     <H3>
                        {I18n.t(
                           "views.company.workforce_planning.details_tags.tags_attachments_label",
                        )}
                     </H3>
                  </Grid.Col>
               </Grid.Row>

               <Grid.Row style={{ marginTop: "5px" }}>
                  <Grid.Col colWidth={6} style={{ display: "flex" }}>
                     <Box>
                        {tagsLoading && filteredTagOptions.length == 0 ? (
                           <Typography>
                              {I18n.t("views.company.workforce_planning.details_tags.no_tags")}
                           </Typography>
                        ) : (
                           <Card shadowStrength={2}>
                              <Menu
                                 scrollable
                                 style={{ maxHeight: "300px" }}
                                 onSelect={({ item }) => handleExpireDateDisplay(item)}
                                 onSearch={(e) => FilterTags(e.target.value || "")}
                              >
                                 <Menu.Search
                                    placeholder={I18n.t(
                                       "views.company.workforce_planning.details_tags.tag_search_placeholder",
                                    )}
                                 />
                                 <Menu.Header>
                                    <Box padding="lg">
                                       <Typography
                                          color="gray70"
                                          italic
                                          style={{ display: "flex", justifyContent: "center" }}
                                       >
                                          {I18n.t(
                                             "views.company.workforce_planning.details_tags.tag_menu_header",
                                          )}
                                       </Typography>
                                    </Box>
                                 </Menu.Header>
                                 <Menu.Options>
                                    {sortedEntries.length > 0 ? (
                                       sortedEntries.map(([category, items]) => (
                                          <React.Fragment key={category}>
                                             <Menu.Group>{category}</Menu.Group>
                                             {items.map((item: any) => (
                                                <Menu.Item
                                                   selected={selectedTagId === item.id}
                                                   key={item.id}
                                                   item={item.id}
                                                   style={{ backgroundColor: "white" }}
                                                >
                                                   {item.name}
                                                </Menu.Item>
                                             ))}
                                          </React.Fragment>
                                       ))
                                    ) : (
                                       <Menu.Item style={{ pointerEvents: "none" }}>
                                          {I18n.t(
                                             "views.company.workforce_planning.details_tags.no_tags",
                                          )}
                                       </Menu.Item>
                                    )}
                                 </Menu.Options>
                              </Menu>
                           </Card>
                        )}
                     </Box>
                  </Grid.Col>
                  <Grid.Col colWidth={6}>
                     <Box>
                        <FileUpload
                           attachments={attachments}
                           setAttachments={setAttachments}
                           deletedAttachmentIds={deletedAttachmentIds}
                           setDeletedAttachmentIds={setDeletedAttachmentIds}
                           loading={loading}
                           setLoading={setLoading}
                           disabled={filteredTagOptions.length === 0}
                        />
                     </Box>
                  </Grid.Col>
               </Grid.Row>
               {expireDateDisplay && (
                  <>
                     <Grid.Row style={{ display: "flex", marginTop: "10px" }}>
                        <Grid.Col colWidth={6}>
                           <H3>
                              {I18n.t(
                                 "views.company.workforce_planning.details_tags.tags_expiration_date_label",
                              )}
                           </H3>
                        </Grid.Col>
                     </Grid.Row>
                     <Grid.Row>
                        <Grid.Col colWidth={6}>
                           <DateSelect
                              onChange={(date) => {
                                 setExpirationDate(date);
                              }}
                           />
                        </Grid.Col>
                     </Grid.Row>
                  </>
               )}
            </Grid>
            <Box
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               style={{ width: "100%" }}
               padding="sm"
            >
               <Required showLabel />
               <Box>
                  <Button
                     variant="secondary"
                     onClick={handleClose}
                     style={{ marginRight: "8px" }}
                     disabled={loading}
                  >
                     {I18n.t("views.company.workforce_planning.cancel")}
                  </Button>

                  <Button
                     variant="primary"
                     onClick={handleTagCreation}
                     disabled={!selectedTagId || loading}
                     style={{ width: !showLoader ? "auto" : "70px" }}
                  >
                     {!showLoader ? (
                        I18n.t("views.company.workforce_planning.save")
                     ) : (
                        <Spinner loading={true} size="md" color="white" />
                     )}
                  </Button>
               </Box>
            </Box>
         </Box>
         <Box padding="xxs" />
      </>
   );
};
