import { Box, Flex, Switch, Typography } from "@procore/core-react";
import React from "react";
import { AssignmentFields } from "./types";
import { ActionMode } from "@/react/prop-types";
import { Check, Ban } from "@procore/core-icons";

export const AssignmentSwitch = React.memo(function AssignmentSwitch(props: any) {
   const { name } = props.field.input;
   const { view } = props.field.meta;
   const { toggleAssignmentByAllocation, toggleAssignmentByTime, setValues } = props;

   const handleChange = (value: boolean) => {
      const isAllocation = name === AssignmentFields.ALLOCATION;
      const isTime = name === AssignmentFields.TIME;
      toggleAssignmentByAllocation(value);
      toggleAssignmentByTime(value);
      if (value) {
         if (isAllocation) {
            toggleAssignmentByTime(false);
            setValues(AssignmentFields.TIME, false);
            setValues(AssignmentFields.ALLOCATION, true);
         } else if (isTime) {
            toggleAssignmentByAllocation(false);
            setValues(AssignmentFields.TIME, true);
            setValues(AssignmentFields.ALLOCATION, false);
         }
      }
      if (!value) {
         if (isAllocation) {
            toggleAssignmentByTime(true);
            setValues(AssignmentFields.TIME, true);
            setValues(AssignmentFields.ALLOCATION, false);
         } else if (isTime) {
            toggleAssignmentByAllocation(true);
            setValues(AssignmentFields.TIME, false);
            setValues(AssignmentFields.ALLOCATION, true);
         }
      }
   };
   return (
      <Flex>
         {view === ActionMode.READ ? (
            <Box>
               {props.value ? (
                  <Check size="sm" style={{ marginTop: "2px" }} />
               ) : (
                  <Ban size="sm" style={{ marginTop: "2px" }} />
               )}
            </Box>
         ) : (
            <Switch
               checked={props.value}
               onChange={() => {
                  handleChange(!props.value);
               }}
               data-testid="assignment-switch"
            />
         )}
         <Typography style={{ paddingLeft: "10px" }}>{props.inlineLabel}</Typography>
      </Flex>
   );
});
