import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   H1,
   H2,
   Page,
   Required,
   useI18nContext,
} from "@procore/core-react";
import React from "react";
import {
   DynamicTokenEditor,
   type DynamicTokenEditorRef,
} from "../../communications/dynamic-tokens/dynamic-token-editor";
import { useToastAlertContext } from "@procore/toast-alert";
import { Form } from "@procore/core-react";
import type { CannedMessage } from "@laborchart-modules/common";
import {
   getRoleTokensForMessages,
   reconstructHtmlWithTokens,
   validateAlertContent,
} from "../../communications/dynamic-tokens/token-utils";
import { ProjectDetailAlertTokens } from "../../communications/constants";
import { customAssignmentAlertsSchema } from "./validation-schemas";
import {
   getCustomAlertsInitialValues,
   getCustomAssignmentsTearsheetHeader,
   prepareCannedMessagePayload,
} from "./helpers";
import type { CannedMessageType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { MessageStore } from "@/stores/message-store.core";
import { TearsheetTabs } from "@/react/prop-types";

type CustomizeProjectAlertsTearsheetContentProps = {
   projectId: string;
   onShowProjectDetail: (projectId: string, showActiveTab?: string) => void;
   alertType: CannedMessageType;
   projectRoles: any[];
   cannedMessage?: CannedMessage;
};
export const CustomizeProjectAlertsTearsheetContent = (
   props: CustomizeProjectAlertsTearsheetContentProps,
) => {
   const I18n = useI18nContext();
   const { projectId, onShowProjectDetail, cannedMessage, alertType, projectRoles } = props;
   const [alertContent, setAlertContent] = React.useState({ subject: "", content: "" });
   const [inititalConent, setInitialAlertContent] = React.useState({ subject: "", content: "" });
   const [errorFields, setErrorFields] = React.useState<string[]>([]);
   const editorRef = React.useRef<DynamicTokenEditorRef>(null);
   const { showToast } = useToastAlertContext();

   React.useEffect(() => {
      if (cannedMessage) {
         const initialSubject = reconstructHtmlWithTokens(
            cannedMessage.dynamic_tokens,
            cannedMessage.subject ?? "",
         );
         const initialContent = reconstructHtmlWithTokens(
            cannedMessage.dynamic_tokens,
            cannedMessage.content ?? "",
         );
         setInitialAlertContent({ subject: initialSubject, content: initialContent });
         setAlertContent({ subject: initialSubject, content: initialContent });
      }
   }, [projectId, cannedMessage]);

   const handleContentChange = React.useCallback(
      (content: { subject: string; content: string }) => {
         setAlertContent(content);
      },
      [],
   );

   const handleSubmit = React.useCallback(
      async (values: any) => {
         const newErrorFields = validateAlertContent(alertContent);

         if (newErrorFields.length > 0) {
            setErrorFields(newErrorFields);
            return;
         }
         try {
            const payload = prepareCannedMessagePayload(alertContent, values, projectId, alertType);
            if (cannedMessage?.id) {
               await MessageStore.updateCannedMessage(cannedMessage.id, payload).payload;
            } else {
               await MessageStore.createCannedMessage(payload).payload;
            }
            showToast.success(
               I18n.t(
                  "views.company.workforce_planning.communications.alert_toasts.customized_alert_success",
               ),
            );
            onShowProjectDetail(projectId, TearsheetTabs.DETAILS);
         } catch (err) {
            showToast.error(
               I18n.t(
                  "views.company.workforce_planning.communications.alert_toasts.customized_alert_fail",
               ),
            );
         }
      },
      [alertContent, cannedMessage],
   );

   const handleCancel = React.useCallback(
      () => onShowProjectDetail(projectId, TearsheetTabs.DETAILS),
      [onShowProjectDetail, projectId],
   );

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Page.Header>
               <H1>{getCustomAssignmentsTearsheetHeader(I18n, alertType)}</H1>
            </Page.Header>
            <Form
               view="create"
               onSubmit={handleSubmit}
               validationSchema={customAssignmentAlertsSchema(I18n)}
               initialValues={getCustomAlertsInitialValues(cannedMessage as CannedMessage, I18n)}
            >
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({ isSubmitting, submitForm }: FormikProps<any>) => (
                  <>
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <Form.Form>
                           <Box padding="md">
                              <Card>
                                 <Box padding="md">
                                    <H2>
                                       {I18n.t(
                                          "views.company.workforce_planning.communications.recipients",
                                       )}
                                    </H2>
                                    <Form.Row style={{ paddingTop: "8px" }}>
                                       <Form.RadioButtons
                                          name="send_as"
                                          label={I18n.t(
                                             "views.company.workforce_planning.communications.send_as",
                                          )}
                                          required
                                          options={[
                                             {
                                                id: "individual",
                                                value: "individual",
                                                label: I18n.t(
                                                   "views.company.workforce_planning.communications.individual_messages",
                                                ),
                                             },
                                             {
                                                id: "group",
                                                value: "group",
                                                label: I18n.t(
                                                   "views.company.workforce_planning.communications.group_messages",
                                                ),
                                             },
                                          ]}
                                       />
                                    </Form.Row>
                                 </Box>
                              </Card>

                              <DynamicTokenEditor
                                 ref={editorRef}
                                 tokens={ProjectDetailAlertTokens.concat(
                                    getRoleTokensForMessages(projectRoles),
                                 )}
                                 I18n={I18n}
                                 onContentChange={handleContentChange}
                                 initialContent={inititalConent}
                                 errorFields={errorFields}
                              />
                           </Box>
                        </Form.Form>
                     </Page.Body>
                     <Page.Footer>
                        <Box padding="md">
                           <Flex justifyContent="space-between" alignItems="center">
                              <Required showLabel />
                              <FlexList space="sm">
                                 <Button
                                    variant="tertiary"
                                    disabled={isSubmitting}
                                    onClick={handleCancel}
                                 >
                                    {I18n.t("views.company.workforce_planning.cancel")}
                                 </Button>
                                 <Button type="submit" disabled={isSubmitting} onClick={submitForm}>
                                    {I18n.t("views.company.workforce_planning.save")}
                                 </Button>
                              </FlexList>
                           </Flex>
                        </Box>
                     </Page.Footer>
                  </>
               )}
            </Form>
         </Page.Main>
      </Page>
   );
};
