import React, { useEffect, useState } from "react";
import {
   Card,
   Box,
   EmptyState,
   FlexList,
   H2,
   Button,
   Title,
   H3,
   Grid,
   Typography,
   useI18nContext,
   Flex,
   Spinner,
   DateSelect,
} from "@procore/core-react";
import { Pencil, Trash } from "@procore/core-icons";
import { ColorSwab } from "../../data-table/ColorSelectComponent/ColorSelectColumn";
import type { TagInstance, TagOption } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import { DateUtils } from "@/lib/utils/date";
import { FileDownloadModal } from "@/react/shared/modals/file-download-modal";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { useToastAlertContext } from "@procore/toast-alert";
import { AddTags } from "../tags/details-add-tags";
import { useGetTagOptionsQuery } from "../../common/queries/queries";
import { useModal } from "@/react/shared/hooks/useModal";
import { FileUpload } from "@/react/shared/components/file-upload";

import styles from "../project/project-detail-cards/project-details.scss";

import classnames from "classnames";
import { getDateString } from "../project/helpers";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { commonButtonStyle, formatDateTime } from "@/react/shared/helper";
import type { DetailsCardProps } from "../types";
import { PageType } from "../types";
import type { EmptyTagsProps, ListTagsCardProps, TagData } from "./types";
import { prepareTagPayload } from "../helper";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";

const cx = classnames.bind(styles);

const EmptyTags: React.FC<EmptyTagsProps> = ({ openAddTagsModal, isAddTagsOpen, pageType }) => {
   const I18n = useI18nContext();
   if (isAddTagsOpen) {
      return null;
   }
   return (
      <>
         <Box padding="md">
            <FlexList justifyContent="space-between">
               <H2>{I18n.t("views.company.workforce_planning.details_tags.title")}</H2>
            </FlexList>
         </Box>
         <Box>
            <EmptyState compact>
               <EmptyState.Title>
                  {I18n.t("views.company.workforce_planning.details_tags.tags_empty_state_title")}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {pageType === PageType.PROJECT
                     ? I18n.t(
                          "views.company.workforce_planning.project_details_tags.tags_empty_state_description",
                       )
                     : I18n.t(
                          "views.company.workforce_planning.people_details_tags.tags_empty_state_description",
                       )}
               </EmptyState.Description>
               <EmptyState.Actions className={cx("empty-state-actions")}>
                  <Button variant="secondary" onClick={openAddTagsModal}>
                     {I18n.t("views.company.workforce_planning.details_tags.add_tags")}
                  </Button>
               </EmptyState.Actions>
            </EmptyState>
         </Box>
      </>
   );
};

const ListTagsCard: React.FC<ListTagsCardProps> = ({
   tags,
   onUpdateProject,
   selectedTagId,
   setSelectedTagId,
   openAddTagsModal,
   isAddTagsOpen,
   canEditTags,
   showLoader,
   pageType,
   cardHeaderKeys,
}) => {
   const I18n = useI18nContext();
   const [selectedAttachment, setSelectedAttachment] = useState<any>(null);
   const [attachments, setAttachments] = useState<SerializedAttachment[]>([]);
   const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<string[]>([]);
   const [loading, setLoading] = useState(false);
   const { isOpen, openModal, closeModal } = useModal();
   const [mode, setMode] = useState<ActionMode>(ActionMode.CREATE);

   const [expirationDate, setExpirationDate] = useState<Date | null>(null);
   const [expireDateDisplay, setExpireDateDisplay] = useState(false);

   const filterAttachmentsByTagId = (data: TagInstance, tagIdToMatch: string) => {
      return data
         .filter((item) => item.tag_id === tagIdToMatch)
         .map((item) => item.attachments)
         .flat();
   };

   useEffect(() => {
      setAttachments(filterAttachmentsByTagId(tags, selectedTagId));
      const expirationDate = tags.find((tag) => tag.tag_id === selectedTagId)?.expr_date;
      setExpirationDate(expirationDate ? getAttachedDate(expirationDate) : null);
   }, [selectedTagId, tags]);

   /* istanbul ignore next */
   useEffect(() => {
      const updateProject = async () => {
         if (deletedAttachmentIds.length > 0) {
            const payload: TagData = prepareTagPayload(
               attachments,
               deletedAttachmentIds,
               selectedTagId,
               expirationDate,
            );
            await onUpdateProject(payload, ActionMode.DELETE);
         }
      };

      updateProject();
   }, [deletedAttachmentIds]);

   useEffect(() => {
      setAttachments([]);
   }, [tags]);

   const toggleSelectedTagId = (tagId: string, mode: ActionMode) => {
      setSelectedTagId(selectedTagId === tagId ? "" : tagId);
      setMode(mode);
      if (pageType === PageType.PERSON) {
         const selectedTag = tags.find((tag) => tag.tag_id === tagId);
         setExpireDateDisplay(false);

         if (selectedTag?.tag.require_expr_date) {
            setExpireDateDisplay(true);
         }
      }
   };

   const handleDownloadLinkClick = (attachment: SerializedAttachment) => {
      setSelectedAttachment(attachment);
      openModal();
   };

   const handleModalClose = () => {
      setSelectedAttachment(null);
      closeModal();
   };

   const updateTags = async () => {
      let payload: TagData = prepareTagPayload(
         attachments,
         deletedAttachmentIds,
         selectedTagId,
         expirationDate,
      );
      if (mode === ActionMode.DELETE) {
         payload = {
            tag_instances: {
               [selectedTagId]: null,
            },
         };
      }
      await onUpdateProject(payload, ActionMode.SAVE);
   };

   // Function to get the action text based on the mode and loading state
   const getActionText = (showLoader: boolean, mode: ActionMode) => {
      if (!showLoader) {
         return mode === ActionMode.EDIT
            ? I18n.t("views.company.workforce_planning.save")
            : I18n.t("views.company.workforce_planning.delete");
      }
      return <Spinner loading={true} size="md" color="white" />;
   };

   if (isAddTagsOpen) {
      return null;
   }

   return (
      <>
         <DetailsCardHeader
            titleKey={cardHeaderKeys.titleKey}
            helpTextKey={cardHeaderKeys.helpTextKey}
            buttonTextKey={cardHeaderKeys.buttonTextKey}
            openAddItemModal={openAddTagsModal}
            btnDisabled={selectedTagId !== ""}
            permission={canEditTags}
         />

         <Box className={cx("custom-box")}>
            <>
               {tags.map((item, index: number) => (
                  <React.Fragment key={item.id}>
                     <Box
                        padding="md"
                        style={{
                           borderBottom: index !== tags.length - 1 ? "1px solid #D6DADC" : "none",
                        }}
                     >
                        {selectedTagId !== item.tag_id && (
                           <FlexList justifyContent="space-between">
                              <Title>
                                 <Title.Text>
                                    <ColorSwab
                                       color={item.tag.color}
                                       shape="pill"
                                       label={item.tag.abbreviation}
                                    />
                                 </Title.Text>
                              </Title>
                              {canEditTags && (
                                 <span>
                                    <Pencil
                                       size="sm"
                                       style={{
                                          ...commonButtonStyle({
                                             selectedId: selectedTagId,
                                             currentId: item.id,
                                          }),
                                          marginRight: "10px",
                                       }}
                                       onClick={() =>
                                          toggleSelectedTagId(item.tag_id, ActionMode.EDIT)
                                       }
                                       data-testid="edit-tag"
                                    />

                                    <Trash
                                       size="sm"
                                       style={commonButtonStyle({
                                          selectedId: selectedTagId,
                                          currentId: item.id,
                                       })}
                                       onClick={() =>
                                          toggleSelectedTagId(item.tag_id, ActionMode.DELETE)
                                       }
                                       data-testid="delete-tag"
                                    />
                                 </span>
                              )}
                           </FlexList>
                        )}
                        {selectedTagId === item.tag_id ? (
                           <Grid>
                              {mode === ActionMode.EDIT ? (
                                 <>
                                    <Grid.Row>
                                       <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                          <H3>
                                             {I18n.t(
                                                "views.company.workforce_planning.details_tags.tag_title",
                                             )}
                                          </H3>
                                       </Grid.Col>

                                       <Grid.Col colWidth={6}>
                                          <H3>
                                             {I18n.t(
                                                "views.company.workforce_planning.details_tags.tags_attachments_label",
                                             )}
                                          </H3>
                                       </Grid.Col>
                                    </Grid.Row>

                                    <Grid.Row style={{ marginTop: "5px" }}>
                                       <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                          <Typography>{item.tag.name}</Typography>
                                       </Grid.Col>

                                       <Grid.Col colWidth={6}>
                                          <Box>
                                             <FileUpload
                                                attachments={attachments}
                                                setAttachments={setAttachments}
                                                deletedAttachmentIds={deletedAttachmentIds}
                                                setDeletedAttachmentIds={setDeletedAttachmentIds}
                                                loading={loading}
                                                setLoading={setLoading}
                                             />
                                          </Box>
                                       </Grid.Col>
                                    </Grid.Row>
                                    {expireDateDisplay && (
                                       <>
                                          {" "}
                                          <Grid.Row style={{ marginTop: "5px" }}>
                                             <Grid.Col colWidth={6}>
                                                <H3>
                                                   {I18n.t(
                                                      "views.company.workforce_planning.details_tags.tags_expiration_date_label",
                                                   )}
                                                </H3>
                                             </Grid.Col>
                                          </Grid.Row>
                                          <Grid.Row>
                                             <Grid.Col colWidth={6}>
                                                {item.tag.require_expr_date ? (
                                                   <DateSelect
                                                      onChange={(date) => {
                                                         setExpirationDate(date);
                                                      }}
                                                      value={expirationDate ?? undefined}
                                                   />
                                                ) : (
                                                   <Typography>
                                                      {item.expr_date
                                                         ? DateUtils.getShortNumericDate(
                                                              new Date(item.expr_date),
                                                           )
                                                         : "-"}
                                                   </Typography>
                                                )}
                                             </Grid.Col>
                                          </Grid.Row>
                                       </>
                                    )}
                                 </>
                              ) : (
                                 <>
                                    <FlexList justifyContent="space-between">
                                       <Title>
                                          <Title.Text>
                                             <H2>
                                                {I18n.t(
                                                   "views.company.workforce_planning.details_tags.delete_tag_title",
                                                )}
                                             </H2>
                                          </Title.Text>
                                          <Title.Subtext>
                                             {I18n.t(
                                                "views.company.workforce_planning.details_tags.delete_tag_description",
                                                { page_type: PageType.PERSON },
                                             )}
                                          </Title.Subtext>
                                       </Title>
                                    </FlexList>
                                    <Box padding="lg" />
                                 </>
                              )}

                              {/* Common Button Grid for EDIT and DELETE */}
                              <Grid.Row>
                                 <Grid.Col colWidth={12}>
                                    <Flex justifyContent="flex-end" alignItems="center">
                                       <FlexList space="sm">
                                          <Button
                                             variant="secondary"
                                             onClick={() => {
                                                setSelectedTagId("");
                                                setAttachments([]);
                                                setDeletedAttachmentIds([]);
                                             }}
                                             disabled={loading}
                                          >
                                             {I18n.t("views.company.workforce_planning.cancel")}
                                          </Button>
                                          <Button
                                             type="submit"
                                             onClick={updateTags}
                                             disabled={loading}
                                             style={{ width: !showLoader ? "auto" : "70px" }}
                                          >
                                             {getActionText(showLoader, mode)}
                                          </Button>
                                       </FlexList>
                                    </Flex>
                                 </Grid.Col>
                              </Grid.Row>
                           </Grid>
                        ) : (
                           <Grid style={{ paddingTop: "16px" }}>
                              <Grid.Row>
                                 {pageType == PageType.PROJECT && (
                                    <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                       <H3 style={{ marginRight: "10px" }}>{item.tag.name}</H3>
                                       {item.expr_date && (
                                          <Typography
                                             intent="small"
                                             className={cx("expiration-date-Tags")}
                                          >
                                             {I18n.t(
                                                "views.company.workforce_planning.details_tags.tags_expiration_date_label",
                                             )}
                                             :{" "}
                                             {DateUtils.getShortNumericDate(
                                                new Date(item.expr_date),
                                             )}
                                          </Typography>
                                       )}
                                    </Grid.Col>
                                 )}
                                 {pageType == PageType.PERSON && (
                                    <>
                                       <Grid.Col colWidth={4} style={{ display: "flex" }}>
                                          <H3 style={{ marginRight: "10px" }}>{item.tag.name}</H3>
                                       </Grid.Col>
                                       <Grid.Col colWidth={2}>
                                          <Box>
                                             <H3>
                                                {I18n.t(
                                                   "views.company.workforce_planning.details_tags.tags_expiration_date_label",
                                                )}
                                             </H3>
                                             {item.expr_date
                                                ? formatDateTime(
                                                     getAttachedDate(item.expr_date),
                                                     I18n.locale,
                                                  )
                                                : "-"}
                                          </Box>
                                       </Grid.Col>{" "}
                                    </>
                                 )}
                                 <Grid.Col colWidth={6}>
                                    <Box>
                                       <H3>
                                          {I18n.t(
                                             "views.company.workforce_planning.details_tags.tags_attachments_label",
                                          )}
                                       </H3>
                                       {item.attachments && item.attachments.length > 0 && (
                                          <AttachmentLink
                                             attachments={item.attachments}
                                             onLinkClick={handleDownloadLinkClick}
                                          />
                                       )}
                                    </Box>
                                 </Grid.Col>
                              </Grid.Row>
                           </Grid>
                        )}
                     </Box>
                  </React.Fragment>
               ))}
            </>
         </Box>
         <Box padding="xxs" />
         {selectedAttachment && (
            <FileDownloadModal
               open={isOpen}
               onClose={handleModalClose}
               fileName={selectedAttachment.name}
               uploadedOn={getDateString(new Date(selectedAttachment.created_at))}
               attachmentId={selectedAttachment.id}
               mimeType={selectedAttachment.mimetype}
            />
         )}
      </>
   );
};

export const DetailsTagsCard: React.FC<DetailsCardProps> = ({
   detailsData,
   refreshTable,
   updateData,
   refetchData,
   loading: dataLoading,
   pageType,
}) => {
   const cardHeaderKeys = {
      titleKey: "views.company.workforce_planning.details_tags.title",
      buttonTextKey: "views.company.workforce_planning.details_tags.add_tag",
      helpTextKey:
         pageType === PageType.PERSON
            ? "views.company.workforce_planning.people_details_tags.tags_certification_help_text"
            : "views.company.workforce_planning.project_details_tags.tags_certification_help_text",
   };
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { data: tagOptions } = useGetTagOptionsQuery();
   const [filteredTags, setFilteredTags] = useState<TagOption[]>([]);
   const [loading, setLoading] = useState<boolean>(false);
   const { openModal, closeModal, isOpen } = useModal();
   const [selectedTagId, setSelectedTagId] = useState<string>("");
   const [canEditTags, setCanEditTags] = useState<boolean>(false);

   const handleTagCreate = async (payload: TagData, mode: string) => {
      await updateTags(payload, mode);
   };

   useEffect(() => {
      if (tagOptions) {
         // Filter tagOptions based on additional conditions for group_ids and globally_accessible
         const filtered = tagOptions.filter(
            (row) =>
               row.globally_accessible ||
               row.group_ids.some((id) => detailsData?.data.group_ids?.includes(id)),
         );

         // Filter out tags that are already available in the project
         const filteredTags = filtered.filter(
            (tag) =>
               !detailsData?.data.tag_instances.some(
                  (availableTag) => availableTag.tag_id === tag.id,
               ),
         );
         setFilteredTags(filteredTags);
      }
   }, [tagOptions, detailsData?.data]);

   const updateTags = async (payload: TagData, mode: string) => {
      try {
         setLoading(true);
         const updatedProject = await updateData(payload);
         if (updatedProject.data && mode === ActionMode.SAVE) {
            setSelectedTagId("");
            refetchData();
            if (refreshTable) {
               refreshTable();
            }
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      const valueAction =
         pageType === PageType.PROJECT ? AuthAction.EDIT_PROJECT_TAGS : AuthAction.EDIT_PEOPLE_TAGS;
      const canEditTags = checkAuthAction(valueAction);
      setCanEditTags(canEditTags);
   }, [checkAuthAction, pageType]);

   return (
      <>
         {(canEditTags || (detailsData && detailsData.data?.tag_instances.length > 0)) && (
            <Card style={{ marginBottom: "5px" }}>
               <Spinner loading={dataLoading}>
                  {canEditTags && detailsData && detailsData.data?.tag_instances.length === 0 && (
                     <EmptyTags
                        openAddTagsModal={openModal}
                        isAddTagsOpen={isOpen}
                        pageType={pageType}
                     />
                  )}
                  {detailsData && detailsData.data?.tag_instances.length > 0 && (
                     <ListTagsCard
                        tags={detailsData?.data?.tag_instances}
                        onUpdateProject={updateTags}
                        selectedTagId={selectedTagId}
                        setSelectedTagId={setSelectedTagId}
                        openAddTagsModal={openModal}
                        isAddTagsOpen={isOpen}
                        canEditTags={canEditTags}
                        showLoader={loading}
                        pageType={pageType}
                        cardHeaderKeys={cardHeaderKeys}
                     />
                  )}
                  {canEditTags && (
                     <AddTags
                        open={isOpen}
                        onClose={closeModal}
                        handleCreate={handleTagCreate}
                        availableTags={filteredTags}
                        showLoader={loading}
                        pageType={pageType}
                        cardHeaderKeys={cardHeaderKeys}
                     />
                  )}
               </Spinner>
            </Card>
         )}
      </>
   );
};
