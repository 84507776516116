import React, { useEffect, useState } from "react";
import { Lock } from "@procore/core-icons";
import type {
   ExtendedFieldType,
   IntegratedField,
   StreamResponseConversion,
   Tab,
} from "@/react/prop-types";
import {
   ActionMode,
   EntityAbbreviation,
   FieldType,
   PageTitle,
   TearsheetTabs,
} from "@/react/prop-types";
import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   H2,
   Page,
   Required,
   Spinner,
   useI18nContext,
} from "@procore/core-react";
import { CustomTabs } from "@/react/shared/components/custom-tabs";
import { useGetGroupOptionsQuery } from "../project/queries";
import {
   useGetCompany,
   useGetJobTitles,
   useGetNotificationProfiles,
   useGetPermissionLevelOptions,
} from "../../common/queries/queries";
import { useGetPeopleDetails } from "./queries";
import { FieldMapping } from "../../people-list/people-list-enums";
import { PEOPLE_STATUS_OPTIONS } from "../../people-list/people-list-prop-types";
import { CustomFieldType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { GetPersonDetailsResponse } from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import type {
   PersonDetailTearsheetProps,
   PersonDetailFields,
   PersonDetailFormValues,
} from "./prop-types";
import { processCustomFieldsFormValues } from "@/react/shared/custom-field-utils";
import type { FormikProps } from "formik";
import { ColorPicker } from "../../common/components/color-picker";
import {
   peopleDetailFieldsSection1,
   peopleDetailFieldsSection2,
   peopleDetailFieldsSection3,
   peopleDetailFieldsSection4,
   peopleDetailFieldsSection5,
} from "./constants";
import { isIntegratedField } from "@/react/shared/helper";
import {
   findById,
   generateCustomLabel,
   getFullName,
   getPersonGenderOptions,
   getPersonStatusMap,
   getPersonType,
   getPersonTypes,
   getReadOnlyViewForDates,
} from "./tearsheet-utils";
import { defaultStore } from "@/stores/default-store";
import { DateUtils } from "@/lib/utils/date";
import { legacyFieldNamesMap } from "../../people-list/helpers";
import { PhotoUploader } from "../shared/photo-uploader";
import { PersonStore } from "@/stores/person-store.core";
import { authManager } from "@/lib/managers/auth-manager";
import { QRCodeCard } from "@/react/shared/components/qr-code-card";
import { DetailsNotesCard } from "../notes/details-notes-card";
import { NotesType } from "../notes/types";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { DetailsAttachmentsCard } from "../attachments/details-attachments-card";
import { PageType } from "../types";
import { DetailsTagsCard } from "../tags/details-tags-card";
import { PersonDetailsActivities } from "./person-detail-cards/person-details-activities-card";
import { getProcoreCompanyIdFromURL } from "@/lib/utils/url";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { renderLinkToProcoreEntity } from "../utils";
import { DetailsTimeOffCard } from "../time-off/details-timeoff-card";
//adding more objects to this array will automatically start rendering other tabs, just maintain unique ID and labels.
const PERSON_DETAILS_TABS: Tab[] = [
   { id: 0, label: TearsheetTabs.OVERVIEW, visible: true },
   { id: 1, label: TearsheetTabs.NOTES, visible: true },
   { id: 2, label: TearsheetTabs.TIME_OFF, visible: true },
   { id: 3, label: TearsheetTabs.ACTIVITY, visible: true },
];

export const PersonDetailTearsheet: React.FC<PersonDetailTearsheetProps> = ({
   personId,
   customFields,
   integratedFields,
   onUpdatePeople,
   showActiveTab,
}) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const {
      data: peopleData,
      isLoading: peopleDataLoading,
      refetch,
   } = useGetPeopleDetails(personId);
   const { data: companyData } = useGetCompany();
   const { data: groups } = useGetGroupOptionsQuery();
   const { data: jobTitles } = useGetJobTitles();
   const { data: permissionLevels } = useGetPermissionLevelOptions();
   const { data: notificationProfiles } = useGetNotificationProfiles();
   const [personIntegratedFields, setPersonIntegratedFields] = useState<IntegratedField[]>([]);
   const activeTabId =
      (showActiveTab && PERSON_DETAILS_TABS.find((tab) => tab.label === showActiveTab)?.id) ?? 0;
   const [activeTab, setActiveTab] = useState(activeTabId || 0);
   const [formViewMode, setFormViewMode] = React.useState<"read" | "update">(ActionMode.READ);
   const personStatusMap = getPersonStatusMap(I18n);
   const personTypes = getPersonTypes(I18n);
   const personGenderOptions = getPersonGenderOptions(I18n);
   const [qrCodeEnabled, setQrCodeEnabled] = React.useState<boolean | undefined>(false);
   const [peopleQrCodeEnabled, setPeopleQRCodeEnabled] = React.useState<boolean | undefined>(false);
   const [canViewPeopleAttachments, setCanViewPeopleAttachments] = useState<boolean>(false);
   const [canViewPeopleTags, setCanViewPeopleTags] = useState<boolean>(false);
   const [personDetailTabsState, setPersonDetailTabsState] = useState(PERSON_DETAILS_TABS);
   let initialValues: PersonDetailFormValues = {} as PersonDetailFormValues;
   const procoreCompanyId: string | undefined = getProcoreCompanyIdFromURL();

   const checkUserCanViewTab = () => {
      const authActions: Array<AuthAction | null> = [
         null, // Placeholder for index alignment
         AuthAction.VIEW_PEOPLE_NOTES,
         AuthAction.VIEW_PEOPLE_TIMEOFF,
         AuthAction.VIEW_PEOPLE_ACTIVITY,
      ];

      const newPersonDetailTabsState = personDetailTabsState.map((tab, index) => ({
         ...tab,
         visible:
            authActions[index] !== null
               ? checkAuthAction(authActions[index] as AuthAction)
               : tab.visible,
      }));

      setPersonDetailTabsState(newPersonDetailTabsState);
   };

   useEffect(() => {
      checkUserCanViewTab();
      setQrCodeEnabled(authManager.companyModules()?.qrCodes);
      setPeopleQRCodeEnabled(authManager.companyModules()?.peopleQrCodes);
      const canViewPeopleAttachments = checkAuthAction(AuthAction.VIEW_PEOPLE_ATTACHMENTS);
      setCanViewPeopleAttachments(canViewPeopleAttachments);
      const canViewPeopleTags = checkAuthAction(AuthAction.VIEW_PEOPLE_TAGS);
      setCanViewPeopleTags(canViewPeopleTags);
   }, []);

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.people_integrated_fields)) {
            setPersonIntegratedFields(integratedFields.data.people_integrated_fields);
         }
      }
   }, [integratedFields]);

   // Function to render form fields
   function renderFormFields(fields: PersonDetailFields[], fieldSource?: string) {
      const rows = [];
      const colWidth = 4;
      for (let i = 0; i < fields.length; i += 3) {
         const rowFields = fields.slice(i, i + 3);

         const validComponents = rowFields
            .map((field: PersonDetailFields, index: number) => {
               let FormComponent = null;
               let additionalProps = {};
               let colStartBase: 1 | 5 | 9;
               let legacyFieldNameKey = field.name;
               //logic for handling the column start base in the form field
               switch (index) {
                  case 0:
                     colStartBase = 1;
                     break;
                  case 1:
                     colStartBase = 5;
                     break;
                  default:
                     colStartBase = 9;
                     break;
               }

               if (fieldSource === PageTitle.PEOPLE_DETAILS) {
                  switch (field.name) {
                     case FieldMapping.groups:
                        field.values = groups;
                        break;
                     case FieldMapping.status:
                        field.values = PEOPLE_STATUS_OPTIONS;
                        break;
                     case FieldMapping.job_title:
                        field.values = jobTitles;
                        legacyFieldNameKey = legacyFieldNamesMap["position_id"];
                        break;
                     case FieldMapping.type:
                        field.values = personTypes;
                        break;
                     case FieldMapping.permission:
                        field.values = permissionLevels;
                        legacyFieldNameKey = legacyFieldNamesMap["permission_level_id"];
                        break;
                     case FieldMapping.notification_profile:
                        field.values = notificationProfiles;
                        break;
                     case FieldMapping.gender:
                        field.values = personGenderOptions;
                        break;
                     default:
                        break;
                  }
               }

               switch (field.type as ExtendedFieldType) {
                  case CustomFieldType.DATE:
                     /* If form view mode is read, render a normal div with dates to respect configured date format in company setting, 
                     else render as date select, as date picker always shows dates in mm/dd/yyyy format */
                     if (formViewMode === ActionMode.READ) {
                        let fieldValue = initialValues[field.name as keyof PersonDetailFormValues];
                        //here using DateUtils to format the date as Globalization toolkit doesn't support date formatting with all the options
                        fieldValue = fieldValue
                           ? DateUtils.formatDate(
                                new Date(Number(fieldValue)),
                                defaultStore.getDateFormat(), // this will give the date format from company settings
                             )
                           : "--";
                        FormComponent = () =>
                           getReadOnlyViewForDates(
                              fieldSource,
                              colStartBase,
                              colWidth,
                              I18n,
                              field,
                              personIntegratedFields,
                              fieldValue as string,
                           );
                     } else {
                        FormComponent = Form.DateSelect;
                     }
                     break;
                  case CustomFieldType.NUMBER:
                     FormComponent = Form.Number;
                     break;
                  case CustomFieldType.TEXT:
                     FormComponent = Form.Text;
                     break;
                  case CustomFieldType.SELECT:
                     FormComponent = Form.Select;
                     additionalProps = {
                        options:
                           fieldSource === PageTitle.PEOPLE_DETAILS
                              ? field.values
                              : field.values?.map((value: string) => ({
                                   id: field.name + "_" + value,
                                   label: value,
                                })) ?? [],
                        onSearch: false,
                        placeholder: I18n.t("views.company.workforce_planning.select_option"),
                     };
                     break;
                  case CustomFieldType.MULTI_SELECT:
                     FormComponent = Form.MultiSelect;
                     additionalProps = {
                        options:
                           fieldSource === PageTitle.PEOPLE_DETAILS
                              ? field.values
                              : field.values?.map((value: string) => ({
                                   id: field.name + "_" + value,
                                   label: value,
                                })) ?? [],
                        placeholder: I18n.t("views.company.workforce_planning.select_option"),
                     };
                     break;
                  case CustomFieldType.PARAGRAPH:
                     FormComponent = Form.TextArea;
                     break;
                  case CustomFieldType.BOOL:
                     FormComponent = Form.Checkbox;
                     break;
                  case CustomFieldType.CURRENCY:
                     FormComponent = Form.Currency;
                     break;
                  case CustomFieldType.HEX_COLOR:
                     FormComponent = Form.Field;
                     additionalProps = {
                        as: ColorPicker,
                     };
                     break;
                  case FieldType.RADIO:
                     FormComponent = Form.RadioButtons;
                     additionalProps = {
                        options:
                           fieldSource === PageTitle.PEOPLE_DETAILS
                              ? field.values
                              : field.values?.map((value: string) => ({
                                   id: field.name + "_" + value,
                                   label: value,
                                })) ?? [],
                     };
                     break;
                  case FieldType.PILL_SELECT:
                     FormComponent = Form.PillSelect;
                     additionalProps = {
                        options:
                           field.values?.map((value) => ({
                              ...value,
                              label: I18n.t(value.label),
                           })) ?? [],
                     };
                     break;
               }

               if (!FormComponent) return null; // If FormComponent is null, return null
               // Set disabled true if field is an integrated field and is locked
               // in case of custom fields property matches with id and for common fields it matches with name
               if (
                  isIntegratedField(
                     fieldSource === PageTitle.PEOPLE_DETAILS
                        ? legacyFieldNamesMap[legacyFieldNameKey]
                        : field.id,
                     personIntegratedFields,
                  ) ||
                  field.integration_only
               ) {
                  additionalProps = { ...additionalProps, disabled: true };
               }
               return (
                  <React.Fragment
                     key={
                        fieldSource === PageTitle.PEOPLE_DETAILS
                           ? `people-field-fragment-outside-${field.name}`
                           : `custom-field-fragment-outside-${field.id}-${index}`
                     }
                  >
                     <FormComponent
                        key={
                           fieldSource === PageTitle.PEOPLE_DETAILS
                              ? `people-field-${field.name}`
                              : `custom-field-${field.id}`
                        }
                        colStart={colStartBase}
                        colWidth={colWidth}
                        name={field.name}
                        // @ts-expect-error: passing element as needed to append lock icon
                        label={
                           fieldSource === PageTitle.PEOPLE_DETAILS ? (
                              generateCustomLabel(
                                 legacyFieldNamesMap[legacyFieldNameKey],
                                 field.id,
                                 personIntegratedFields,
                                 I18n,
                              )
                           ) : (
                              <div style={{ display: "flex", alignItems: "center" }}>
                                 <span>{field.name}</span>
                                 {(isIntegratedField(field.id, personIntegratedFields) ||
                                    field.integration_only) && (
                                    <Lock size="sm" style={{ marginLeft: "3px" }} />
                                 )}
                              </div>
                           )
                        }
                        {...additionalProps}
                        {...(field.required === true && { required: true })}
                     />
                  </React.Fragment>
               );
            })
            .filter((component: any) => component !== null); // Filter out null components
         if (validComponents.length > 0) {
            rows.push(<Form.Row key={`custom-fields-row-${i}`}>{validComponents}</Form.Row>);
         }
      }
      return rows;
   }

   /* istanbul ignore next */
   async function updatePeopleData(payload: any) {
      return await PersonStore.updatePerson(personId, payload).payload;
   }

   /* istanbul ignore next */
   async function handleUploadPhoto(url: string | null) {
      await updatePeopleData({ profile_pic_url: url });
   }

   if (peopleData?.data && groups) {
      initialValues = getFormValues(peopleData, groups);
   }

   function getFormValues(peopleData: GetPersonDetailsResponse, groups: StreamResponseConversion) {
      const data = peopleData.data;
      const personType = getPersonType(data.is_assignable, data.is_user, personTypes);
      const baseFormValues = {
         first_name: data.name.first,
         last_name: data.name.last,
         status: personStatusMap[data.status],
         groups: data.group_ids
            ? data.group_ids.map((groupId) => findById(groups, groupId)).filter(Boolean)
            : [],
         job_title: findById(jobTitles, data.position?.id),
         type: personType,
         permission: findById(permissionLevels, data.permission_level_id),
         address_1: data.address_1 ?? null,
         address_2: data.address_2 ?? null,
         city_town: data.city_town ?? null,
         state_province: data.state_province ?? null,
         zipcode: data.zipcode ?? null,
         country: data.country ?? null,
         employee_number: data.employee_number ?? null,
         email: data.email ?? null,
         phone: data.phone ?? null,
         can_receive_email: data.can_receive_email,
         can_receive_sms: data.can_receive_sms,
         can_receive_mobile: data.can_receive_mobile,
         notification_profile: findById(notificationProfiles, data.notification_profile_id),
         emergency_contact_name: data.emergency_contact_name,
         emergency_contact_number: data.emergency_contact_number,
         emergency_contact_relation: data.emergency_contact_relation,
         hourly_wage: data.hourly_wage ?? null,
         language: data.language,
         gender: findById(personGenderOptions, data.gender),
         hired_date: data.hired_date ? new Date(data.hired_date) : null,
         dob: data.dob ? new Date(data.dob) : null,
      };

      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      return { ...baseFormValues, ...customFieldValues };
   }

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            {peopleDataLoading ? (
               <Spinner loading />
            ) : (
               <>
                  <Page.Header>
                     <Page.Title>
                        <H1>
                           {getFullName(peopleData?.data.name)}{" "}
                           {renderLinkToProcoreEntity({
                              entityType: CustomFieldEntity.PERSON,
                              procoreEntityId: peopleData?.data.procore_person_id,
                              procoreCompanyId: procoreCompanyId,
                           })}
                        </H1>
                     </Page.Title>
                     <CustomTabs
                        tabs={personDetailTabsState}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                     />
                  </Page.Header>
                  {activeTab === personDetailTabsState[0].id && (
                     <Form view={formViewMode} initialValues={initialValues} enableReinitialize>
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {({
                           submitForm,
                           isSubmitting,
                           resetForm,
                        }: FormikProps<PersonDetailFormValues>) => (
                           <>
                              <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                 <Card style={{ marginBottom: "5px" }}>
                                    <Form.Form>
                                       <Box padding="md">
                                          <FlexList justifyContent="space-between">
                                             <H2>Information</H2>
                                             {formViewMode === ActionMode.READ && (
                                                <Button
                                                   variant="secondary"
                                                   onClick={() =>
                                                      setFormViewMode(ActionMode.UPDATE)
                                                   }
                                                   data-testid="edit-people-details"
                                                   disabled // can be enabled during the edit story
                                                >
                                                   {I18n.t("views.company.workforce_planning.edit")}
                                                </Button>
                                             )}
                                          </FlexList>
                                       </Box>
                                       <Box padding="md">
                                          {peopleDetailFieldsSection1 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   peopleDetailFieldsSection1 as PersonDetailFields[],
                                                   PageTitle.PEOPLE_DETAILS,
                                                )}{" "}
                                             </>
                                          )}
                                          <hr />
                                          {peopleDetailFieldsSection2 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   peopleDetailFieldsSection2 as PersonDetailFields[],
                                                   PageTitle.PEOPLE_DETAILS,
                                                )}{" "}
                                             </>
                                          )}
                                          <hr />
                                          {peopleDetailFieldsSection3 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   peopleDetailFieldsSection3 as PersonDetailFields[],
                                                   PageTitle.PEOPLE_DETAILS,
                                                )}{" "}
                                             </>
                                          )}

                                          <hr />
                                          {peopleDetailFieldsSection4 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   peopleDetailFieldsSection4 as PersonDetailFields[],
                                                   PageTitle.PEOPLE_DETAILS,
                                                )}{" "}
                                             </>
                                          )}

                                          <hr />
                                          {peopleDetailFieldsSection5 && (
                                             <>
                                                {" "}
                                                {renderFormFields(
                                                   peopleDetailFieldsSection5 as PersonDetailFields[],
                                                   PageTitle.PEOPLE_DETAILS,
                                                )}{" "}
                                             </>
                                          )}

                                          {customFields && (
                                             <>
                                                <hr />
                                                {renderFormFields(
                                                   customFields as PersonDetailFields[],
                                                )}
                                             </>
                                          )}
                                       </Box>
                                    </Form.Form>
                                 </Card>
                                 <Card style={{ marginBottom: "5px" }}>
                                    <Box padding="md">
                                       <H2>
                                          {I18n.t(
                                             "views.company.workforce_planning.people.profile_photo",
                                          )}
                                       </H2>
                                       <PhotoUploader
                                          imgUrl={peopleData?.data.profile_pic_url ?? ""}
                                          onUploadPhoto={handleUploadPhoto}
                                          disableUpload={false} // here editing permission can be passed, is the user having privilege of editing people details
                                       />
                                    </Box>
                                 </Card>
                                 {canViewPeopleTags && (
                                    <DetailsTagsCard
                                       detailsData={peopleData}
                                       refetchData={refetch}
                                       loading={peopleDataLoading}
                                       updateData={updatePeopleData}
                                       pageType={PageType.PERSON}
                                       refreshTable={onUpdatePeople}
                                    ></DetailsTagsCard>
                                 )}
                                 {canViewPeopleAttachments && (
                                    <DetailsAttachmentsCard
                                       attachmentData={peopleData}
                                       refetchData={refetch}
                                       loading={peopleDataLoading}
                                       updateData={updatePeopleData}
                                       pageType={PageType.PERSON}
                                    />
                                 )}
                                 {qrCodeEnabled && peopleQrCodeEnabled && (
                                    <QRCodeCard
                                       entityAbbreviation={EntityAbbreviation.PERSON}
                                       companyQrId={companyData?.data.qr_id}
                                       entityQrId={peopleData?.data.qr_id}
                                       entityTitle={getFullName(peopleData?.data.name)}
                                       entitySubtitle={peopleData?.data.employee_number}
                                       imageDetailsHelpText={I18n.t(
                                          "views.company.workforce_planning.people.qr_code.image_details_help_text",
                                       )}
                                    />
                                 )}
                              </Page.Body>
                              {formViewMode === ActionMode.UPDATE && (
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="space-between" alignItems="center">
                                          <Required showLabel />
                                          <FlexList space="sm">
                                             <Button
                                                variant="tertiary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                   setFormViewMode(ActionMode.READ);
                                                   resetForm();
                                                }}
                                             >
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                             >
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              )}
                           </>
                        )}
                     </Form>
                  )}
                  {activeTab === personDetailTabsState[1].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <DetailsNotesCard
                           loading={peopleDataLoading}
                           refetchData={refetch}
                           noteType={NotesType.PERSON}
                           notesData={peopleData}
                           contextId={personId}
                        />
                     </Page.Body>
                  )}
                  {activeTab === personDetailTabsState[2].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <DetailsTimeOffCard
                           loading={peopleDataLoading}
                           refetchData={refetch}
                           timeOffData={peopleData}
                           contextId={personId}
                           pageType={PageType.PERSON}
                        />
                     </Page.Body>
                  )}
                  {activeTab === personDetailTabsState[3].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <PersonDetailsActivities personId={personId} />
                     </Page.Body>
                  )}
               </>
            )}
         </Page.Main>
      </Page>
   );
};
