import type { useI18nContext } from "@procore/core-react";
import type { DeleteActionProps, EditActionProps } from "./time-off-list-prop-types";
import { EditTimeOffTearsheet } from "../tearsheets/time-off/edit-time-off-tearsheet";
import { DeleteTimeOffModal } from "./modals/confirm-delete-modal";
import React from "react";

// Edit Component for the row actions
export const Edit = (props: EditActionProps) => {
   const { data, tableApi, updateTimeOff, refetchTimeOffData } = props;
   return (
      <EditTimeOffTearsheet
         timeOff={data}
         tableApi={tableApi}
         updateTimeOff={updateTimeOff}
         refetchTimeOffData={refetchTimeOffData}
      />
   );
};

// Delete Component for the row actions
export const Delete = (props: DeleteActionProps) => {
   const { data, tableApi, refetchTimeOffData } = props;
   return (
      <DeleteTimeOffModal
         timeOffId={data.id}
         tableApi={tableApi}
         refetchTimeOffData={refetchTimeOffData}
      />
   );
};

export const getTimeOffTypeOptions = (I18n: ReturnType<typeof useI18nContext>) => [
   {
      id: 1,
      value: "paid",
      label: I18n.t("views.company.workforce_planning.time_off.paid"),
      color: "green",
   },
   {
      id: 2,
      value: "unpaid",
      label: I18n.t("views.company.workforce_planning.time_off.unpaid"),
      color: "yellow",
   },
];

export const getTimeOffWeekendOptions = (I18n: ReturnType<typeof useI18nContext>) => [
   {
      id: 1,
      label: I18n.t("views.company.workforce_planning.time_off.apply_to_saturday"),
      value: "apply_to_saturday",
   },
   {
      id: 2,
      label: I18n.t("views.company.workforce_planning.time_off.apply_to_sunday"),
      value: "apply_to_sunday",
   },
];

export const getTimeOffRepeatOptions = (I18n: ReturnType<typeof useI18nContext>) => [
   {
      id: 1,
      value: "never",
      label: I18n.t("views.company.workforce_planning.time_off.never"),
   },
   {
      id: 2,
      value: "weekly",
      label: I18n.t("views.company.workforce_planning.time_off.weekly"),
   },
   {
      id: 3,
      value: "monthly",
      label: I18n.t("views.company.workforce_planning.time_off.monthly"),
   },
   {
      id: 4,
      value: "yearly",
      label: I18n.t("views.company.workforce_planning.time_off.yearly"),
   },
];
