import type {
   CustomField,
   PillValue,
   SortModel,
   StreamResponseConversion,
   TagOption,
} from "@/react/prop-types";
import type { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type {
   CellValueChangeParams,
   ColumnDefinition,
   IServerFilter,
   TableApi,
} from "@procore/data-table";
import type { FindPeopleSortBy } from "./people-list-enums";
import type {
   IndexedFindPeopleSortBy,
   NonIndexedFindPeopleSortBy,
} from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-people";
import type { PeopleDetailFormValues } from "../tearsheets/people/types";

export type CoreApiSort = {
   sort_by?: NonIndexedFindPeopleSortBy | IndexedFindPeopleSortBy | FindPeopleSortBy;
   direction?: Order;
   custom_field_id?: string;
};

export type PeopleListData = {
   id: string;
   country: string;
   status: PillValue<string>;
   group_ids?: string[];
   gender: string;
   job_title: string;
};

export type PeopleListDataTableProps = {
   fetchPeopleList: (
      serverFilters: IServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
      search?: string,
      groupKeys?: string[],
      rowGroupCols?: ColumnDefinition[],
   ) => Promise<any>;
   groupId: string;
   savedView: any;
   currentDateTime: string;
   tableApi: TableApi | undefined;
   handleTableReady: (api: TableApi) => void;
   customFields?: CustomField[] | null;
   groupOptions: StreamResponseConversion;
   streamJobTitles: () => Promise<StreamResponseConversion>;
   updatePeople: (params: CellValueChangeParams<any>) => Promise<any>;
   isLastNameFirst: boolean;
};

export type EditPeopleFormValues = Omit<Partial<PeopleDetailFormValues>, "name">;

export const PEOPLE_STATUS_OPTIONS = [
   {
      id: "active",
      color: "green",
      label: "views.company.workforce_planning.active",
   } as const,
   {
      id: "inactive",
      color: "gray",
      label: "views.company.workforce_planning.inactive",
   } as const,
];

export const PERSON_TYPE_OPTIONS = [
   {
      id: "assignable",
      label: "views.company.workforce_planning.people.assignable",
   } as const,
   {
      id: "assignable_user",
      label: "views.company.workforce_planning.people.assignable_user",
   } as const,
   {
      id: "user",
      label: "views.company.workforce_planning.people.user",
   } as const,
];

//created this interface by extending IServerFilter, as new fieldId, fieldType is added to the filter for custom_field filtering
export interface PeopleListServerFilter extends IServerFilter {
   fieldId?: string;
   fieldType?: string;
}

export type FormattedTags = TagOption & {
   shape: string;
   componentName: string;
   label?: string;
};

export type ValueValidatorResponse = {
   isValid: boolean;
   errorMessage: string;
   isRequired: boolean;
};
