import React, { useCallback, useState } from "react";
import {
   Dropzone,
   Flex,
   Thumbnail,
   Tooltip,
   spacing,
   useDropzone,
   useI18nContext,
} from "@procore/core-react";
import { CloudinaryStore } from "@/stores/cloudinary-store.core";
import { Clear } from "@procore/core-icons";
import "../style.css";
import { getFileDimensions, readFile, resizeFile } from "../helper";
export const PHOTO_ACCEPTED_FILE_TYPES = [".jpg", ".jpeg", ".png", ".x-png", ".gif", ".bmp"];
export const PHOTO_MIN_DIMENSION = 104;
export const PHOTO_MAX_DIMENSION = 1080;

const PhotoDropzone = (props: any) => {
   const { thumbnail, label, open, disabled, clearPhoto, I18n } = props;
   const removeImageText: string = I18n.t("views.company.workforce_planning.remove_image");
   return (
      <Flex alignItems="center" justifyContent="center" className="thumbnail">
         <Thumbnail
            src={thumbnail ?? ""}
            size="lg"
            label={label}
            clickable
            onClick={open}
            variant="image"
            disabled={disabled}
            style={{ zIndex: 1 }}
         />
         {thumbnail && (
            <button onClick={clearPhoto} className="clear-button" aria-label={removeImageText}>
               <Tooltip overlay={removeImageText} trigger={["hover", "focus"]}>
                  <Clear size="sm" />
               </Tooltip>
            </button>
         )}
      </Flex>
   );
};
export const PhotoUploader = React.memo(function PhotoUploader(props: {
   imgUrl: string;
   onUploadPhoto: (url: string | null) => void;
   disableUpload: boolean;
}) {
   const I18n = useI18nContext();
   const [hasError, setHasError] = useState<boolean>(false);
   const { imgUrl, onUploadPhoto, disableUpload } = props;
   const [thumbnail, setThumbnail] = useState<string>(imgUrl);

   const clearPhoto = () => {
      setThumbnail("");
      onUploadPhoto(null);
   };

   const onDrop = useCallback(
      async (acceptedFiles: File[]) => {
         if (acceptedFiles?.length) {
            const inputFile = await readFile(acceptedFiles?.[0]);
            const { width, height } = await getFileDimensions(inputFile as string);

            const isInvalidSize =
               width > PHOTO_MAX_DIMENSION ||
               height > PHOTO_MAX_DIMENSION ||
               width < PHOTO_MIN_DIMENSION ||
               height < PHOTO_MIN_DIMENSION;

            setHasError(isInvalidSize);
            if (isInvalidSize) {
               setThumbnail("");
               return;
            }

            const resizedDataUrl = await resizeFile(
               acceptedFiles?.[0],
               PHOTO_MIN_DIMENSION,
               PHOTO_MIN_DIMENSION,
               "base64",
            );

            setThumbnail(resizedDataUrl as string);
            const uploadResponse = await CloudinaryStore.uploadPhoto(resizedDataUrl as string)
               .payload;

            if ("error" in uploadResponse) {
               console.error(uploadResponse.error.message);
               return;
            }

            onUploadPhoto(uploadResponse.secure_url);
         }
      },
      [onUploadPhoto],
   );

   const dropzone = useDropzone({
      multiple: false,
      maxFileNumber: 1,
      accept: PHOTO_ACCEPTED_FILE_TYPES,
      preventDropOnDocument: true,
      disabled: disableUpload,
      onDrop,
   });

   const handleDropError = useCallback((error: any) => {
      if (error === "reset") {
         setHasError(false);
      }
   }, []);

   return (
      <Dropzone
         {...dropzone}
         {...(hasError
            ? {
                 dropError: {
                    type: "MAX_FILE_SIZE",
                    title: "",
                    message: I18n.t(
                       "views.company.workforce_planning.projects.select_image.dimenssionError",
                       {
                          min: PHOTO_MIN_DIMENSION,
                          max: PHOTO_MAX_DIMENSION,
                       },
                    ),
                 },
              }
            : {})}
         dispatchDropError={handleDropError}
         isIconVisible={false}
         rootProps={{
            style: {
               padding: 0,
               margin: 0,
               width: "auto",
               height: 104,
               border: 0,
               marginTop: `${spacing.lg}px`,
            },
         }}
         contentRenderer={(props) => (
            <PhotoDropzone
               {...props}
               thumbnail={thumbnail}
               label={I18n.t("views.company.workforce_planning.projects.select_image.label")}
               disabled={disableUpload}
               clearPhoto={clearPhoto}
               I18n={I18n}
            />
         )}
      />
   );
});
