import { DateUtils } from "@/lib/utils/date";
import { Format } from "@/lib/utils/format";
import type { CustomFieldProjectType, SortModel } from "@/react/prop-types";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { PersonStore } from "@/stores/person-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import type {
   FindPeoplePaginatedQueryParams,
   SerializedPeopleListPerson,
} from "@laborchart-modules/lc-core-api";
import { Box, DetailPage, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition, TableApi } from "@procore/data-table";
import moment from "moment-timezone";
import * as React from "react";
import { useGetCustomFields, useGetSavedView } from "../common/queries/queries";
import { PersonTearsheetProvider } from "../tearsheets/people/people-tearsheet";
import { useGetGroupOptionsQuery } from "../tearsheets/project/queries";
import {
   columnHeadersMap,
   convertDataTableSort,
   convertPeopleDataTableFilters,
   defaultPeopleListTableConfig,
   filterFieldMap,
   filterGroupDetails,
   filterRendererMap,
   updatePeople,
} from "./helpers";
import { PeopleListDataTable } from "./people-list-data-table";
import type { PeopleListServerFilter } from "./people-list-prop-types";
import { FileDownloadToastProvider } from "../data-table/DataExport/FileDownload";
import type { SavedView } from "@laborchart-modules/common";
import { CustomFieldType } from "@laborchart-modules/common";
import { useViewPreferenceContext } from "@/react/providers/permission-context-provider";
import { convertSavedViewToDataTableConfig, getDataForGrouping } from "@/react/shared/helper";

const setDateValue = (value?: number | null) => (value ? new Date(value) : null);

const setPersonStatus = (status: PersonStatus) => {
   const statusMap: Record<PersonStatus, { label: string; color: string }> = {
      [PersonStatus.ACTIVE]: {
         label: "views.company.workforce_planning.active",
         color: "green",
      },
      [PersonStatus.INACTIVE]: {
         label: "views.company.workforce_planning.inactive",
         color: "gray",
      },
   };

   return {
      id: status,
      ...statusMap[status],
   };
};

const setPersonType = (isUser: boolean, isAssignable: boolean) => {
   if (isUser && isAssignable) {
      return "assignable-user";
   } else if (isAssignable) {
      return "assignable";
   } else if (isUser) {
      return "user";
   }
};

const setTagsValue = (tags: SerializedPeopleListPerson["tag_instances"]) => {
   return tags?.map((tag) => ({
      label: tag.tag.abbreviation,
      color: tag.tag.color,
      id: tag.tag.id,
      name: tag.tag.name,
      shape: "pill",
      componentName: "multiselect",
   }));
};

const setJobValue = (position: SerializedPeopleListPerson["position"]) => {
   return {
      label: position?.name ?? "",
      color: position?.color ?? "",
      shape: "circle",
   };
};

const setPersonValue = (person: SerializedPeopleListPerson) => {
   return {
      name: {
         first: person.name.first ?? " ",
         last: person.name.last ?? " ",
      },
      profile_pic_url: person.profile_pic_url,
   };
};
const setPersonGender = (gender: SerializedPeopleListPerson["gender"], I18n: any) => ({
   id: gender,
   label:
      gender == "male"
         ? I18n.t("views.company.workforce_planning.male")
         : I18n.t("views.company.workforce_planning.female"),
});

export function PeopleListContainer() {
   const { getViewPreference } = useViewPreferenceContext();
   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;
   const { groupId } = useGroupContext();
   const I18n = useI18nContext();
   const { data: groupOptions } = useGetGroupOptionsQuery();
   const [tableApi, setTableApi] = React.useState<TableApi>();
   const { data: customFields } = useGetCustomFields(CustomFieldEntity.PERSON);
   const [convertedSavedView, setConvertedSavedView] = React.useState<any>();

   const url = new URL(window.location.href);
   const params = url.searchParams;
   const [viewId] = React.useState(params.get("viewId"));

   const handleTableReady = (api: TableApi) => setTableApi(api);

   const { data: savedViewData } = useGetSavedView(viewId);

   /* istanbul ignore next */
   React.useEffect(() => {
      if (viewId && savedViewData && customFields && !convertedSavedView) {
         const convertedSavedViewData = convertSavedViewToDataTableConfig(
            savedViewData?.data as SavedView,
            columnHeadersMap,
            defaultPeopleListTableConfig,
            filterFieldMap,
            filterRendererMap,
            groupId,
            customFields,
         );
         setConvertedSavedView(convertedSavedViewData);
      }
   }, [groupId, viewId, savedViewData, convertedSavedView, setConvertedSavedView, customFields]);

   const fetchPeopleList = async (
      filters: PeopleListServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
      search?: string,
      groupKeys?: string[],
      rowGroupCols?: ColumnDefinition[],
   ) => {
      const coreApiFilters = convertPeopleDataTableFilters(filters);

      const coreApiSort = convertDataTableSort(sortModel, isLastNameFirst);
      const params: FindPeoplePaginatedQueryParams = {
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         limit: 100,
         starting_after: startingAfter,
         search: search,
         sort_by: coreApiSort.sort_by,
         sort_direction: coreApiSort.direction,
         custom_field_id: coreApiSort.custom_field_id, // required when sorted by custom field
         group_id: groupId === "my-groups" ? undefined : groupId,
         filters: coreApiFilters,
      };
      const peopleData = await PersonStore.findPeopleList(params).payload;

      const peopleListDetails: Array<Record<string, any>> = [];

      if (rowGroupCols && rowGroupCols?.length > 0) {
         if (peopleData.pagination?.next_starting_after) {
            let nextStartingAfter: string | null = peopleData.pagination.next_starting_after;
            do {
               try {
                  const nextData: any = await PersonStore.findPeopleList({
                     ...params,
                     starting_after: nextStartingAfter,
                     limit: 100,
                     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }).payload;

                  peopleData.data.push(...nextData.data);
                  nextStartingAfter = nextData.pagination?.next_starting_after;
               } catch (error) {
                  console.error("Error fetching people list:", error);
                  break; // Exit the loop on error
               }
            } while (nextStartingAfter);
         }

         peopleData.data = await getDataForGrouping(
            peopleData.data,
            rowGroupCols,
            groupKeys,
            "people_list",
         );
         if (groupKeys?.length === 0) {
            return { data: peopleData.data, pagination: peopleData.data.length };
         }
      }

      for (const personData of peopleData.data) {
         const personListOptions = filterGroupDetails({
            groupOptions,
            personGroupList: personData.group_ids ?? [],
         });

         const currentAssignments = personData.current_assignments
            ? personData.current_assignments
            : [];

         const nextAssignments = Array.isArray(personData.next_assignment)
            ? personData.next_assignment
            : personData.next_assignment
            ? [personData.next_assignment]
            : [];

         const assignments = [...currentAssignments, ...nextAssignments];

         // Initialize the base people details
         const personDetails: Record<string, any> = {
            ...personData,
            person: setPersonValue(personData),
            permission: personData.permission_level?.name,
            job_title: setJobValue(personData.position),
            tag_instances: setTagsValue(personData.tag_instances ?? []),
            type: setPersonType(personData.is_user, personData.is_assignable),
            status: setPersonStatus(personData.status),
            email: personData.email,
            hourly_wage: personData.hourly_wage,
            address_1: personData.address_1,
            address_2: personData.address_2,
            city: personData.city_town,
            state: personData.state_province,
            country: personData.country,
            postal: personData.zipcode,
            hired_date: setDateValue(personData.hired_date),
            linked_to_procore: personData.procore_id,
            language: Format.capitalize(personData.language),
            notification_profile: personData.notification_profile?.name,
            gender: personData.gender ? setPersonGender(personData.gender, I18n) : null,
            dob: setDateValue(personData.dob),
            groups: personListOptions,
            assignments,
         };

         // Adding custom fields as individual attributes
         if (personData.custom_fields) {
            personData.custom_fields.forEach((customField: CustomFieldProjectType) => {
               const { name, value, type } = customField;
               if (type === CustomFieldType.DATE && value) {
                  personDetails[name] = DateUtils.getAttachedDate(value);
               } else if (type === CustomFieldType.HEX_COLOR && value) {
                  personDetails[name] = {
                     label: "",
                     color: value,
                     shape: "square",
                  };
               } else {
                  personDetails[name] = value;
               }
            });
         }
         peopleListDetails.push(personDetails);
      }

      return { data: peopleListDetails, pagination: peopleData.pagination };
   };

   const streamJobTitles = async () => {
      const params: { group_id?: string } = {};
      if (groupId != "my-groups") {
         params.group_id = groupId;
      }
      const stream = await PositionStore.findPositionsStream(params).stream;

      const job_titles = [];

      for await (const item of stream) {
         job_titles.push({
            id: item.id,
            label: item.name,
         });
      }

      return job_titles;
   };

   const currentDateTime = moment().format("YYYYMMDD_HHmmss");

   return (
      <PersonTearsheetProvider peopleTableApi={tableApi}>
         <FileDownloadToastProvider>
            <DetailPage width="block" className={"border-box people-list-detail-page"}>
               <DetailPage.Main className="people-list-page-main">
                  <DetailPage.Body className="people-list-page-body">
                     <DetailPage.Card className="people-list-card">
                        <DetailPage.Section className="people-list-section">
                           <Box
                              style={{
                                 height: "100%",
                                 marginTop: "5px",
                              }}
                           >
                              {((!viewId && groupOptions && customFields) ||
                                 (viewId &&
                                    convertedSavedView &&
                                    groupOptions &&
                                    customFields)) && (
                                 <PeopleListDataTable
                                    fetchPeopleList={fetchPeopleList}
                                    groupId={groupId}
                                    savedView={viewId ? convertedSavedView : null}
                                    currentDateTime={currentDateTime}
                                    tableApi={tableApi}
                                    handleTableReady={handleTableReady}
                                    customFields={customFields}
                                    groupOptions={groupOptions}
                                    streamJobTitles={streamJobTitles}
                                    updatePeople={(params) => updatePeople(params, customFields)}
                                    isLastNameFirst={isLastNameFirst}
                                 />
                              )}
                           </Box>
                        </DetailPage.Section>
                     </DetailPage.Card>
                  </DetailPage.Body>
               </DetailPage.Main>
            </DetailPage>
         </FileDownloadToastProvider>
      </PersonTearsheetProvider>
   );
}
