import { v4 } from "uuid";
import type { DataTableRequest } from "./prop-types";
import { addDatesToRequestsCurve, getWorkdaysPerWeek } from "./helpers";
import type { Project } from "@laborchart-modules/common";

export function generateBellCurve(
   duration: number,
   work_hours: number,
   total_estimated_hours: number,
   max_peak_workers: number | null,
   work_days: any,
   project: Project,
): any {
   const days_per_week = getWorkdaysPerWeek(work_days);
   const HOURS_PER_WEEK = days_per_week * work_hours;
   // To generate a skewed curve, we simply update the mean.
   // To skew to the right, increase the mean
   // To skew to the left, decrease the mean
   const mean = (duration - 1) / 2;
   const hours_per_week: number[] = [];
   const std_dev = mean / 2;
   // This loop is bell curve specific, update for skewed curvees
   // Calculate total PDF sum for normalization
   let total_pdf_sum = 0;
   for (let i = 0; i < duration; i++) {
      total_pdf_sum += bellCurvePDF(i, mean, std_dev);
   }
   for (let i = 0; i < duration; i++) {
      const percentage = bellCurvePDF(i, mean, std_dev) / total_pdf_sum;
      const hours_in_week = percentage * Number(total_estimated_hours);

      hours_per_week.push(hours_in_week);
   }
   const requests: Array<Omit<DataTableRequest, "start_day" | "end_day">> = [];
   let assigned_workers = 0;
   hours_per_week.forEach((h: number, i: number) => {
      const estimated_weekly_workers = h / HOURS_PER_WEEK;
      let weekly_workers;
      if (max_peak_workers) {
         weekly_workers =
            estimated_weekly_workers > max_peak_workers
               ? max_peak_workers
               : estimated_weekly_workers;
      } else {
         weekly_workers = estimated_weekly_workers;
      }
      const net_new_workers = Math.round(weekly_workers - assigned_workers);
      if (i < mean && net_new_workers > 0) {
         const week_start = i + 1;
         const week_end = duration - i;

         requests.push({
            id: v4(),
            week_start: week_start,
            week_end: week_end,
            weekly_workers: net_new_workers,
            request_duration: week_end - week_start + 1,
            job_title: null,
            category: null,
         });

         assigned_workers += net_new_workers;
      }
   });

   return addDatesToRequestsCurve(requests, project, work_days);
}

export function generateCustomCurve(
   duration: number,
   work_hours: number,
   total_estimated_hours: number,
   max_peak_workers: number | null,
   work_days: any,
   project: Project,
   mean: number,
   stdDevCoefficient: number = 1,
): any {
   const days_per_week = getWorkdaysPerWeek(work_days);
   const HOURS_PER_WEEK = days_per_week * work_hours;
   // To generate a skewed curve, we simply update the mean.
   // To skew to the right, increase the mean
   // To skew to the left, decrease the mean
   const midpoint = duration / 2;
   if (midpoint == mean) {
      return generateBellCurve(
         duration,
         work_hours,
         total_estimated_hours,
         max_peak_workers,
         work_days,
         project,
      );
   }
   const hours_per_week: number[] = [];
   let planned_hours = 0;
   const std_dev = (midpoint / 3) * stdDevCoefficient;
   // This loop is bell curve specific, update for skewed curvees
   for (let i = 0; i < duration; i++) {
      const percentage = bellCurvePercentage(i, mean, std_dev);
      const hours_thru_week = percentage * total_estimated_hours;
      // Round the hours to the nearest multiple of work_hours (40 hours)
      const hours_in_week = Math.max(
         Math.ceil(hours_thru_week / HOURS_PER_WEEK) * HOURS_PER_WEEK - planned_hours,
         HOURS_PER_WEEK,
      );

      hours_per_week.push(hours_in_week);
      planned_hours += hours_in_week;
   }

   const workers_per_week = hours_per_week.map((x: number) => {
      return x / HOURS_PER_WEEK;
   });
   const requests =
      mean > midpoint
         ? generateRightSkewedRequests(hours_per_week, workers_per_week, duration, max_peak_workers)
         : generateLeftSkewedRequests(hours_per_week, workers_per_week, max_peak_workers);
   return addDatesToRequestsCurve(requests, project, work_days);
}

function generateRightSkewedRequests(
   hours_per_week: number[],
   workers_per_week: number[],
   duration: number,
   max_peak_workers: number | null,
): any {
   const requests: any[] = [];
   let assigned_workers = 0;
   hours_per_week.forEach((h: number, i: number) => {
      let reachedMaxPeak = false;
      let net_new_workers = workers_per_week[i];
      if (max_peak_workers) {
         if (assigned_workers + net_new_workers > max_peak_workers) {
            net_new_workers = max_peak_workers - assigned_workers;
         }

         reachedMaxPeak = assigned_workers >= max_peak_workers;
      }

      if (net_new_workers > 0 && !reachedMaxPeak) {
         assigned_workers += net_new_workers;
         const week_start = i + 1;
         const last_week_needing_workers = findLastWeekNeedingWorkers(workers_per_week);
         const week_end = last_week_needing_workers + 1;
         requests.push({
            id: v4(),
            week_start: week_start,
            week_end: week_end,
            weekly_workers: net_new_workers,
            request_duration: week_end - week_start + 1,
            job_title: null,
            category: null,
         });

         for (let w = i; w < duration; w++) {
            workers_per_week[w] = Math.max(workers_per_week[w] - net_new_workers, 0);
         }
      }
   });

   return requests;
}
function generateLeftSkewedRequests(
   hours_per_week: number[],
   workers_per_week: number[],
   max_peak_workers: number | null,
): any {
   const requests: any[] = [];
   let assigned_workers = 0;

   for (let i = hours_per_week.length; i > 0; i--) {
      let reachedMaxPeak = false;
      let net_new_workers = workers_per_week[i];
      if (max_peak_workers) {
         if (assigned_workers + net_new_workers > max_peak_workers) {
            net_new_workers = max_peak_workers - assigned_workers;
         }

         reachedMaxPeak = assigned_workers >= max_peak_workers;
      }
      if (net_new_workers > 0 && !reachedMaxPeak) {
         assigned_workers += net_new_workers;
         const week_end = i;
         const first_week_needing_workers = findFirstWeekNeedingWorkers(workers_per_week);
         const week_start = first_week_needing_workers + 1;
         requests.push({
            id: v4(),
            week_start: week_start,
            week_end: week_end,
            weekly_workers: net_new_workers,
            request_duration: week_end - week_start + 1,
            job_title: null,
            category: null,
         });

         for (let w = i; w >= 0; w--) {
            workers_per_week[w] = Math.max(workers_per_week[w] - net_new_workers, 0);
         }
      }
   }
   return requests;
}

function findLastWeekNeedingWorkers(weekly_workers: number[]): number {
   let value = -1;
   weekly_workers.forEach((x: number, i: number) => {
      if (x > 0) {
         value = i;
      }
   });

   return value;
}

function findFirstWeekNeedingWorkers(weekly_workers: number[]): number {
   let value = -1;
   for (let i = weekly_workers.length; i >= 0; i--) {
      if (weekly_workers[i] > 0) {
         value = i;
      }
   }

   return value;
}

export function generateLinearPlan(
   duration: number,
   work_hours: number,
   total_estimated_hours: number,
   max_peak_workers: number | null,
   work_days: any,
   project: Project,
): any {
   const days_per_week = getWorkdaysPerWeek(work_days);
   const HOURS_PER_REQUEST = days_per_week * work_hours;

   const HOURS_PER_WEEK = total_estimated_hours / duration;

   // Minimum of 1 requrest per week
   const REQUESTS_PER_WEEK = Math.max(Math.round(HOURS_PER_WEEK / HOURS_PER_REQUEST), 1);
   if (max_peak_workers && REQUESTS_PER_WEEK > max_peak_workers) {
      throw new Error("Unable to generate linear curve");
   }
   // We are generating four request rows in a uniform distribution.
   // This approach is pending beta feedback from customers and
   // CSM.
   const weekly_workers = Math.max(Math.floor(REQUESTS_PER_WEEK / 3), 1);
   let planned_workers = 0;
   const requests = [];
   while (planned_workers < REQUESTS_PER_WEEK) {
      const remaining_available = REQUESTS_PER_WEEK - planned_workers;
      const request = {
         id: v4(),
         week_start: 1,
         week_end: duration,
         weekly_workers:
            remaining_available < weekly_workers ? remaining_available : weekly_workers,
         request_duration: duration,
         job_title: null,
         category: null,
         status: null,
      };

      planned_workers += request.weekly_workers;
      requests.push(request);
   }
   return addDatesToRequestsCurve(requests, project, work_days);
}

export function findAverageWorkersPerWeek(
   total_estimated_hours: number,
   duration: number,
   work_hours: number,
   work_days: any,
) {
   const days_per_week = getWorkdaysPerWeek(work_days);
   const HOURS_PER_REQUEST = days_per_week * work_hours;

   const HOURS_PER_WEEK = total_estimated_hours / duration;

   const REQUESTS_PER_WEEK = Math.round(HOURS_PER_WEEK / HOURS_PER_REQUEST);
   return REQUESTS_PER_WEEK;
}

// Error function https://en.wikipedia.org/wiki/Error_function#:~:text=When%20the%20results%20of%20a,%2Ba%2C%20for%20positive%20a.
function erf(x: number): number {
   const a1 = 0.254829592;
   const a2 = -0.284496736;
   const a3 = 1.421413741;
   const a4 = -1.453152027;
   const a5 = 1.061405429;
   const p = 0.3275911;

   const sign = x < 0 ? -1 : 1;
   x = Math.abs(x);

   const t = 1.0 / (1.0 + p * x);
   const y = (((a5 * t + a4) * t + a3) * t + a2) * t + a1;

   return sign * (1 - y * Math.exp(-x * x));
}

// Standard normal Cumulative Distribution Function
function standardNormalCDF(x: number): number {
   return 0.5 * (1 + erf(x / Math.sqrt(2)));
}

function bellCurvePercentage(x: number, mean: number, stdDev: number): number {
   const z = (x - mean) / stdDev;
   return standardNormalCDF(z);
}

function bellCurvePDF(x: number, mean: number, stdDev: number): number {
   const exponent = -0.5 * Math.pow((x - mean) / stdDev, 2);
   return (1 / (stdDev * Math.sqrt(2 * Math.PI))) * Math.exp(exponent);
}
