import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   H2,
   Page,
   Required,
   Token,
   Tooltip,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import React from "react";
import { useGetGroupOptionsQuery } from "../project/queries";
import type { FormikProps } from "formik";
import { SwitchField } from "./switch-field";
import { createPersonSchema } from "./validation-schemas";
import { PEOPLE_STATUS_OPTIONS } from "../../people-list/people-list-prop-types";
import { useGetJobTitles, useGetPermissionLevelOptions } from "../../common/queries/queries";
import { TwilioStoreCore } from "../../../../stores/twilio-store.core";
import type { ValidatePhoneNumberResponse } from "@laborchart-modules/lc-core-api/dist/api/twilio/validate-phone-number";
import { PersonStore } from "@/stores/person-store.core";
import { ErrorBanner } from "../../error-banner";
import type { CreatePersonFormValues } from "./prop-types";

/* istanbul ignore next */
async function createPerson(
   values: Omit<CreatePersonFormValues, "group_ids"> & {
      group_ids?: string[];
   },
) {
   const payload: any = {
      is_user: values.type.value === "user" || values.type.value === "assignable_user",
      is_assignable: values.type.value === "assignable_user" || values.type.value === "assignable",
      name: {
         first: values.first_name,
         last: values.last_name,
      },
      status: values.status.id,
      tag_instances: [],
      group_ids: values.group_ids ?? [],
      job_title_id: values.job_title ? values.job_title.id : null,
      notification_profile_id: null,
      permission_level_id: values.permission_level ? values.permission_level.id : null,
      custom_fields: {},
      can_receive_email: values.enable_email,
      can_receive_sms: values.enable_sms,
      email: values.email,
      phone: values.phone,
      employee_number: values.employee_id,
   };

   return await PersonStore.createPerson(payload).payload;
}

export function CreatePersonTearsheet(
   props: Readonly<{
      onClose: () => void;
      onPersonCreated: (projectId: string) => void;
   }>,
) {
   const I18n = useI18nContext();
   const [isError, setIsError] = React.useState<boolean>(false);
   const [errorMessage, setErrorMessage] = React.useState<string>("");
   const [phoneNumberValid, setPhoneNumberValid] = React.useState<boolean>(true);
   const { data: groups } = useGetGroupOptionsQuery();
   const { data: job_titles } = useGetJobTitles();
   const { data: permission_levels } = useGetPermissionLevelOptions();
   const validationSchema = createPersonSchema(I18n, phoneNumberValid);

   const initialValues: CreatePersonFormValues = {
      first_name: "",
      last_name: "",
      status: {
         id: "active",
         color: "green",
         label: I18n.t("views.company.workforce_planning.active"),
      },
      type: {
         id: 0,
         value: "assignable",
      },
      group_ids: [],
      job_title: null,
      employee_id: "",
      email: null,
      phone: null,
      enable_email: false,
      enable_sms: false,
      permission_level: null,
   };

   /* istanbul ignore next */
   async function handleSubmit(values: CreatePersonFormValues) {
      try {
         const selected_group_ids = values.group_ids.map((g: { id: string }) => g.id);
         const group_ids = selected_group_ids.includes("select_all")
            ? groups?.map((g: { id: string }) => g.id)
            : selected_group_ids;
         const response = await createPerson({ ...values, group_ids: group_ids });
         props.onPersonCreated(response.data.id);
      } catch (e: any) {
         setIsError(true);
         setErrorMessage(e.validation?.[0].message);
      }
   }

   async function validatePhoneNumber(number: string): Promise<ValidatePhoneNumberResponse> {
      return await TwilioStoreCore.validateNumber(number).payload;
   }

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Page.Header>
               <Page.Title>
                  <H1>{I18n.t("views.company.workforce_planning.people.create_person")}</H1>
               </Page.Title>
            </Page.Header>
            <Form
               view="create"
               initialValues={initialValues}
               validationSchema={validationSchema}
               onSubmit={handleSubmit}
            >
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({
                  submitForm,
                  isSubmitting,
                  setFieldValue,
                  values,
                  setFieldError,
                  setFieldTouched,
               }: FormikProps<any>) => (
                  <>
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <Card>
                           <Box padding="md">
                              <H2>{I18n.t("views.company.workforce_planning.information")}</H2>
                           </Box>

                           <Box padding="md">
                              {/* istanbul ignore next */}
                              {isError && (
                                 <ErrorBanner
                                    title="Error"
                                    content={
                                       errorMessage === "Email is already in use"
                                          ? I18n.t(
                                               "views.company.workforce_planning.people.validations.email_already_in_use",
                                            )
                                          : I18n.t(
                                               "views.company.workforce_planning.people.validations.generic_error",
                                            )
                                    }
                                 />
                              )}
                              <Form.Form>
                                 <Form.Row>
                                    <Form.Text
                                       colStart={1}
                                       colWidth={4}
                                       name="first_name"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.first_name",
                                       )}
                                       required
                                    />
                                    <Form.Text
                                       colStart={5}
                                       colWidth={4}
                                       name="last_name"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.last_name",
                                       )}
                                       required
                                    />
                                    <Form.PillSelect
                                       colStart={9}
                                       colWidth={4}
                                       name="status"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.status",
                                       )}
                                       required
                                       options={PEOPLE_STATUS_OPTIONS.map((status) => ({
                                          ...status,
                                          label: I18n.t(status.label),
                                       }))}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.MultiSelect
                                       colStart={1}
                                       colWidth={4}
                                       name="group_ids"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.groups",
                                       )}
                                       options={[
                                          {
                                             id: "select_all",
                                             label: I18n.t(
                                                "views.company.workforce_planning.select_all",
                                             ),
                                          },
                                       ].concat(groups ?? [])}
                                       required
                                       disabled={values.permission_level?.is_admin}
                                       tokenRenderer={({
                                          focused,
                                          disabled,
                                          getLabel,
                                          option,
                                          removeToken,
                                       }) => {
                                          return (
                                             <Tooltip overlay={getLabel(option)} trigger="hover">
                                                <Token disabled={disabled} focused={focused}>
                                                   <Token.Label>
                                                      {option.id == "select_all"
                                                         ? I18n.t(
                                                              "views.company.workforce_planning.all_groups",
                                                           )
                                                         : getLabel(option)}
                                                   </Token.Label>
                                                   <Token.Remove data-close onClick={removeToken} />
                                                </Token>
                                             </Tooltip>
                                          );
                                       }}
                                    />
                                    <Form.Select
                                       colStart={5}
                                       colWidth={4}
                                       name="job_title"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.job_title",
                                       )}
                                       options={job_titles}
                                    />
                                    <Form.Text
                                       colStart={9}
                                       colWidth={4}
                                       name="employee_id"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.employee_id",
                                       )}
                                    />
                                 </Form.Row>
                                 <Form.Row style={{ borderBottom: "1px solid #D6DADC" }}>
                                    <Form.RadioButtons
                                       colStart={1}
                                       colWidth={6}
                                       name="type"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.type",
                                       )}
                                       options={[
                                          {
                                             id: 0,
                                             label: (
                                                <Typography>
                                                   {`${I18n.t(
                                                      "views.company.workforce_planning.people.assignable",
                                                   )} `}
                                                   <i>
                                                      {I18n.t(
                                                         "views.company.workforce_planning.people.assignable_desc",
                                                      )}
                                                   </i>
                                                </Typography>
                                             ),
                                             value: "assignable",
                                          },
                                          {
                                             id: 1,
                                             label: (
                                                <Typography>
                                                   {`${I18n.t(
                                                      "views.company.workforce_planning.people.assignable_user",
                                                   )} `}
                                                   <i>
                                                      {I18n.t(
                                                         "views.company.workforce_planning.people.assignable_user_desc",
                                                      )}
                                                   </i>
                                                </Typography>
                                             ),
                                             value: "assignable_user",
                                          },
                                          {
                                             id: 2,
                                             label: (
                                                <Typography>
                                                   {`${I18n.t(
                                                      "views.company.workforce_planning.people.user",
                                                   )} `}
                                                   <i>
                                                      {I18n.t(
                                                         "views.company.workforce_planning.people.user_desc",
                                                      )}
                                                   </i>
                                                </Typography>
                                             ),
                                             value: "user",
                                          },
                                       ]}
                                    />
                                    <Form.Select
                                       colStart={7}
                                       colWidth={6}
                                       name="permission_level"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.permission_level",
                                       )}
                                       options={permission_levels}
                                       disabled={values.type.value === "assignable"}
                                       /* istanbul ignore next */
                                       onSelect={(e) => {
                                          const { item } = e;
                                          if (item.is_admin) {
                                             // Admins has access to all groups
                                             setFieldValue("group_ids", []);
                                          }
                                       }}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Text
                                       colStart={1}
                                       colWidth={6}
                                       name="email"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.email",
                                       )}
                                    />
                                    <Form.Text
                                       colStart={7}
                                       colWidth={6}
                                       name="phone"
                                       label={I18n.t(
                                          "views.company.workforce_planning.people.phone_number",
                                       )}
                                       onBlur={async (e) => {
                                          const value = e.target.value;
                                          setFieldTouched("phone", true, false);
                                          if (value) {
                                             const result = await validatePhoneNumber(value);
                                             if (
                                                result.data.formatted_number &&
                                                result.data.conflicts?.length === 0
                                             ) {
                                                setFieldValue(
                                                   "phone",
                                                   result.data.formatted_number,
                                                   false,
                                                );
                                                setFieldError("phone", undefined);
                                                setPhoneNumberValid(true);
                                             } else {
                                                const message =
                                                   result.data.formatted_number === null
                                                      ? I18n.t(
                                                           "views.company.workforce_planning.people.validations.invalid_phone_number",
                                                        )
                                                      : I18n.t(
                                                           "views.company.workforce_planning.people.validations.phone_conflict",
                                                        );
                                                setFieldError("phone", message);
                                                setPhoneNumberValid(false);
                                             }
                                          } else {
                                             setFieldError("phone", undefined);
                                             setPhoneNumberValid(true);
                                          }
                                       }}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Field
                                       as={SwitchField}
                                       name="enable_email"
                                       colStart={1}
                                       colWidth={6}
                                    />
                                    <Form.Field
                                       as={SwitchField}
                                       name="enable_sms"
                                       colStart={7}
                                       colWidth={6}
                                    />
                                 </Form.Row>
                              </Form.Form>
                           </Box>
                        </Card>
                     </Page.Body>
                     <Page.Footer>
                        <Box padding="md">
                           <Flex justifyContent="space-between" alignItems="center">
                              <Required showLabel />
                              <FlexList space="sm">
                                 <Button
                                    variant="tertiary"
                                    disabled={isSubmitting}
                                    onClick={props.onClose}
                                 >
                                    {I18n.t("views.company.workforce_planning.cancel")}
                                 </Button>
                                 <Button type="submit" disabled={isSubmitting} onClick={submitForm}>
                                    {I18n.t("views.company.workforce_planning.create")}
                                 </Button>
                              </FlexList>
                           </Flex>
                        </Box>
                     </Page.Footer>
                  </>
               )}
            </Form>
         </Page.Main>
      </Page>
   );
}
