import { Trash } from "@procore/core-icons";
import {
   Button,
   H3,
   Modal,
   Spinner,
   Tooltip,
   Typography,
   useI18nContext,
   ZIndexProvider,
} from "@procore/core-react";
import type { TableApi } from "@procore/data-table";
import { useRowSelectionState } from "@procore/data-table";
import React, { useEffect, useState } from "react";
import { useToastAlertContext } from "@procore/toast-alert";
import { PersonStore } from "@/stores/person-store.core";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";

export type ConfirmDeleteModalPeoplePropTypes = {
   successText: string;
   deleteText: string;
   headerText: string;
   tooltipText?: string;
   id?: string;
   isBulk?: boolean;
   tableApi?: TableApi;
};

export const ConfirmDeletePeopleModal = (props: ConfirmDeleteModalPeoplePropTypes) => {
   const { isBulk = false, tooltipText, id, tableApi, headerText, deleteText, successText } = props;
   const I18n = useI18nContext();
   const [open, setOpen] = React.useState(false);
   const [loading, setLoading] = React.useState(false);
   const [itHasAdmin, setItHasAdmin] = useState(false);
   const { showToast } = useToastAlertContext();
   const selectedRows = useRowSelectionState().map((x: any) => x.data);
   const count = isBulk ? selectedRows.length : 1;
   const { checkAuthAction } = usePermissionContext();
   const [canDeletePeopleState, setCanDeletePeopleState] = useState(false);

   useEffect(() => {
      const canDetelePeople = checkAuthAction(AuthAction.DELETE_PEOPLE);
      setCanDeletePeopleState(canDetelePeople);
   }, [checkAuthAction]);

   useEffect(() => {
      if (selectedRows.length > 0) {
         const adminCount = selectedRows.filter((user) => user.permission === "Admin").length;
         adminCount === 1 && canDeletePeopleState && setItHasAdmin(false);
         setItHasAdmin(adminCount >= 1 || (adminCount === 1 && selectedRows.length > 1));
      }
   }, [selectedRows, canDeletePeopleState]);

   const handleDelete = async () => {
      try {
         setLoading(true);
         if (isBulk) {
            const ids = selectedRows?.map((x: any) => x.id);
            await PersonStore.batchDelete({ ids: ids }).stream;
            tableApi?.deselectAll();
         } else {
            await PersonStore.deletePerson(id!).payload;
         }
         tableApi?.refreshServerSide({});
         showToast.success(isBulk ? I18n.t(successText, { count }) : I18n.t(successText));
      } catch {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      } finally {
         setLoading(false);
         setOpen(false);
      }
   };

   return (
      <>
         {tooltipText ? (
            <ZIndexProvider>
               <Tooltip
                  overlay={I18n.t(tooltipText)}
                  placement="left"
                  trigger={itHasAdmin ? "hover" : "none"}
               >
                  <div>
                     <Button
                        icon={<Trash />}
                        onClick={() => setOpen(true)}
                        variant="tertiary"
                        data-testid="trash-btn"
                        disabled={itHasAdmin}
                     />
                  </div>
               </Tooltip>
            </ZIndexProvider>
         ) : (
            <Button
               icon={<Trash />}
               onClick={() => setOpen(true)}
               variant="tertiary"
               data-testid="trash-btn"
               disabled={itHasAdmin}
            />
         )}

         <Modal open={open} onClose={() => setOpen(false)} width="md">
            <Modal.Header>{isBulk ? I18n.t(headerText) : I18n.t(headerText)}</Modal.Header>
            <Spinner loading={loading}>
               <Modal.Body>
                  <Typography>
                     {selectedRows.length > 1
                        ? I18n.t(deleteText, { count })
                        : I18n.t(
                             "views.company.workforce_planning.people.modals.delete.description.one",
                          )}
                     <H3>
                        {I18n.t(
                           "views.company.workforce_planning.people.modals.delete.description.highlighted_text",
                        )}
                     </H3>
                  </Typography>
               </Modal.Body>
               <Modal.Footer>
                  <Modal.FooterButtons>
                     <Button variant="secondary" onClick={() => setOpen(false)}>
                        {I18n.t("views.company.workforce_planning.cancel")}
                     </Button>
                     <Button variant="primary" onClick={handleDelete}>
                        {I18n.t("views.company.workforce_planning.delete")}
                     </Button>
                  </Modal.FooterButtons>
               </Modal.Footer>
            </Spinner>
         </Modal>
      </>
   );
};
