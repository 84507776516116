import React from "react";
import { Button, Modal, useI18nContext, Typography } from "@procore/core-react";

export type FileDeleteModalProps = {
   open: boolean;
   onClose: () => void;
   handleDelete: () => void | Promise<void>;
   removeText?: string;
};

export const FileDeleteModal: React.FC<FileDeleteModalProps> = ({
   open,
   onClose,
   handleDelete,
   removeText = "views.company.workforce_planning.remove_file",
}) => {
   const I18n = useI18nContext();

   return (
      <Modal open={open} onClose={onClose} width="sm">
         <Modal.Header className="tags-file-download-modal-center">
            {I18n.t("views.company.workforce_planning.confirm_deletion")}
         </Modal.Header>

         <Modal.Body className="tags-file-download-modal-center">
            <Typography style={{ fontSize: "14px" }}>
               {I18n.t("views.company.workforce_planning.remove_file_message")}
            </Typography>
         </Modal.Body>
         <Modal.Footer>
            <Modal.FooterButtons>
               <Button variant="secondary" onClick={onClose}>
                  {I18n.t("views.company.workforce_planning.cancel")}
               </Button>
               <Button variant="primary" onClick={handleDelete}>
                  {I18n.t(removeText)}
               </Button>
            </Modal.FooterButtons>
         </Modal.Footer>
      </Modal>
   );
};
