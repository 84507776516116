import type { Dispatch, SetStateAction } from "react";
import type { GanttCustomFieldFilter, ganttFilterType } from "../prop-types";
import { ganttFilterTypeEnums } from "../prop-types";
import React from "react";
import {
   Checkbox,
   DateSelect,
   FlexList,
   Form,
   MultiSelect,
   NumberInput,
   Select,
   useI18nContext,
} from "@procore/core-react";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import { getDetachedDay, getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { TokenRenderer } from "../gantt-filter-token-renderer";
import { dateClassifiers, filterClassifiers } from "@/react/shared/constants";
import {
   deleteFilter,
   getPreSelected,
   isDuplicate,
   setClassifierFromValue,
   updateGanttFilter,
} from "./gantt-custom-filter-helper";

export type GanttCustomFilterComponentProps = {
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
   filter: any;
   ganttFilter: ganttFilterType;
};

export const GanttCustomFilter = (props: GanttCustomFilterComponentProps) => {
   const { setGanttFilter, filter, ganttFilter } = props;
   const [selected, setSelected] = React.useState<boolean[]>([]);
   const [options, setOptions] = React.useState<any[]>([]);
   const I18n = useI18nContext();
   const [classifier, setClassifier] = React.useState<
      undefined | { id: number; label: string; value: string }
   >();
   const [value, setValue] = React.useState<any[]>([]);

   const [numberValue, setNumberValue] = React.useState<number | null>(null);
   const [textValue, setTextValue] = React.useState<string | undefined>(undefined);
   const [dateValue, setDateValue] = React.useState<number | undefined>(undefined);

   React.useEffect(() => {
      switch (filter.type) {
         case CustomFieldType.BOOL: {
            const preSelected = getPreSelected(
               ganttFilter,
               filter.id,
               (x: { value: boolean }) => x.value,
            );
            setSelected(preSelected);
            break;
         }

         case CustomFieldType.MULTI_SELECT:
         case CustomFieldType.SELECT: {
            const preSelected = getPreSelected(ganttFilter, filter.id, (x: { value: string[] }) =>
               filter.type === CustomFieldType.MULTI_SELECT
                  ? x.value.map((val) => ({ key: val, name: val }))
                  : [{ key: x.value, name: x.value }],
            );
            setValue(preSelected);
            break;
         }

         case CustomFieldType.NUMBER:
         case CustomFieldType.CURRENCY: {
            const preSelected = getPreSelected(
               ganttFilter,
               filter.id,
               (x: { value: number }) => x.value,
            );
            setNumberValue(preSelected[0]);

            const preClassifier = getPreSelected(
               ganttFilter,
               filter.id,
               (x: { classifier: { value: string } }) => x.classifier,
            );
            const selectedClassifier = setClassifierFromValue(
               filterClassifiers,
               preClassifier[0],
               I18n,
            );
            if (selectedClassifier) setClassifier(selectedClassifier);

            break;
         }

         case CustomFieldType.TEXT: {
            const preSelected = getPreSelected(
               ganttFilter,
               filter.id,
               (x: { value: string }) => x.value,
            );
            setTextValue(preSelected[0]);
            break;
         }

         case CustomFieldType.DATE: {
            const preSelected = getPreSelected(
               ganttFilter,
               filter.id,
               (x: { value: string }) => x.value,
            );
            const preClassifier = getPreSelected(
               ganttFilter,
               filter.id,
               (x: { classifier: { value: string } }) => x.classifier,
            );

            const selectedClassifier = setClassifierFromValue(
               dateClassifiers,
               preClassifier[0],
               I18n,
            );
            if (selectedClassifier) setClassifier(selectedClassifier);
            setDateValue(preSelected[0]);
            break;
         }
      }
   }, [ganttFilter]);

   React.useEffect(() => {
      if (filter.type === CustomFieldType.MULTI_SELECT || filter.type === CustomFieldType.SELECT) {
         const options = filter.values.map((value: any) => ({ key: value, name: value }));
         setOptions(options);
      }
   }, [filter]);

   function handleOnchange(filter: any, value: any) {
      const currentFilters = ganttFilter[ganttFilterTypeEnums.customFieldsFilters] || {};

      let newFilter: GanttCustomFieldFilter = {
         property: "custom_fields",
         type: filter.type,
         negation: false,
         filterName: filter.id,
         customFieldId: filter.id,
      };
      switch (filter.type) {
         case CustomFieldType.BOOL: {
            delete filter.selected;
            const newSelected = [...selected];
            const index = newSelected.indexOf(value);
            if (index > -1) {
               newSelected.splice(index, 1); // Remove if already selected
            } else {
               newSelected.push(value); // Add if not selected
            }
            setSelected(newSelected);
            if (newSelected.length === 0) {
               deleteFilter(currentFilters, filter, setGanttFilter);
               return;
            }

            if (newSelected.length != 2) {
               // If both values are not selected, remove the existing filter
               delete currentFilters[filter.id];
               filter.selected = newSelected[0]; // Set the selected value to the filter
            }
            if (newSelected.length === 2) {
               // If both values are selected,
               filter.selected = value; // Set the selected value to the filter
            }

            newFilter = {
               ...newFilter,
               value: filter.selected,
            };

            // Update the filters by checking for duplicates
            const updatedFilters = {
               ...currentFilters,
               [filter.id]: [
                  ...(currentFilters[filter.id] || []),
                  ...(!isDuplicate(currentFilters, newFilter, filter) ? [newFilter] : []),
               ],
            };
            updateGanttFilter(updatedFilters, setGanttFilter);

            break;
         }
         case CustomFieldType.MULTI_SELECT:
         case CustomFieldType.SELECT: {
            setValue(value);
            if (value.length === 0) {
               deleteFilter(currentFilters, filter, setGanttFilter);
               return;
            }

            filter.selected = currentFilters[filter.id]
               ? value
                    .map((x: { key: any }) => x.key) // Extract the keys
                    .filter(
                       (key: any) =>
                          currentFilters[filter.id]?.every(
                             (y: { value: any[] }) => !y.value.includes(key),
                          ), // Ensure key is not in any of the arrays
                    )
               : value.map((x: { key: any }) => x.key);

            const newSelectFilter: any = [];
            if (filter.selected.length === 0) {
               delete currentFilters[filter.id];
               if (value.length > 0) {
                  value.forEach((val: { key: any }) => {
                     newSelectFilter.push({
                        property: "custom_fields",
                        type: filter.type,
                        negation: false,
                        filterName: filter.id,
                        value: filter.type === CustomFieldType.MULTI_SELECT ? [val.key] : val.key,
                        customFieldId: filter.id,
                     });
                  });
               }
            } else {
               newSelectFilter.push({
                  property: "custom_fields",
                  type: filter.type,
                  negation: false,
                  filterName: filter.id,
                  value:
                     filter.type === CustomFieldType.MULTI_SELECT
                        ? filter.selected
                        : filter.selected[0],
                  customFieldId: filter.id,
               });
            }

            const updatedFilters = {
               ...currentFilters,
               [filter.id]: [
                  ...(currentFilters[filter.id] || []),
                  ...newSelectFilter.filter(
                     (key: any) => !isDuplicate(currentFilters, key, filter),
                  ),
               ],
            };

            updateGanttFilter(updatedFilters, setGanttFilter);
            break;
         }
         case CustomFieldType.NUMBER:
         case CustomFieldType.CURRENCY: {
            if (value.parsedNumber === null) {
               deleteFilter(currentFilters, filter, setGanttFilter);
               return;
            }
            setNumberValue(value.parsedNumber);
            newFilter = {
               ...newFilter,
               value: value.value,
               classifier: classifier?.value,
            };
            const updatedFilters = {
               ...currentFilters,
               [filter.id]: [newFilter],
            };
            updateGanttFilter(updatedFilters, setGanttFilter);

            break;
         }
         case CustomFieldType.DATE: {
            if (!value) {
               deleteFilter(currentFilters, filter, setGanttFilter);
               return;
            }
            const selectedDate = getDetachedDay(value);
            setDateValue(selectedDate);
            newFilter = {
               ...newFilter,
               value: selectedDate,
               classifier: classifier?.value,
            };
            const updatedFilters = {
               ...currentFilters,
               [filter.id]: [newFilter],
            };
            updateGanttFilter(updatedFilters, setGanttFilter);
            break;
         }
      }
   }
   function handleClassifierSelect(filter: any, selection: any) {
      const selected =
         filter.type === CustomFieldType.DATE
            ? dateClassifiers.find((x) => x.value == selection.item)
            : filterClassifiers.find((x) => x.value == selection.item);
      if (selected) {
         //a shallow copy of the selected object to avoid mutating the original object
         const selectedCopy = { ...selected };
         selectedCopy.label = I18n.t(selectedCopy.label);
         setClassifier(selectedCopy);
      } else {
         setClassifier(undefined);
      }
      switch (filter.type) {
         case CustomFieldType.NUMBER:
         case CustomFieldType.CURRENCY:
            setNumberValue(null);
            break;
         case CustomFieldType.DATE:
            setDateValue(undefined);
            break;
      }
   }
   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const currentFilters = ganttFilter[ganttFilterTypeEnums.customFieldsFilters] || {};
      if (value) {
         setTextValue(value);
         const textFilter = {
            property: "custom_fields",
            type: filter.type,
            negation: false,
            filterName: filter.id,
            value: value,
            customFieldId: filter.id,
         };
         const updatedFilters = {
            ...currentFilters,
            [filter.id]: [textFilter],
         };
         updateGanttFilter(updatedFilters, setGanttFilter);
      } else {
         setTextValue("");
         deleteFilter(currentFilters, filter, setGanttFilter);
      }
   };
   return (
      <>
         {filter.type === CustomFieldType.BOOL && (
            <div className="ganttFilterLabel">
               <h3 className="filterName">{filter.name}</h3>
               <Checkbox
                  checked={selected.includes(true)}
                  onChange={() => handleOnchange(filter, true)}
               >
                  {I18n.t("views.company.workforce_planning.boolean_values.yes_value")}
               </Checkbox>
               <Checkbox
                  checked={selected.includes(false)}
                  onChange={() => handleOnchange(filter, false)}
               >
                  {I18n.t("views.company.workforce_planning.boolean_values.no_value")}
               </Checkbox>
            </div>
         )}
         {(filter.type === CustomFieldType.MULTI_SELECT ||
            filter.type === CustomFieldType.SELECT) && (
            <div className="ganttFilterLabel">
               <h3 className="filterName">{filter.name}</h3>
               <MultiSelect
                  getId={(item) => item.key}
                  getLabel={(item) => item.name}
                  onChange={(value) => {
                     return handleOnchange(filter, value);
                  }}
                  options={options}
                  value={value}
                  className="ganttFilterMultiselect"
                  tokenRenderer={TokenRenderer}
                  data-testid="multi-select-filter"
               />
            </div>
         )}
         {(filter.type === CustomFieldType.NUMBER || filter.type == CustomFieldType.CURRENCY) && (
            <div className="ganttFilterLabel">
               <h3 className="filterName">{filter.name}</h3>
               <FlexList>
                  <Select
                     label={classifier?.label}
                     onSelect={(value) => {
                        return handleClassifierSelect(filter, value);
                     }}
                     placeholder={I18n.t(
                        "views.company.workforce_planning.classifiers.placeholder",
                     )}
                     style={{ width: "190px" }}
                  >
                     {filterClassifiers.map((c) => (
                        <Select.Option
                           key={c.id}
                           value={c.value}
                           selected={c.value === classifier?.value}
                        >
                           {I18n.t(c.label)}
                        </Select.Option>
                     ))}
                  </Select>
                  <NumberInput
                     disabled={!classifier}
                     onChange={(value) => {
                        return handleOnchange(filter, value);
                     }}
                     value={numberValue ?? null}
                     title="numeric-value-filter"
                     placeholder={I18n.t(
                        "views.company.workforce_planning.filters.value_placeholder",
                     )}
                  />
               </FlexList>
            </div>
         )}
         {filter.type === CustomFieldType.TEXT && (
            <div className="ganttFilterLabel">
               <h3 className="filterName">{filter.name}</h3>
               <FlexList>
                  <Form>
                     <Form.Row>
                        <Form.Text
                           name="inputVal"
                           colStart={1}
                           colWidth={12}
                           value={textValue}
                           onChange={handleChange}
                           placeholder={I18n.t(
                              "views.company.workforce_planning.filters.value_placeholder",
                           )}
                           style={{ marginTop: "-15px", width: "100%" }}
                           data-testid="text-filter"
                        />
                     </Form.Row>
                  </Form>
               </FlexList>
            </div>
         )}
         {filter.type === CustomFieldType.DATE && (
            <div className="ganttFilterLabel">
               <h3 className="filterName">{filter.name}</h3>
               <FlexList>
                  <Select
                     style={{ width: "190px" }}
                     label={classifier?.label}
                     onSelect={(value) => {
                        return handleClassifierSelect(filter, value);
                     }}
                     placeholder={I18n.t(
                        "views.company.workforce_planning.classifiers.placeholder",
                     )}
                  >
                     {dateClassifiers.map((c) => (
                        <Select.Option
                           key={c.id}
                           value={c.value}
                           selected={c.value === classifier?.value}
                        >
                           {I18n.t(c.label)}
                        </Select.Option>
                     ))}
                  </Select>
                  <DateSelect
                     disabled={!classifier}
                     value={dateValue ? getAttachedDate(dateValue) : undefined}
                     onChange={(date) => handleOnchange(filter, date)}
                  />
               </FlexList>
            </div>
         )}
      </>
   );
};
