import React, { useState } from "react";
import { Box, Card, Flex, Spinner, useI18nContext } from "@procore/core-react";
import type { SelectValue } from "@/react/prop-types";
import { useGetUpcomingTimeOff } from "../../common/queries/queries";
import type { DetailsTimeOffCardProps, ListTimeOffProps } from "./types";
import type {
   ColumnDefinition,
   DataTableCellRendererProps,
   DateCellColumnDefinition,
   SelectCellColumnDefinition,
   TableApi,
} from "@procore/data-table";
import {
   ClientSideDataTable,
   DateCellEditor,
   DateCellRenderer,
   SelectCellEditor,
   SelectCellRenderer,
} from "@procore/data-table";
import { DateFilter } from "../../data-table/custom-filters/date-filter";
import { timeOffReasons } from "./constants";
import { DateUtils } from "@/lib/utils/date";
import { EmptyState } from "../../empty-state/empty-state";
import { updateTimeOff } from "../../time-off-list/time-off-list-helpers";
import { timeOptions } from "@/lib/utils/timezones";
import { Delete, Edit, getTimeOffTypeOptions } from "../../time-off-list/time-off-list-utils";
import type { SerializedFindTimeOff } from "@laborchart-modules/lc-core-api/dist/api/time-off/find-time-off";

export const formatTimeOffData = (
   timeOffData: SerializedFindTimeOff[],
   timeOffTypeOptions: Array<{
      id: number;
      value: string;
      label: string;
      color: string;
   }>,
) => {
   return timeOffData?.map((timeOff) => ({
      id: timeOff.id,
      start_date: timeOff.start_day ? DateUtils.getAttachedDate(timeOff.start_day) : "",
      end_date: timeOff.end_day ? DateUtils.getAttachedDate(timeOff.end_day) : "",
      reason: timeOffReasons.find(({ value }) => value === timeOff.reason),
      daily_start_time: timeOptions.find(({ id }) => id === timeOff.batch_start_time)!.label,
      daily_end_time: timeOptions.find(({ id }) => id === timeOff.batch_end_time)!.label,
      repeat: timeOff.repeat,
      occurences: timeOff.occurrences_count,
      person_name: timeOff.person_name,
      cadence: timeOff.cadence,
      repeat_end_day: timeOff.repeat_end_day,
      type: timeOff.is_paid ? timeOffTypeOptions[0] : timeOffTypeOptions[1],
      apply_to_saturday: timeOff.apply_to_saturday,
      apply_to_sunday: timeOff.apply_to_sunday,
   }));
};

const ListTimeOffCard: React.FC<ListTimeOffProps> = ({ timeOffData, refetchTimeOffData }) => {
   const I18n = useI18nContext();

   const timeOffTypeOptions = getTimeOffTypeOptions(I18n);

   const [tableApi, setTableApi] = useState<TableApi>();
   const handleTableReady = (api: TableApi) => setTableApi(api);
   /* istanbul ignore next */
   const formattedTimeOffData = React.useMemo(() => {
      return formatTimeOffData(timeOffData ?? [], timeOffTypeOptions);
   }, [timeOffData]);

   /* istanbul ignore next */
   const startDateColumn: DateCellColumnDefinition = {
      field: "start_date",
      headerName: I18n.t("views.company.workforce_planning.start_date"),
      cellRenderer: DateCellRenderer,
      cellEditor: DateCellEditor,
      filterRenderer: DateFilter as any,
      flex: 8,
      minWidth: 150,
   };
   /* istanbul ignore next */
   const endDateColumn: DateCellColumnDefinition = {
      field: "end_date",
      headerName: I18n.t("views.company.workforce_planning.end_date"),
      cellRenderer: DateCellRenderer,
      cellEditor: DateCellEditor,
      filterRenderer: DateFilter as any,
      flex: 8,
      minWidth: 150,
   };
   /* istanbul ignore next */
   const reasonColumn: SelectCellColumnDefinition = {
      field: "reason",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.time_off.reason"),
      flex: 8,
      minWidth: 150,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => timeOffReasons,
         getId: (item: SelectValue<any>) => item?.value,
      },
      getStringFormattedValue(item) {
         /* istanbul ignore next */
         const snake_case_value = item.value.split(" ").join("_");
         return I18n.t(`views.company.workforce_planning.time_off.reasons.${snake_case_value}`);
      },
      comparator: (itemA, itemB) => itemA.value.localeCompare(itemB.value),
   };
   /* istanbul ignore next */
   const timeOffColumnDefinitions: ColumnDefinition[] = [
      startDateColumn,
      endDateColumn,
      reasonColumn,
   ];
   /* istanbul ignore next */
   const getEmptyStateRenderer = () => {
      //These translations will get replaced when the Add Time Off feature is implemented
      const title = I18n.t(
         "views.company.workforce_planning.people_details_timeoff.time_off_empty_state_description",
      );
      const description = I18n.t(
         "views.company.workforce_planning.people_details_timeoff.time_off_empty_state_help_text",
      );

      return <EmptyState title={title} description={description} compact />;
   };
   /* istanbul ignore next */
   return (
      <Box padding="sm" marginBottom="sm">
         <ClientSideDataTable columnDefinitions={timeOffColumnDefinitions}>
            <Flex
               style={{
                  height: "100%",
                  width: "100%",
               }}
               alignItems="stretch"
            >
               <Box
                  flex="1"
                  display="flex-column"
                  alignItems="stretch"
                  style={{
                     height: "400px",
                     width: "100%",
                  }}
               >
                  <ClientSideDataTable.Table
                     onTableReady={handleTableReady}
                     rows={formattedTimeOffData || []}
                     pagination={true}
                     paginationPageSize={100}
                     emptyStateRenderer={getEmptyStateRenderer}
                     rowActions={[
                        (props: DataTableCellRendererProps) => (
                           <Edit
                              {...props}
                              tableApi={tableApi as TableApi}
                              updateTimeOff={updateTimeOff}
                              refetchTimeOffData={refetchTimeOffData}
                           />
                        ),
                        (props: DataTableCellRendererProps) => (
                           <Delete
                              {...props}
                              tableApi={tableApi as TableApi}
                              refetchTimeOffData={refetchTimeOffData}
                           />
                        ),
                     ]}
                     suppressColumnVirtualisation={false}
                     rowActionsConfig={{
                        sortable: false,
                     }}
                  />
               </Box>
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ClientSideDataTable.ContextPanel style={{ marginLeft: "16px" }} />
               </Flex>
            </Flex>
         </ClientSideDataTable>
      </Box>
   );
};

export const DetailsTimeOffCard: React.FC<DetailsTimeOffCardProps> = ({
   loading: DataLoading,
   contextId,
   pageType,
}) => {
   const {
      scheduledTimeOff,
      isLoading: isScheduledTimeOff,
      refetch,
   } = useGetUpcomingTimeOff(contextId, pageType);

   return (
      <Card style={{ marginBottom: "5px", height: "100%" }}>
         <Spinner loading={DataLoading}>
            {!isScheduledTimeOff && (
               <ListTimeOffCard
                  timeOffData={scheduledTimeOff}
                  showLoader={isScheduledTimeOff}
                  pageType={pageType}
                  refetchTimeOffData={refetch}
               />
            )}
         </Spinner>
      </Card>
   );
};
