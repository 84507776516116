import React from "react";
import { LinkToProcoreEntity } from "../link-to-procore-entity/link-to-procore-entity";
import type { LinkToProcoreEntityArgs } from "./types";

export const renderLinkToProcoreEntity = ({
   entityType,
   procoreEntityId,
   procoreCompanyId,
}: LinkToProcoreEntityArgs) => {
   // Check if procoreEntityId and procoreCompanyId are available
   if (procoreEntityId && procoreCompanyId) {
      return (
         <LinkToProcoreEntity
            entityType={entityType}
            procoreEntityId={String(procoreEntityId)}
            procoreCompanyId={procoreCompanyId}
         />
      );
   }
   return null;
};
