import { ActionMode } from "@/react/prop-types";
import {
   useI18nContext,
   Box,
   Grid,
   H3,
   Spinner,
   Button,
   Required,
   Checkbox,
   TextArea,
   FlexList,
   H2,
   Title,
} from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { FileUpload } from "@/react/shared/components/file-upload";
import styles from "../project/project-detail-cards/project-details.scss";
import classnames from "classnames";
import type { CreateNotePayload } from "@laborchart-modules/lc-core-api/dist/api/notes/create-note";
import { NotesType, type AddNotesModalProps } from "./types";
import { dateSortOptions } from "@/react/shared/constants";
import { NotesToolbar } from "@/react/shared/components/notes-toolbar";
import { getUniqueAttachmentIds } from "../helper";
const cx = classnames.bind(styles);

export const AddNotes = ({
   onClose,
   handleCreateUpdate,
   showLoader,
   mode = ActionMode.CREATE,
   initialValues,
   showToolBar,
   canEditNotes,
   handleDelete = async () => {}, // Default to a no-op async function
   noteType,
}: AddNotesModalProps) => {
   const I18n = useI18nContext();
   const [attachments, setAttachments] = useState<any[]>([]);
   const [deletedAttachmentIds, setDeletedAttachmentIds] = useState<string[]>([]);
   const [loading, setLoading] = useState(false);
   const [isPrivate, setIsPrivate] = useState(false);
   const [note, setNote] = useState<string>("");
   const [noteId, setNoteId] = useState<string>("");

   useEffect(() => {
      if (initialValues) {
         setAttachments(initialValues.attachments);
         setIsPrivate(initialValues.is_private);
         setNote(initialValues.content);
         setNoteId(initialValues.id);
      }
   }, [initialValues]);

   const handleClose = () => {
      onClose();
      setAttachments([]);
      setDeletedAttachmentIds([]);
   };

   const handleNoteDeletion = async () => {
      await handleDelete(noteId);
      handleClose();
   };

   const handleNotesCreateUpdate = async () => {
      const payload: CreateNotePayload = {
         content: note,
         is_private: isPrivate,
         attachment_ids: getUniqueAttachmentIds(attachments, deletedAttachmentIds),
      };
      await handleCreateUpdate(payload, noteId);
      handleClose();
   };
   // title is same for project and people
   const deleteNoteTitle =
      "views.company.workforce_planning.project_details_notes.delete_note_title";

   // description is different for project and people
   const deleteNoteDescription =
      noteType === NotesType.PROJECT
         ? "views.company.workforce_planning.project_details_notes.delete_note_description"
         : "views.company.workforce_planning.people_details_notes.delete_note_description";

   // private text is same for project and people
   const privateText = "views.company.workforce_planning.project_details_notes.private";
   // title is same for project and people
   const notesTitleText = "views.company.workforce_planning.project_details_notes.title";
   return (
      <>
         <Box hidden={mode !== ActionMode.DELETE}>
            <FlexList justifyContent="space-between">
               <Title>
                  <Title.Text>
                     <H2>{I18n.t(deleteNoteTitle)}</H2>
                  </Title.Text>
                  <Title.Subtext>{I18n.t(deleteNoteDescription)}</Title.Subtext>
               </Title>
            </FlexList>
            <Box padding="lg" />
            <Box
               display="flex"
               justifyContent="flex-end"
               alignItems="center"
               style={{ width: "100%" }}
            >
               <Button
                  variant="secondary"
                  onClick={handleClose}
                  style={{ marginRight: "10px" }}
                  disabled={loading}
               >
                  {I18n.t("views.company.workforce_planning.cancel")}
               </Button>

               <Button
                  variant="primary"
                  onClick={handleNoteDeletion}
                  style={{ width: !showLoader ? "auto" : "70px" }}
               >
                  {!showLoader ? (
                     I18n.t("views.company.workforce_planning.delete")
                  ) : (
                     <Spinner loading={true} size="md" color="white" />
                  )}
               </Button>
            </Box>
         </Box>

         {mode !== ActionMode.DELETE && showToolBar && (
            <Box padding="sm">
               <NotesToolbar
                  sortOptions={dateSortOptions}
                  selectedSortOption={dateSortOptions[1]}
                  isAddNoteDisabled={true}
                  canEditNotes={canEditNotes}
               />
            </Box>
         )}

         {mode !== ActionMode.DELETE && (
            <Box
               padding={mode === ActionMode.CREATE ? "md" : ""}
               className={mode === ActionMode.CREATE ? cx("custom-box") : ""}
            >
               <Grid>
                  <Grid.Row>
                     <Grid.Col colWidth={6}>
                        <Checkbox
                           checked={isPrivate}
                           onChange={() => {
                              setIsPrivate(!isPrivate);
                           }}
                           data-testid="notes-private-checkbox"
                        >
                           {I18n.t(privateText)}
                        </Checkbox>
                     </Grid.Col>
                  </Grid.Row>
               </Grid>
               <Grid style={{ marginTop: "20px" }}>
                  <Grid.Row>
                     <Grid.Col colWidth={6}>
                        <H3>
                           {I18n.t(notesTitleText)}
                           <Required />
                        </H3>
                     </Grid.Col>
                     <Grid.Col colWidth={6}>
                        <H3>
                           {I18n.t(
                              "views.company.workforce_planning.details_tags.tags_attachments_label",
                           )}
                        </H3>
                     </Grid.Col>
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: "5px" }}>
                     <Grid.Col colWidth={6}>
                        <TextArea
                           style={{ width: "95%", height: "200px" }}
                           value={note}
                           onChange={(e) => setNote(e.target.value)}
                           data-testid="notes-textarea"
                        />
                     </Grid.Col>

                     <Grid.Col colWidth={6}>
                        <Box>
                           <FileUpload
                              attachments={attachments}
                              setAttachments={setAttachments}
                              deletedAttachmentIds={deletedAttachmentIds}
                              setDeletedAttachmentIds={setDeletedAttachmentIds}
                              loading={loading}
                              setLoading={setLoading}
                           />
                        </Box>
                     </Grid.Col>
                  </Grid.Row>
               </Grid>
               <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: "100%" }}
                  padding="sm"
               >
                  <Required showLabel />
                  <Box>
                     <Button
                        variant="secondary"
                        onClick={handleClose}
                        style={{ marginRight: "10px" }}
                        disabled={loading}
                     >
                        {I18n.t("views.company.workforce_planning.cancel")}
                     </Button>

                     <Button
                        variant="primary"
                        onClick={handleNotesCreateUpdate}
                        disabled={loading || !note}
                        style={{ width: !showLoader ? "auto" : "70px" }}
                     >
                        {!showLoader ? (
                           I18n.t("views.company.workforce_planning.save")
                        ) : (
                           <Spinner loading={true} size="md" color="white" />
                        )}
                     </Button>
                  </Box>
               </Box>
            </Box>
         )}
         <Box padding="xxs" />
      </>
   );
};
