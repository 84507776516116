import type { ProjectDetailFormValues, ProjectRoleData, ProjectWageOverrideData } from "./types";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { DateUtils } from "@/lib/utils/date";
import { months } from "@/react/shared/constants";
import { v4 } from "uuid";
import type { CannedMessage } from "@laborchart-modules/common";
import { stripAndReturnHTMLTokens } from "../../communications/dynamic-tokens/token-utils";
import type { CannedMessageType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { ResourceName } from "@/react/prop-types";
import type { ExtendedGetDetailResponse } from "../types";

export function compareDates({
   startDate,
   endDate,
}: {
   startDate: Date;
   endDate: ProjectDetailFormValues["est_end_date"] | undefined;
}) {
   if (!startDate) {
      return true;
   }
   const formattedStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
   );
   if (endDate) {
      const formattedEndDate = new Date(
         endDate.getFullYear(),
         endDate.getMonth(),
         endDate.getDate(),
      );
      if (formattedEndDate.getTime() === formattedStartDate.getTime()) {
         return true;
      }
      return formattedEndDate.getTime() > formattedStartDate.getTime();
   }
   return true;
}

export function prepareOverridePayload(selectedOverrideId: string, wage: number | null) {
   const payload: ProjectWageOverrideData = {
      wage_overrides: {
         [selectedOverrideId]: wage,
      },
   };
   return payload;
}

export function formatFullDateTime(timestamp: number): string {
   if (isNaN(timestamp) || timestamp < 0) {
      return "";
   }

   const date = new Date(timestamp);
   const month = months[date.getUTCMonth()];
   const day = date.getUTCDate();
   const year = date.getUTCFullYear();

   let hours = date.getUTCHours();
   const minutes = date.getUTCMinutes();
   const ampm = hours >= 12 ? "PM" : "AM";
   hours = hours % 12;
   hours = hours ? hours : 12;
   const strMinutes = minutes < 10 ? "0" + minutes : minutes;

   hours = (hours + 19) % 24;

   return `${month} ${day}, ${year} at ${hours}:${strMinutes} ${ampm} EST`;
}

export function getInitials(fullName: string): string {
   const words = fullName.split(" ");
   if (words.length < 2) {
      return "";
   }
   const initials = words.slice(0, 2).map((word) => word.charAt(0).toUpperCase());
   return initials.join("");
}

function processDataForView(breakdown: any, selectedView: string): Array<[number, number]> {
   const assignments = breakdown.daily_ass_totals;
   const requests = breakdown.daily_request_totals;
   const data: Array<[number, number]> = [];

   const processEntry = (k: string, yVal: number) => {
      data.push([getAttachedDate(Number(k)).getTime(), yVal]);
   };

   if (selectedView === "both") {
      const keys =
         Object.keys(requests).length >= Object.keys(assignments).length ? requests : assignments;
      Object.keys(keys).forEach((k) => {
         const yVal = (requests[k]?.count || 0) + (assignments[k]?.count || 0);
         processEntry(k, yVal);
      });
   } else {
      const source = selectedView === "requests" ? requests : assignments;
      Object.keys(source).forEach((k) => processEntry(k, source[k].count));
   }

   return data;
}

export function processTotalsDataForGraph(
   totals: any,
   selectedView: "assignments" | "requests" | "both",
   I18n: {
      t: (t: string) => string;
   },
): Highcharts.SeriesAreaOptions[] {
   return totals.breakdown_data
      .map((breakdown: any) => ({
         name: breakdown.name ?? I18n.t("views.company.workforce_planning.none"),
         color: breakdown.color ?? "#ACB5B9",
         type: "area" as const,
         data: processDataForView(breakdown, selectedView),
      }))
      .filter((series: Highcharts.SeriesAreaOptions) => {
         if (series.data) {
            return series.data.length > 0;
         }
         return false;
      });
}

export const getDateString = (date: Date) => {
   return `${DateUtils.getMonth(date)} ${date.getDate()}, ${date.getFullYear()}`;
};

// this function is used to group the roles by job title
export const getGroupedDataForRolesByJobTitle = (
   rolesData: ExtendedGetDetailResponse["data"]["roles"] = [],
) => {
   const groupedData = rolesData.reduce((acc: { [key: string]: any }, item) => {
      const { job_title_id, assignee, id, person_id } = item;

      // If the group doesn't exist, initialize it
      if (!acc[job_title_id]) {
         acc[job_title_id] = {
            job_title_id,
            job_title_name: item.position.name,
            job_title_color: item.position.color,
            persons: [],
         };
      }
      // Push the person details into the group
      acc[job_title_id].persons.push({
         role_id: id,
         id: person_id,
         name: `${assignee.name.first} ${assignee.name.last}`,
         email: assignee.email,
      });
      // sorting by name
      acc[job_title_id].persons.sort((a: { name: string }, b: { name: string }) => {
         return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });

      return acc;
   }, {});
   return groupedData;
};

// this function is used to get the removed resources while updating the roles
export const getRemovedResources = (
   initialResources: ResourceName[],
   selectedResources: ResourceName[],
) => {
   // Find resources in initialResources that are not in selectedResources
   const removedResources = initialResources.filter(
      (initial) => !selectedResources.some((selected) => selected.id === initial.id),
   );

   // Add archived flag to removed resources
   return removedResources.map((resource) => ({
      ...resource,
      archived: true,
   }));
};

export function prepareRolesPayload(
   jobTitleValue: string,
   resources: ResourceName[],
   archived: boolean,
) {
   // Prepare roles payload by mapping the resources to roles array
   const roles = resources.map((resource) => ({
      id: resource.role_id ?? v4(),
      position_id: jobTitleValue,
      person_id: resource.id,
      archived: resource.archived ?? archived,
   }));

   const payload: ProjectRoleData = {
      roles,
   };
   return payload;
}

export function prepareCannedMessagePayload(
   alertContent: { subject: string; content: string },
   formValues: any,
   projectId: string,
   type: CannedMessageType,
): Partial<CannedMessage> {
   const { content: apiReadySubject, dynamicTokens: subjectTokens } = stripAndReturnHTMLTokens(
      alertContent.subject,
      projectId,
   );
   const { content: apiReadyContent, dynamicTokens: contentTokens } = stripAndReturnHTMLTokens(
      alertContent.content,
      projectId,
   );

   const dynamic_tokens = subjectTokens.concat(contentTokens);

   const payload = {
      content: apiReadyContent,
      subject: apiReadySubject,
      include_signature: false,
      is_group: formValues.send_as.value === "group",
      owner_id: projectId,
      type: type,
      dynamic_tokens: dynamic_tokens,
   };

   return payload;
}

export function getCustomAlertsInitialValues(cannedMessage: CannedMessage | null, I18n: any) {
   if (!cannedMessage || !cannedMessage.is_group) {
      return {
         send_as: {
            id: "individual",
            value: "individual",
            label: I18n.t("views.company.workforce_planning.communications.individual_messages"),
         },
      };
   } else {
      return {
         send_as: {
            id: "group",
            value: "group",
            label: I18n.t("views.company.workforce_planning.communications.group_messages"),
         },
      };
   }
}

export function getCustomAssignmentsTearsheetHeader(I18n: any, type: CannedMessageType): string {
   switch (type) {
      case "assignment-new":
         return I18n.t("views.company.workforce_planning.communications.customized_new_assignment");
      case "assignment-edit":
         return I18n.t(
            "views.company.workforce_planning.communications.customized_updated_assignment",
         );
      case "assignment-transfer":
         return I18n.t(
            "views.company.workforce_planning.communications.customized_assignment_transfer",
         );
      case "assignment-delete":
         return I18n.t(
            "views.company.workforce_planning.communications.customized_deleted_assignment",
         );
      default:
         return I18n.t("views.company.workforce_planning.communications.assignment_alert");
   }
}
