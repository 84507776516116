import { Link as LinkIcon } from "@procore/core-icons";
import { Button, Flex, Tooltip, useI18nContext } from "@procore/core-react";
import type { ColumnDefinition, DataTableCellRendererProps } from "@procore/data-table";
import { withDataTableRenderer } from "@procore/data-table";
import type { ReactElement } from "react";
import React, { useCallback } from "react";
import { ProcoreTargetType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { getProcoreCompanyIdFromURL } from "@/lib/utils/url";

interface LinkToProcoreRendererParams {
   entityType: "person" | "project";
}

export const LinkToProcoreCell: React.FunctionComponent<LinkToProcoreRendererProps> = (
   props,
): ReactElement | null => {
   const I18n = useI18nContext();
   const { value, columnDefinition } = props;
   const { entityType } = columnDefinition.cellRendererParams ?? {};
   const companyId = getProcoreCompanyIdFromURL();

   let linkUrl = "";
   if (entityType === ProcoreTargetType.PERSON) {
      linkUrl = `/${companyId}/company/directory/users/${value}?tab=general`;
   } else {
      linkUrl = `/${value}/project/admin`;
   }

   const handleClick = useCallback(() => {
      window.open(linkUrl, "_blank");
   }, [linkUrl]);

   if (value === null || value === undefined || value === "") {
      return null;
   }

   const popoverHeading = I18n.t(
      "views.company.workforce_planning.projects.linked_record_in_procore",
   );

   return (
      <Flex alignItems="center">
         <Tooltip overlay={popoverHeading} trigger={["hover", "focus"]}>
            <Button
               icon={<LinkIcon />}
               data-testid="link-btn"
               onClick={handleClick}
               variant="secondary"
               size="sm"
            />
         </Tooltip>
      </Flex>
   );
};

export type LinkToProcoreColumnDefinition = ColumnDefinition<
   string,
   typeof LinkToProcoreCell,
   LinkToProcoreRendererParams
>;

export type LinkToProcoreRendererProps = DataTableCellRendererProps<
   string,
   LinkToProcoreColumnDefinition
>;

export const LinkToProcoreRenderer = withDataTableRenderer(LinkToProcoreCell, "input");
