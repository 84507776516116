import { ActivityCategory } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { useI18nContext } from "@procore/core-react";
import { Box, Typography } from "@procore/core-react";
import React from "react";
import "../style.css";
import type { ActivitiesResponseProps, ExtendedActivitiesResponseProps, ValueType } from "../types";
import type { ActivityStreamName } from "@laborchart-modules/common/dist/reql-builder/procedures/find-activity";
import { timeOptions } from "@/lib/utils/timezones";
import { formatDate, getTranslationLabel } from "../helper";

export const getActionText = (
   action_type: string,
   action_category: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   switch (action_type) {
      case "create":
         if (action_category === ActivityCategory.PERSON_ASSIGNMENTS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.assigned",
            );
         } else if (action_category === ActivityCategory.PERSON_TAGS_ATTACHMENTS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.added_attachment",
            );
         } else if (action_category === ActivityCategory.PERSON) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.created",
            );
         } else if (action_category === ActivityCategory.PERSON_USER_LOCK) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.user_unlocked",
            );
         } else if (action_category === ActivityCategory.PERSON_NOTES) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.added_note",
            );
         } else {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.added",
            );
         }

      case "update":
         if (action_category === ActivityCategory.PERSON_TAGS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.changed",
            );
         } else {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.updated",
            );
         }
      case "delete":
         if (action_category === ActivityCategory.PERSON_TAGS_ATTACHMENTS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.removed_attachment",
            );
         } else if (action_category === ActivityCategory.PERSON) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.deleted",
            );
         } else if (action_category === ActivityCategory.PERSON_USER_LOCK) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.user_locked",
            );
         } else if (action_category === ActivityCategory.PERSON_NOTES) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.removed_note",
            );
         } else {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.removed",
            );
         }

      default:
         return "";
   }
};

export const getTargetText = (
   action_type: string,
   action_category: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   switch (action_type) {
      case "create":
         if (action_category === ActivityCategory.PERSON_TAGS_ATTACHMENTS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.to_tag",
            );
         } else {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.to",
            );
         }
      case "delete":
      case "update":
         if (action_category === ActivityCategory.PERSON_TAGS_ATTACHMENTS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.from_tag",
            );
         } else if (action_category === ActivityCategory.PERSON_ATTACHMENTS) {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.attachment_from",
            );
         } else {
            return I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.from",
            );
         }
      default:
         return "";
   }
};

export const getSubjectName = (
   activity: ActivitiesResponseProps,
   defaultSubject: "subject_1_name" | "subject_2_name" | "subject_3_name",
) => {
   const name = activity[defaultSubject];
   return typeof name === "object"
      ? `${(name as ActivityStreamName)?.first ?? ""} ${(name as ActivityStreamName)?.last ?? ""}`
      : (name as string); // for subject_2_name and subject_3_name as strings
};

const getExpirationDate = (activity: ActivitiesResponseProps) => {
   return activity?.meta?.property_name === "expr_date";
};

const getFormattedDateRange = (
   activity: ActivitiesResponseProps,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   if (!getExpirationDate(activity)) return null;
   return (
      <>
         <span className="highlighted-text">{formatDate(activity?.meta?.old_val)} </span>
         <span className="light-text">
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.to",
            )}{" "}
         </span>
         <span className="highlighted-text">{formatDate(activity?.meta?.new_val)}</span>
      </>
   );
};

// for category type person/person_user_lock
export const renderSimpleActivityText = (
   activity: ActivitiesResponseProps,
   actionTypeText: string,
) => {
   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray50">
            <span className="highlighted-text">{getSubjectName(activity, "subject_1_name")} </span>

            <span className="light-text">{actionTypeText} </span>
         </Typography>
      </Box>
   );
};

// for category type person/person_user_lock
export const renderNotesActivityText = (
   activity: ActivitiesResponseProps,
   actionTypeText: string,
) => {
   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray50">
            <span className="light-text">{actionTypeText} </span>
            <span className="highlighted-text">{getSubjectName(activity, "subject_1_name")}</span>
         </Typography>
      </Box>
   );
};

// for category type person_tags/person_attachments/person_tag_attachments
export const renderActivityText = (
   activity: ActivitiesResponseProps,
   actionTypeText: string,
   targetText: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const expirationText = getExpirationDate(activity)
      ? I18n.t(
           "views.company.workforce_planning.people_details_activities.activity_action.expiration_date",
        )
      : null;
   const dateRangeContent = getFormattedDateRange(activity, I18n);
   const isPersonTags = activity.category === ActivityCategory.PERSON_TAGS;
   const isPersonAttachments = activity.category === ActivityCategory.PERSON_ATTACHMENTS;
   const isPersonTagAttachments = activity.category === ActivityCategory.PERSON_TAGS_ATTACHMENTS;

   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray50">
            <span className="light-text">{actionTypeText} </span>
            <span className="highlighted-text">
               {activity.category === ActivityCategory.PERSON_ATTACHMENTS ||
               activity.category === ActivityCategory.PERSON_TAGS
                  ? getSubjectName(activity, "subject_2_name")
                  : getSubjectName(activity, "subject_3_name")}{" "}
            </span>

            {isPersonTags && expirationText && (
               <span className="light-text">{expirationText} </span>
            )}

            <span className="light-text">{targetText} </span>

            {isPersonTags && (
               <span className="highlighted-text">
                  {dateRangeContent ?? getSubjectName(activity, "subject_1_name")}
               </span>
            )}

            {isPersonAttachments && (
               <span className="highlighted-text">
                  {getSubjectName(activity, "subject_1_name")}
               </span>
            )}
            {isPersonTagAttachments && (
               <span className="highlighted-text">
                  {getSubjectName(activity, "subject_2_name")}
               </span>
            )}
         </Typography>
      </Box>
   );
};

// for category type person_assignments
export const renderAssignmentsActivityText = (
   activity: ExtendedActivitiesResponseProps,
   actionTypeText: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const formattedStartDate = formatDate(activity?.meta?.start_day);
   const formattedEndDate = activity?.meta?.end_day ? formatDate(activity?.meta?.end_day) : "TBD";

   const closing = activity?.meta?.transfer
      ? I18n.t(
           "views.company.workforce_planning.people_details_activities.activity_action.project_via_assignment_transfer",
        )
      : I18n.t(
           "views.company.workforce_planning.people_details_activities.activity_action.project",
        );

   const projectName = activity.job_number
      ? `${getSubjectName(activity, "subject_2_name")} - ${activity.job_number}`
      : getSubjectName(activity, "subject_2_name");

   const statusName = activity?.meta?.status_name ? (
      <span className="dark-highlighted-text">
         {`(${I18n.t("views.company.workforce_planning.people.status")}: ${
            activity.meta.status_name
         }) `}
      </span>
   ) : null;

   const assignmentText =
      activity?.action_type !== "create" ? (
         <span className="light-text-small-case">
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.assignment",
            )}{" "}
         </span>
      ) : null;

   let content = (
      <span>
         <span className="light-text">{actionTypeText} </span>
         <span className="dark-highlighted-text">
            {getSubjectName(activity, "subject_1_name")}
            {activity?.action_type !== "create" && "'s"}{" "}
         </span>
         {statusName}
         {assignmentText}
         <span className="light-text">
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.from",
            )}{" "}
         </span>
         <span className="dark-highlighted-text">{formattedStartDate} </span>
         <span className="light-text">
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.to",
            )}{" "}
         </span>
         <span className="dark-highlighted-text">{formattedEndDate} </span>
      </span>
   );

   if (activity?.meta?.percent_allocated) {
      content = (
         <>
            {content}
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.with_time_allocation_of",
            )}{" "}
            <span className="dark-highlighted-text">{activity.meta.percent_allocated}%</span>
         </>
      );
   } else {
      const startTime = timeOptions.find(({ id }) => id === activity?.meta?.start_time)?.label;
      const endTime = timeOptions.find(({ id }) => id === activity?.meta?.end_time)?.label;
      content = (
         <>
            {content}
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.with_start_time",
            )}{" "}
            <span className="dark-highlighted-text">{startTime}</span>{" "}
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.with_end_time",
            )}{" "}
            <span className="dark-highlighted-text">{endTime}</span>
         </>
      );
   }

   content = (
      <>
         {content}{" "}
         <span className="light-text">
            {activity?.action_type === "create"
               ? I18n.t(
                    "views.company.workforce_planning.people_details_activities.activity_action.to",
                 )
               : I18n.t(
                    "views.company.workforce_planning.people_details_activities.activity_action.on_the",
                 )}{" "}
         </span>
         <span className="dark-highlighted-text">{projectName}</span>{" "}
         <span className="light-text">{closing}</span>
      </>
   );

   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray50">
            {content}
         </Typography>
      </Box>
   );
};

// for category type person_timeoff
export const renderTimeOffActivityTest = (
   activity: ExtendedActivitiesResponseProps,
   actionTypeText: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const activityDetails = activity?.details?.reduce(
      (acc: Record<string, any>, { property_name, ...rest }) => {
         if (property_name) {
            acc[property_name] = rest;
         }
         return acc;
      },
      {},
   );

   const reason = activityDetails ? activityDetails["reason"]["value"] : null;
   const repeat = activityDetails ? activityDetails["repeat"]["value"] : null;

   const content = (
      <span>
         <span className="light-text">{actionTypeText} </span>
         {repeat !== "never" && (
            <span className="light-text">
               {I18n.t(
                  "views.company.workforce_planning.people_details_activities.activity_action.repeating",
               )}{" "}
            </span>
         )}
         <span className="light-text">
            {I18n.t(
               "views.company.workforce_planning.people_details_activities.activity_action.time_off_for",
            )}{" "}
            <span className="dark-highlighted-text">{reason} </span>
            {activity?.action_type === "delete"
               ? I18n.t(
                    "views.company.workforce_planning.people_details_activities.activity_action.scheduled_for",
                 )
               : I18n.t(
                    "views.company.workforce_planning.people_details_activities.activity_action.for",
                 )}{" "}
         </span>

         <span className="dark-highlighted-text">
            {getSubjectName(activity, "subject_1_name")}{" "}
         </span>
      </span>
   );
   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray50">
            {content}
         </Typography>
      </Box>
   );
};

// for category type group_ids, position_id, permission_level_id, notification_profile_id
export const renderPersonSpecificAdd = (
   label: string,
   value: string,
   person: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const actionTo = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.to",
   );
   const addedLabel = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.added",
   );
   const group = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.group",
   );
   const translatedLabel = getTranslationLabel(label, I18n);

   switch (label) {
      case "position_id":
      case "permission_level_id":
         return (
            <>
               <span className="light-text">{addedLabel} </span>
               <span className="light-text">{translatedLabel} </span>
               <span className="dark-highlighted-text">{value} </span>
               <span className="light-text">{actionTo} </span>
               <span className="dark-highlighted-text">{person}</span>
            </>
         );

      case "group_ids":
         return (
            <>
               <span className="light-text">{addedLabel} </span>
               <span className="dark-highlighted-text">{person} </span>
               <span className="light-text">{actionTo} </span>
               <span className="dark-highlighted-text">{value} </span>
               <span className="light-text">{group}</span>
            </>
         );

      case "notification_profile_id":
         return (
            <>
               <span className="light-text">{addedLabel} </span>
               <span className="dark-highlighted-text">{value} </span>
               <span className="light-text">{translatedLabel} </span>
               <span className="light-text">{actionTo} </span>
               <span className="dark-highlighted-text">{person}</span>
            </>
         );

      default:
         return null;
   }
};

// for category type group_ids, position_id, permission_level_id, notification_profile_id
export const renderPersonSpecificUpdate = (
   label: string,
   value: string,
   person: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const translatedLabel = getTranslationLabel(label, I18n);
   const changed = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.changed",
   );
   const actionTo = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.to",
   );

   return (
      <>
         <span className="light-text">{changed} </span>
         <span className="dark-highlighted-text">{person}</span>
         <span className="light-text">
            {"'s"} {translatedLabel}{" "}
         </span>
         <span className="light-text">{actionTo} </span>
         <span className="dark-highlighted-text">{value}</span>
      </>
   );
};

// for category type group_ids, position_id, permission_level_id, notification_profile_id
export const renderPersonSpecificDelete = (
   label: string,
   value: string,
   person: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const actionFrom = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.from",
   );
   const removed = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.removed",
   );
   const group = I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.group",
   );
   const translatedLabel = getTranslationLabel(label, I18n);

   switch (label) {
      case "position_id":
      case "permission_level_id":
         return (
            <>
               <span className="light-text">{removed} </span>
               <span className="light-text">{translatedLabel} </span>
               <span className="dark-highlighted-text">{value} </span>
               <span className="light-text">{actionFrom} </span>
               <span className="dark-highlighted-text">{person}</span>
            </>
         );

      case "group_ids":
         return (
            <>
               <span className="light-text">{removed} </span>
               <span className="dark-highlighted-text">{person} </span>
               <span className="light-text">{actionFrom} </span>
               <span className="dark-highlighted-text">{value} </span>
               <span className="light-text">{group}</span>
            </>
         );

      case "notification_profile_id":
         return (
            <>
               <span className="light-text">{removed} </span>
               <span className="dark-highlighted-text">{value} </span>
               <span className="light-text">{translatedLabel} </span>
               <span className="light-text">{actionFrom} </span>
               <span className="dark-highlighted-text">{person}</span>
            </>
         );

      default:
         return null;
   }
};

// for category type receive_sms, receive_mobile, receive_email
export const renderPersonNotificationActivity = (
   label: string,
   value: boolean,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   let notificationType = "";
   switch (label) {
      case "can_recieve_sms":
         notificationType = value
            ? I18n.t(
                 "views.company.workforce_planning.people_details_activities.activity_action.enabled_receiving_sms",
              )
            : I18n.t(
                 "views.company.workforce_planning.people_details_activities.activity_action.disabled_receiving_sms",
              );
         break;
      case "can_recieve_mobile":
         notificationType = value
            ? I18n.t(
                 "views.company.workforce_planning.people_details_activities.activity_action.enabled_receiving_mobile_notifications",
              )
            : I18n.t(
                 "views.company.workforce_planning.people_details_activities.activity_action.disabled_receiving_mobile_notifications",
              );
         break;
      case "can_recieve_email":
         notificationType = value
            ? I18n.t(
                 "views.company.workforce_planning.people_details_activities.activity_action.enabled_receiving_email",
              )
            : I18n.t(
                 "views.company.workforce_planning.people_details_activities.activity_action.disabled_receiving_email",
              );
         break;
   }
   return <span className="light-text">{notificationType}</span>;
};

// for category type profile_pic_url
export const renderPersonProfilePicActivity = (
   label: string,
   oldValue: string,
   newValue: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   const translationLabel = getTranslationLabel(label + "_people", I18n); // added _people to differentiate from people profile pic as property_name is same for people and project and we want to show different translation for both
   let messageToRender = `${I18n.t(
      "views.company.workforce_planning.people_details_activities.activity_action.changed",
   )} ${translationLabel} `;
   if (!oldValue) {
      messageToRender = `${I18n.t(
         "views.company.workforce_planning.people_details_activities.activity_action.added",
      )} ${translationLabel} `;
   } else if (!newValue) {
      messageToRender = `${I18n.t(
         "views.company.workforce_planning.people_details_activities.activity_action.removed",
      )} ${translationLabel} `;
   }

   return <span className="light-text">{messageToRender}</span>;
};

// for category type person_assignable, person_user
export const renderPersonAssignableActivity = (
   label: string,
   personName: string,
   newValue: boolean,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   let messageToRender = "";
   if (label === "is_assignable") {
      messageToRender = newValue
         ? `${personName} ${I18n.t(
              "views.company.workforce_planning.people_details_activities.activity_action.became_assignable",
           )}`
         : `${personName} ${I18n.t(
              "views.company.workforce_planning.people_details_activities.activity_action.is_no_longer_assignable",
           )}`;
   } else {
      messageToRender = newValue
         ? `${personName} ${I18n.t(
              "views.company.workforce_planning.people_details_activities.activity_action.is_now_a_user",
           )}`
         : `${personName} ${I18n.t(
              "views.company.workforce_planning.people_details_activities.activity_action.is_no_longer_a_user",
           )}`;
   }

   return <span className="light-text">{messageToRender}</span>;
};

export const renderChange = (
   label: string,
   I18n: ReturnType<typeof useI18nContext>,
   oldValue?: ValueType,
   newValue?: ValueType,
   labelClass?: string, // a new parameter added to pass dynamic css class in some cases
) => {
   return (
      <>
         <span className="light-text">{`
         ${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.changed",
         )} 
         ${getTranslationLabel(label, I18n)} ${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.from",
         )} `}</span>
         <span className={labelClass ?? "dark-highlighted-text"}>{`${oldValue} `}</span>
         <span className="light-text">{`${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.to",
         )} `}</span>
         <span className={labelClass ?? "dark-highlighted-text"}>{`${newValue}`}</span>
      </>
   );
};

export const renderAdd = (
   label: string,
   value: ValueType,
   I18n: ReturnType<typeof useI18nContext>,
   labelClass?: string, // a new parameter added to pass dynamic css class in some cases
) => {
   return (
      <>
         <span className="light-text">{`${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.added",
         )} ${getTranslationLabel(label, I18n)}: `}</span>
         <span className={labelClass ?? "dark-highlighted-text"}>{value}</span>
      </>
   );
};

export const renderRemove = (
   label: string,
   value: ValueType,
   I18n: ReturnType<typeof useI18nContext>,
   labelClass?: string, // a new parameter added to pass dynamic css class in some cases
) => {
   return (
      <>
         <span className="light-text">{`${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.removed",
         )} ${getTranslationLabel(label, I18n)}: `}</span>
         <span className={labelClass ?? "dark-highlighted-text"}>{value}</span>
      </>
   );
};

export const renderColorChange = (
   label: string,
   I18n: ReturnType<typeof useI18nContext>,
   oldValue?: string,
   newValue?: string,
) => {
   return (
      <>
         <span className="light-text">{`
         ${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.changed",
         )} 
         ${getTranslationLabel(label, I18n)} ${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.from",
         )} `}</span>
         <span
            style={{ backgroundColor: oldValue }}
            className="color-span"
            data-testid="old-color-span"
         ></span>
         <span className="light-text">{`${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.to",
         )} `}</span>
         <span
            style={{ backgroundColor: newValue }}
            className="color-span"
            data-testid="new-color-span"
         ></span>
      </>
   );
};

export const renderColorAdd = (
   label: string,
   value: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   return (
      <>
         <span className="light-text">{`${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.added",
         )} ${getTranslationLabel(label, I18n)}: `}</span>
         <span
            style={{ backgroundColor: value }}
            className="color-span"
            data-testid="color-span"
         ></span>
      </>
   );
};

export const renderColorRemove = (
   label: string,
   value: string,
   I18n: ReturnType<typeof useI18nContext>,
) => {
   return (
      <>
         <span className="light-text">{`${I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.removed",
         )} ${getTranslationLabel(label, I18n)}: `}</span>
         <span
            style={{ backgroundColor: value }}
            className="color-span"
            data-testid="color-span"
         ></span>
      </>
   );
};

export const renderCannedMessageType = (type: string, I18n: ReturnType<typeof useI18nContext>) => {
   switch (type) {
      case "assignment-new":
         return I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.assignment.creation",
         );
      case "assignment-edit":
         return I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.assignment.update",
         );
      case "assignment-transfer":
         return I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.assignment.transfer",
         );
      case "assignment-delete":
         return I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.assignment.delete",
         );
      case "assignment-reminder":
         return I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.assignment.reminder",
         );
      default:
         return I18n.t(
            "views.company.workforce_planning.project_details_activities.activity_action.assignment.unknown",
         );
   }
};

export const CannedAssignmentMessage = ({
   activity,
   I18n,
}: {
   activity: ActivitiesResponseProps;
   I18n: ReturnType<typeof useI18nContext>;
}) => {
   const messageType = renderCannedMessageType(activity?.meta?.type ?? "unknown", I18n);

   const actionText =
      activity?.action_type === "create"
         ? I18n.t(
              "views.company.workforce_planning.project_details_activities.activity_action.added",
           )
         : activity?.action_type === "update"
         ? I18n.t(
              "views.company.workforce_planning.project_details_activities.activity_action.changed",
           )
         : I18n.t(
              "views.company.workforce_planning.project_details_activities.activity_action.removed",
           );

   return (
      <Box display="flex" style={{ marginTop: "10px" }}>
         <Typography intent="body" color="gray50">
            <span className="light-text">
               {`${actionText} `}
               <span className="highlighted-text">
                  {I18n.t(
                     "views.company.workforce_planning.project_details_activities.activity_action.alert_template",
                  )}
               </span>{" "}
               {I18n.t(
                  "views.company.workforce_planning.people_details_activities.activity_action.for",
               )}{" "}
               <span className="highlighted-text">{messageType}</span>
            </span>
         </Typography>
      </Box>
   );
};
