import React, { useEffect, useMemo, useState } from "react";
import {
   Box,
   Button,
   Flex,
   FlexList,
   Form,
   H2,
   Required,
   Title,
   useI18nContext,
} from "@procore/core-react";
import type { JobTitle, ResourceName } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { rolesSchema } from "../validation-schemas";
import type { AddRolesModalProps, RoleFormValues } from "../types";

import styles from "./project-details.scss";
import classnames from "classnames";
import { getRemovedResources } from "../helpers";

const cx = classnames.bind(styles);

export const AddRolesProject = ({
   onClose,
   handleCreate,
   mode = ActionMode.CREATE,
   roles,
   initialValues,
   availableJobTitles,
   availableResourceName,
}: AddRolesModalProps) => {
   const I18n = useI18nContext();
   const [filteredJobTitles, setFilteredJobTitles] = useState<JobTitle[]>([]);
   const [filteredResourceName, setFilteredResourceName] = useState<ResourceName[]>([]);
   const validationSchema = rolesSchema(I18n);

   const filteredJobTitlesMemo = useMemo(() => {
      if (availableJobTitles && roles) {
         return availableJobTitles.filter(
            (jobTitle) => !roles.some((role) => role?.job_title_id === jobTitle.id),
         );
      }
      return [];
   }, [availableJobTitles, roles]);

   const filteredResourceNameMemo = useMemo(() => {
      if (availableResourceName && roles) {
         return availableResourceName.filter(
            (resourceName) =>
               !roles.some((role) => role.persons.some((person) => person.id === resourceName.id)),
         );
      }
      return [];
   }, [availableResourceName, roles]);

   useEffect(() => {
      setFilteredJobTitles(filteredJobTitlesMemo);
   }, [filteredJobTitlesMemo]);

   useEffect(() => {
      setFilteredResourceName(filteredResourceNameMemo);
   }, [filteredResourceNameMemo]);

   const handleClose = () => {
      onClose();
      setFilteredJobTitles(availableJobTitles || []);
      setFilteredResourceName(availableResourceName || []);
   };

   const handleSubmit = async (values: RoleFormValues) => {
      const {
         job_title: { id: jobTitleValue },
         resources,
      } = values;

      // resources that are coming from the backend while editing the roles
      const initialResources = initialValues?.resources ?? [];
      // resources that are selected by the user
      const selectedResources = [...resources];
      // resources that are removed by the user
      const removedResources = getRemovedResources(initialResources, selectedResources);
      // combined resources that needs to be updated
      const combinedResources = [...selectedResources, ...removedResources];

      const archived = mode === ActionMode.DELETE ? true : false;
      await handleCreate(jobTitleValue, combinedResources, archived);
   };

   return (
      <>
         {mode === ActionMode.CREATE && (
            <DetailsCardHeader
               titleKey="views.company.workforce_planning.project_details_roles.title"
               helpTextKey="views.company.workforce_planning.project_details_roles.roles_help_text"
               buttonTextKey="views.company.workforce_planning.project_details_roles.add_role"
               btnDisabled={true}
            />
         )}
         <Box padding="sm" hidden={mode !== ActionMode.DELETE}>
            <FlexList justifyContent="space-between">
               <Title>
                  <Title.Text>
                     <H2>
                        {I18n.t(
                           "views.company.workforce_planning.project_details_roles.roles_delete_title",
                        )}
                     </H2>
                  </Title.Text>
                  <Title.Subtext>
                     {I18n.t(
                        "views.company.workforce_planning.project_details_roles.roles_delete_description",
                     )}
                  </Title.Subtext>
               </Title>
            </FlexList>
         </Box>
         <Box padding="sm" className={mode === ActionMode.CREATE ? cx("custom-box") : ""}>
            <Form
               view="create"
               onSubmit={handleSubmit}
               validationSchema={validationSchema}
               initialValues={initialValues}
            >
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({ submitForm, isSubmitting }: FormikProps<WageOverrideFormValues>) => (
                  <>
                     <Box padding={mode === ActionMode.CREATE ? "md" : ""}>
                        <Form.Form>
                           <Form.Row>
                              <Form.Select
                                 colStart={1}
                                 colWidth={6}
                                 name="job_title"
                                 label={I18n.t(
                                    "views.company.workforce_planning.project_details_roles.roles_job_title_label",
                                 )}
                                 options={filteredJobTitles}
                                 placeholder={I18n.t(
                                    "views.company.workforce_planning.project_details_roles.roles_job_title_placeholder",
                                 )}
                                 required
                                 disabled={mode === ActionMode.DELETE}
                              />
                              <Form.MultiSelect
                                 colStart={7}
                                 colWidth={6}
                                 name="resources"
                                 label={I18n.t(
                                    "views.company.workforce_planning.project_details_roles.roles_resource_label",
                                 )}
                                 options={filteredResourceName}
                                 placeholder={I18n.t(
                                    "views.company.workforce_planning.project_details_roles.roles_resource_placeholder",
                                 )}
                                 required
                                 disabled={mode === ActionMode.DELETE}
                                 getId={(resource: ResourceName) => resource.id} // for allowing inbuilt searching
                                 getLabel={(resource: ResourceName) => resource.label} // for allowing inbuilt searching
                              />
                           </Form.Row>
                        </Form.Form>
                     </Box>
                     <Box padding={mode === ActionMode.CREATE ? "md" : ""}>
                        <Flex justifyContent="space-between" alignItems="center">
                           <Required showLabel />
                           <FlexList space="sm">
                              <Button
                                 variant="secondary"
                                 disabled={isSubmitting}
                                 onClick={handleClose}
                              >
                                 {I18n.t("views.company.workforce_planning.cancel")}
                              </Button>
                              <Button type="submit" disabled={isSubmitting} onClick={submitForm}>
                                 {I18n.t(
                                    mode === ActionMode.DELETE
                                       ? "views.company.workforce_planning.delete"
                                       : "views.company.workforce_planning.save",
                                 )}
                              </Button>
                           </FlexList>
                        </Flex>
                     </Box>
                  </>
               )}
            </Form>
         </Box>
         {mode === ActionMode.CREATE && <Box padding="xxs" />}
      </>
   );
};
