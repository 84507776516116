import React, { useState } from "react";
import {
   Button,
   useI18nContext,
   Form,
   Spinner,
   Tearsheet,
   Page,
   H1,
   Card,
   Box,
   Flex,
   Required,
   FlexList,
} from "@procore/core-react";
import { timeOffReasons } from "../../tearsheets/time-off/constants";
import * as yup from "yup";
import { getOrderedTimeOptions } from "../../tearsheets/time-off/helpers";
import type { FormikProps } from "formik";
import { isAfter } from "date-fns";
import { ErrorBanner } from "../../error-banner";
import { useToastAlertContext } from "@procore/toast-alert";
import { Pencil } from "@procore/core-icons";
import type {
   EditTimeOffFormValues,
   EditTimeOffModalProps,
} from "../../time-off-list/time-off-list-prop-types";
import { DateUtils } from "@/lib/utils/date";
import { DEFAULT_START_TIME } from "@/react/shared/constants";
import {
   getTimeOffTypeOptions,
   getTimeOffWeekendOptions,
} from "../../time-off-list/time-off-list-utils";
import { RepeatType } from "./types";

export const EditTimeOffTearsheet = (props: EditTimeOffModalProps) => {
   const { timeOff, tableApi, updateTimeOff, refetchTimeOffData } = props;
   const I18n = useI18nContext();
   const [isOpen, setOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);
   const { showToast } = useToastAlertContext();
   const buttonSize = refetchTimeOffData ? "sm" : "md"; // If refetchTimeOffData is defined, it means the tearsheet is opened from the time off tab in people details
   // Compare the time off prop data and tableApi data to get the correct time off data
   const timeOffTableApiData = tableApi?.getRowData()?.find((x) => x?.id == timeOff.id);
   if (timeOffTableApiData) {
      const personName = `${timeOffTableApiData.person_name?.first} ${timeOffTableApiData.person_name?.last}`;
      timeOff.start_date = timeOffTableApiData.start_date;
      timeOff.end_date = timeOffTableApiData.end_date;
      timeOff.daily_start_time = timeOffTableApiData.daily_start_time;
      timeOff.daily_end_time = timeOffTableApiData.daily_end_time;
      timeOff.reason = timeOffTableApiData.reason;
      timeOff.type = timeOffTableApiData.type;
      timeOff.apply_to_saturday = timeOffTableApiData.apply_to_saturday;
      timeOff.apply_to_sunday = timeOffTableApiData.apply_to_sunday;
      timeOff.cadence = timeOffTableApiData.cadence;
      timeOff.repeat = timeOffTableApiData.repeat;
      timeOff.repeat_end_date = timeOffTableApiData.repeat_end_day
         ? DateUtils.getAttachedDate(timeOffTableApiData.repeat_end_day)
         : "";
      timeOff.resource_name = timeOffTableApiData.person_name ? personName : "";
   }
   const isRepeating = timeOff.occurences && timeOff.occurences > 1;

   const orderedTimeOptions = getOrderedTimeOptions(DEFAULT_START_TIME);

   const timeOffWeekendOptions = getTimeOffWeekendOptions(I18n);

   const timeOffTypeOptions = getTimeOffTypeOptions(I18n);

   const initialWeekendValues = [];
   const daily_start_time = timeOff.daily_start_time?.label || timeOff.daily_start_time || "";
   const daily_end_time = timeOff.daily_end_time?.label || timeOff.daily_end_time || "";
   if (timeOff.apply_to_saturday) initialWeekendValues.push(timeOffWeekendOptions[0]);
   if (timeOff.apply_to_sunday) initialWeekendValues.push(timeOffWeekendOptions[1]);
   if (timeOff.repeat == RepeatType.WEEKLY)
      timeOff.repeat = I18n.t("views.company.workforce_planning.time_off.weekly");
   if (timeOff.repeat == RepeatType.MONTHLY)
      timeOff.repeat = I18n.t("views.company.workforce_planning.time_off.monthly");
   if (timeOff.repeat == RepeatType.YEARLY)
      timeOff.repeat = I18n.t("views.company.workforce_planning.time_off.yearly");

   const initialValues = {
      resource_name: timeOff.resource_name,
      start_date: timeOff.start_date,
      end_date: timeOff.end_date,
      daily_start_time: orderedTimeOptions.find((x) => x.label == daily_start_time),
      daily_end_time: orderedTimeOptions.find((x) => x.label == daily_end_time),
      reason: timeOffReasons.find((x) => x.value == timeOff.reason?.value),
      type: timeOff.type?.value === "paid" ? { id: 1 } : { id: 2 },
      weekend: initialWeekendValues,
      cadence: timeOff.cadence,
      repeat: timeOff.repeat,
      repeat_end_date: timeOff.repeat_end_date,
      workers_name: timeOff.workers_name,
   };

   const handleSubmit = async (values: EditTimeOffFormValues) => {
      setLoading(true);
      try {
         if (!values.type?.value) {
            // If type is not selected, set it based on the value
            if (values.type?.id == "1") {
               values.type = {
                  id: "1",
                  value: "paid",
                  label: I18n.t("views.company.workforce_planning.time_off.paid"),
               };
            }
            if (values.type?.id == "2") {
               values.type = {
                  id: "2",
                  value: "unpaid",
                  label: I18n.t("views.company.workforce_planning.time_off.unpaid"),
               };
            }
         }

         if (isRepeating) {
            delete values.start_date;
            delete values.end_date;
         }

         await updateTimeOff(timeOff.id, values);
         setLoading(false);
         setOpen(false);
         showToast.success(
            I18n.t("views.company.workforce_planning.time_off.modals.success_toast"),
         );
         // refetchTimeOffData is only defined when tearsheet is getting opened from the time off tab in people details
         refetchTimeOffData ? refetchTimeOffData() : tableApi?.refreshServerSide({});
      } catch (err) {
         // refetchTimeOffData is only defined when tearsheet is getting opened from the time off tab in people details
         if (refetchTimeOffData) {
            showToast.error(I18n.t("views.company.workforce_planning.time_off.modals.edit_error"));
         }
         setLoading(false);
         setError(true);
      }
   };

   // Clear selected form values if repeating time off else clear all form values
   const clearForm = isRepeating
      ? {
           start_date: initialValues.start_date,
           end_date: initialValues.end_date,
           daily_start_time: "",
           daily_end_time: "",
           reason: "",
        }
      : {};

   return (
      <>
         <Button
            onClick={() => setOpen(true)}
            icon={<Pencil />}
            variant="tertiary"
            size={buttonSize}
         />
         <Tearsheet open={isOpen} onClose={() => setOpen(false)}>
            <Spinner loading={loading}>
               <Page>
                  <Page.Main
                     style={{
                        width: "400",
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                     }}
                  >
                     <Page.Header>
                        {error && (
                           <Page.Banner>
                              <ErrorBanner
                                 title={I18n.t("views.company.workforce_planning.error")}
                                 content={I18n.t(
                                    "views.company.workforce_planning.time_off.modals.edit_error",
                                 )}
                              />
                           </Page.Banner>
                        )}
                        <Page.Title>
                           <H1>
                              {I18n.t(
                                 "views.company.workforce_planning.time_off.modals.edit_header",
                              )}
                           </H1>
                        </Page.Title>
                     </Page.Header>

                     <Form
                        view="update"
                        onSubmit={handleSubmit}
                        initialValues={initialValues as any}
                        validationSchema={yup.object().shape({
                           start_date: yup
                              .date()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           end_date: yup
                              .date()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              )
                              .test(
                                 "validate-end-date",
                                 I18n.t(
                                    "views.company.workforce_planning.validations.end_date_before_start_date",
                                 ),
                                 function (endDate) {
                                    const { start_date } = this.parent;
                                    return endDate.getTime() >= start_date.getTime();
                                 },
                              ),
                           daily_start_time: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           daily_end_time: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           reason: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                           type: yup
                              .object()
                              .required(
                                 I18n.t(
                                    "views.company.workforce_planning.validations.required_field",
                                 ),
                              ),
                        })}
                     >
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {(formikProps: FormikProps<any>) => {
                           const { repeat } = formikProps.values;

                           return (
                              <Form.Form>
                                 <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                    <Card>
                                       <Box padding="md">
                                          <Form.Row>
                                             <Form.Text
                                                name="resource_name"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.resource",
                                                )}
                                                colStart={1}
                                                colWidth={11}
                                                readOnly
                                                view="read"
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.DateSelect
                                                view={isRepeating ? "read" : "update"}
                                                name="start_date"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.start_date",
                                                )}
                                                colStart={1}
                                                colWidth={6}
                                                onChange={(date) => {
                                                   // Manually set end date when the user selects a start date if end date is not set.
                                                   // This mimics legacy behavior so new modal does not add clicks.
                                                   formikProps.setFieldValue("start_date", date);
                                                   if (!formikProps.values.end_date && date) {
                                                      formikProps.setFieldValue("end_date", date);
                                                   }
                                                }}
                                                required
                                             />
                                             <Form.DateSelect
                                                view={isRepeating ? "read" : "update"}
                                                name="end_date"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.end_date",
                                                )}
                                                colStart={7}
                                                colWidth={6}
                                                disabledDate={(date) => {
                                                   const { start_date } = formikProps.values;

                                                   return isAfter(start_date, date);
                                                }}
                                                required
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="daily_start_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.daily_start_time",
                                                )}
                                                options={orderedTimeOptions}
                                                colStart={1}
                                                colWidth={5}
                                                required
                                                onSearch={false}
                                             />
                                             <Form.Select
                                                name="daily_end_time"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.daily_end_time",
                                                )}
                                                options={orderedTimeOptions}
                                                colStart={7}
                                                colWidth={5}
                                                required
                                                onSearch={false}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="reason"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.reason",
                                                )}
                                                options={timeOffReasons}
                                                required
                                                onSearch={false}
                                                colStart={1}
                                                colWidth={11}
                                                block
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.RadioButtons
                                                name="type"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.type",
                                                )}
                                                options={timeOffTypeOptions}
                                             />
                                             <Form.Checkboxes
                                                colStart={7}
                                                name="weekend"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.time_off.modals.labels.weekends",
                                                )}
                                                options={timeOffWeekendOptions}
                                             />
                                          </Form.Row>

                                          <Form.Row>
                                             {repeat !== "never" && (
                                                <Form.Text
                                                   view="read"
                                                   name="repeat"
                                                   label={I18n.t(
                                                      "views.company.workforce_planning.time_off.repeat",
                                                   )}
                                                   colStart={1}
                                                />
                                             )}
                                             {repeat !== "never" && (
                                                <Form.Text
                                                   view="read"
                                                   colStart={7}
                                                   colWidth={4}
                                                   style={{ height: "36px", width: "145px" }}
                                                   name="cadence"
                                                   label={I18n.t(
                                                      "views.company.workforce_planning.time_off.modals.labels.cadence",
                                                   )}
                                                />
                                             )}
                                          </Form.Row>

                                          <Form.Row style={{ marginTop: "-15px" }}>
                                             {
                                                // When showing repeat end day, use  negative top margin to group with cadence
                                                repeat !== "never" && (
                                                   <Form.DateSelect
                                                      view="read"
                                                      colStart={7}
                                                      colWidth={4}
                                                      name="repeat_end_date"
                                                      label={I18n.t(
                                                         "views.company.workforce_planning.time_off.modals.labels.repeat_end_date",
                                                      )}
                                                   />
                                                )
                                             }
                                          </Form.Row>
                                       </Box>
                                    </Card>
                                 </Page.Body>
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="space-between" alignItems="center">
                                          <Required showLabel />
                                          <FlexList space="md">
                                             <Button
                                                variant="tertiary"
                                                onClick={() => formikProps.setValues(clearForm)}
                                             >
                                                {I18n.t("views.company.workforce_planning.clear")}
                                             </Button>
                                             <Button variant="primary" type="submit">
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              </Form.Form>
                           );
                        }}
                     </Form>
                  </Page.Main>
               </Page>
            </Spinner>
         </Tearsheet>
      </>
   );
};
